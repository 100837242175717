import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { Button, Link } from "@smartrent/ui";

import { useParkingWebUrlQuery, useSignsPaginatedQuery } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import { SignStatusPill } from "@/common/parking/SignStatusPill";
import { useUser } from "@/layout/Context";
import { ColumnOptions, Table } from "@/common/sr-table";
import { formatDate } from "@/utils";
import { Sign } from "@/types";

interface SignsTableProps {
  groupId: number;
  propertyId: string;
  style?: StyleProp<ViewStyle>;
}

const PAGE_SIZE = 5;

export const SignsTable: React.FC<SignsTableProps> = ({
  groupId,
  propertyId,
  style,
}) => {
  const [page, setPage] = useState(1);
  const drawer = useDrawerNav();
  const { permissions } = useUser();

  const { data, isLoading } = useSignsPaginatedQuery({
    property_id: propertyId,
    page: page,
    limit: PAGE_SIZE,
  });

  const { data: baseUrl, isLoading: loadingParkingWebUrl } =
    useParkingWebUrlQuery();

  const signs = data?.records ?? [];
  const totalRecords = data?.total_records ?? 0;
  const currentPage = data?.current_page ?? 1;
  const totalPages = data?.total_pages ?? 1;

  const handleCreatePress = useCallback(
    () => drawer.push("createSign", { groupId }),
    [drawer, groupId]
  );

  const columns = useMemo<ColumnOptions<Sign>[]>(
    () => [
      {
        name: "status",
        header: "Status",
        render: ({ row }) => <SignStatusPill active={row.active} />,
      },
      {
        name: "location",
        header: "Location",
        render: ({ row }) => row.location,
      },
      {
        name: "url",
        header: "URL",
        render: ({ row }) =>
          row.active ? (
            <Link
              numberOfLines={1}
              href={`${baseUrl}/s/${row.id}`}
              type="title5"
              target="_blank"
            >
              Sign URL
            </Link>
          ) : (
            "\u2014"
          ),
      },
      {
        name: "spaces",
        header: "Assigned Spaces",
        render: ({ row }) => row.total_spaces ?? 0,
      },
      {
        name: "inserted_at",
        header: "Created At",
        render: ({ row }) => formatDate({ date: row.inserted_at }),
      },
    ],
    [baseUrl]
  );

  const handlePageChange = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRowPress = useCallback(
    (record: Sign) =>
      drawer.push("signDetail", { signId: record.id, propertyId }),
    [drawer, propertyId]
  );

  const keyExtractor = useCallback((row: Sign) => row.id, []);

  return (
    <Table<Sign>
      title="Signs"
      keyExtractor={keyExtractor}
      data={signs}
      columns={columns}
      currentPage={currentPage}
      totalPages={totalPages}
      totalRecords={totalRecords}
      onRowPress={handleRowPress}
      onPageChange={handlePageChange}
      pageSize={PAGE_SIZE}
      loading={isLoading || loadingParkingWebUrl}
      action={
        permissions.manage_parking ? (
          <Button onPress={handleCreatePress}>Create</Button>
        ) : null
      }
      noRecordsText="No Signs Found"
      style={style}
    />
  );
};
