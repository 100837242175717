import * as React from "react";

import { View, StyleSheet } from "react-native";
import { startCase, orderBy, sum, map } from "lodash-es";
import {
  Panel,
  PanelHeader,
  PanelHeaderTitleWithSubtitle,
  PanelBody,
  useTheme,
} from "@smartrent/ui";

import { BarChart } from "@/common/graphs/BarChart";

export interface HubHealthReportProps {
  community_wifi_details: null | { enabled: boolean; ssid: string };
  connection_report: {
    connection: string | null; // "ethernet" | "wifi" | "mobile"
    count: number;
  }[];
  online_report: { count: number; online: boolean }[];
  firmware_report: {
    type: string; // "zipato" | "smartrent" | "virtual"
    firmware: null | string;
    count: number;
  }[];
  hubs_on_community_wifi_count: number;
}

/**
 * Pure functional component
 *
 * Handle data fetching upstream and pass data to this component
 */
export const HubHealthReport: React.FC<HubHealthReportProps> = ({
  community_wifi_details,
  connection_report,
  online_report,
  firmware_report,
  hubs_on_community_wifi_count,
}) => {
  const { colors, shadows } = useTheme();

  const totalHubs = sum(map(online_report, "count"));

  return (
    <View>
      <Panel style={[styles.barGraph, shadows.light]}>
        <PanelHeader>
          <PanelHeaderTitleWithSubtitle
            title="Connection Type"
            subtitle="How the hubs are connected to the Internet"
          />
        </PanelHeader>
        <PanelBody>
          <BarChart
            colorful
            data={orderBy(connection_report, ["count"], ["desc"]).map(
              ({ connection, count }) => ({
                name: connection
                  ? `${startCase(connection)} (${count})`
                  : `Unknown (${count})`,
                count,
              })
            )}
            bars={[
              {
                dataKey: "count",
                fill: colors.primary,
                legendLabel: "Connection Type",
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel style={[styles.barGraph, shadows.light]}>
        <PanelHeader>
          <PanelHeaderTitleWithSubtitle
            title="Connection Status"
            subtitle="How many hubs are online"
          />
        </PanelHeader>
        <PanelBody>
          <BarChart
            colorful
            data={orderBy(online_report, ["count"], ["desc"]).map(
              ({ online, count }) => ({
                name: online ? `Online (${count})` : `Offline (${count})`,
                count,
              })
            )}
            bars={[
              {
                dataKey: "count",
                fill: colors.primary,
                legendLabel: "Connection Status",
              },
            ]}
          />
        </PanelBody>
      </Panel>

      <Panel style={[styles.barGraph, shadows.light]}>
        <PanelHeader>
          <PanelHeaderTitleWithSubtitle
            title="Firmware Version"
            subtitle="Which firmware the hubs are running"
          />
        </PanelHeader>
        <PanelBody>
          <BarChart
            colorful
            data={orderBy(firmware_report, ["count"], ["desc"]).map(
              ({ firmware, type, count }) => ({
                name: `${startCase(type)} ${
                  firmware ? firmware : "N/A"
                } (${count})`,
                count,
              })
            )}
            bars={[
              {
                dataKey: "count",
                fill: colors.primary,
                legendLabel: "Firmware",
              },
            ]}
          />
        </PanelBody>
      </Panel>

      {community_wifi_details?.enabled ? (
        <Panel style={[styles.barGraph, shadows.light]}>
          <PanelHeader>
            <PanelHeaderTitleWithSubtitle
              title="WiFi Device Network Connected"
              subtitle="How many hubs are connected to WiFi Device Network"
            />
          </PanelHeader>
          <PanelBody>
            <BarChart
              colorful
              data={orderBy(
                [
                  {
                    name: `Yes (${hubs_on_community_wifi_count})`,
                    count: hubs_on_community_wifi_count,
                  },
                  {
                    name: `No (${totalHubs - hubs_on_community_wifi_count})`,
                    count: totalHubs - hubs_on_community_wifi_count,
                  },
                ],
                ["count"],
                ["desc"]
              )}
              bars={[
                {
                  dataKey: "count",
                  fill: colors.primary,
                  legendLabel: "WiFi Device Network",
                },
              ]}
            />
          </PanelBody>
        </Panel>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  barGraph: {
    minWidth: 400,
    marginBottom: 32,
  },
});
