import * as React from "react";
import { StyleSheet } from "react-native";
import {
  FieldSeparator,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitleWithStatusBadge,
  Typography,
  VStack,
} from "@smartrent/ui";

import { useUser } from "@/layout/Context";
import { formatDate } from "@/utils";

import { useUnit } from "../unit/UnitContext";

export const UnitWifiCard: React.FC = () => {
  const { permissions } = useUser();
  const { residentUnitWifiConfiguration, residentUnitWifiAccounts } = useUnit();

  if (
    !permissions.manage_community_wifi ||
    !residentUnitWifiConfiguration?.enabled
  ) {
    return null;
  }

  return (
    <>
      <Panel style={styles.panel}>
        <PanelHeader>
          <PanelHeaderTitleWithStatusBadge
            title="Unit Community Wifi"
            status={residentUnitWifiAccounts.length > 0 ? "success" : "error"}
            statusLabel={
              residentUnitWifiAccounts.length > 0 ? "Enrolled" : "Not Enrolled"
            }
          />
        </PanelHeader>
        <PanelBody>
          <VStack spacing={8}>
            <Typography type="title4">Resident Activation</Typography>
            <FieldSeparator />
            {residentUnitWifiAccounts.length > 0 ? (
              residentUnitWifiAccounts.map(
                ({ first_name, last_name, enrolled_at }) => (
                  <Typography key={`${first_name} ${last_name}`} type="title6">
                    Enrolled by {first_name} {last_name} on{" "}
                    {formatDate({ date: enrolled_at, pattern: "P p" })}
                  </Typography>
                )
              )
            ) : (
              <Typography type="title6">No residents enrolled</Typography>
            )}
          </VStack>
        </PanelBody>
      </Panel>
    </>
  );
};

const styles = StyleSheet.create({
  panel: {
    flexGrow: 1,
    maxWidth: 720,
  },
});
