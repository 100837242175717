import { useCallback } from "react";
import * as React from "react";
import { FormikHelpers } from "formik";

import { DrawerBackButton } from "@smartrent/ui";

import { useToggleParkingMapModule } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";

import { ParkingMapsForm, ParkingMapsFormValues } from "./ParkingMapsForm";

interface EnableParkingMapsProps {
  propertyId: string;
}

export const EnableParkingMaps: React.FC<EnableParkingMapsProps> = ({
  propertyId,
}) => {
  const drawer = useDrawerNav();
  const [toggleParkingMapsModule] = useToggleParkingMapModule();

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);

  const handleSubmit = useCallback(
    async (
      values: ParkingMapsFormValues,
      actions: FormikHelpers<ParkingMapsFormValues>
    ) => {
      actions.setSubmitting(true);
      await toggleParkingMapsModule({
        property: {
          id: propertyId,
          map_id: values.map_id,
          maps_module_enabled: true,
        },
      });
      actions.setSubmitting(false);

      drawer.reset();
    },
    [drawer, propertyId, toggleParkingMapsModule]
  );

  return (
    <>
      <DrawerBackButton onPress={drawer.pop}>Go Back</DrawerBackButton>
      <ParkingMapsForm
        propertyId={propertyId}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </>
  );
};
