import { useMemo, useEffect, useCallback, useState } from "react";
import * as React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { utcToZonedTime } from "date-fns-tz";

import { Button, ConfirmDialog, FlatListItemSeparator } from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import { HubRequest } from "@/types";
import { useHealNetworkMutation, useLatestHubMessagesQuery } from "@/api/hub";
import { useUnit } from "@/api/units";

import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";

interface HealNetworkProps {
  hub: HubRequest;
}
export const HealNetwork: React.FC<HealNetworkProps> = ({ hub }) => {
  const { visible, onOpen, onClose } = useModalState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [sendHealNetwork, { isSuccess: healStarted }] =
    useHealNetworkMutation();
  const { data: unit } = useUnit(hub?.unit_id);

  // Get the latest hub messages from CWM Redis.
  // Runs automatically every 5 seconds.
  const { data: latestMessages, refetch: refetchLatestMessages } =
    useLatestHubMessagesQuery(
      { serial: hub.serial },
      { refetchInterval: 5000 }
    );

  useEffect(() => {
    if (healStarted) {
      refetchLatestMessages();
    }
  }, [healStarted, refetchLatestMessages]);

  const logs = useMemo(() => {
    const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC";

    // Get latest log messages, reversed, and filter out logs from more than 5 minutes ago.
    return latestMessages?.total_records > 0
      ? latestMessages.records.reverse().filter((log) => {
          const zonedTime = utcToZonedTime(log.timestamp, tz);
          const diff = differenceInMinutes(new Date(), new Date(zonedTime));

          return diff < 5;
        })
      : [];
  }, [latestMessages]);

  const onConfirm = useCallback(() => {
    onClose();

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 10000);

    sendHealNetwork({ unitId: unit?.unit?.id });
  }, [sendHealNetwork, onClose, unit]);

  const buttonStyle = useMemo(
    () => (logs.length ? styles.leftButton : styles.centerButton),
    [logs.length]
  );

  return (
    <View style={styles.container}>
      {!!unit?.unit ? (
        <Button onPress={onOpen} style={buttonStyle} disabled={buttonDisabled}>
          Heal Network
        </Button>
      ) : null}

      {logs.length ? <FlatListItemSeparator style={styles.separator} /> : null}

      {logs.map(({ message, timestamp }, key) => (
        <ListItemLabelValueCompact
          key={key}
          value={message}
          timestamp={timestamp}
          style={styles.logItem}
        />
      ))}

      <ConfirmDialog
        title="Are you sure?"
        description="Heal Network should be used when devices are online but not responding, or are slow to respond. This operation may take 3 to 5 minutes. Please do not repeatedly press the button during this time."
        visible={visible}
        onClose={onClose}
        onConfirm={onConfirm}
        testID="confirm-dialog"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    maxHeight: 500,
    overflow: "auto",
    padding: 16,
  } as Omit<ViewStyle, "overflow">,
  centerButton: {
    marginVertical: 60,
    alignSelf: "center",
  },
  leftButton: {
    alignSelf: "flex-start",
    marginVertical: 0,
    marginBottom: 16,
  },
  separator: {
    width: "100%",
  },
  logItem: {
    marginBottom: 8,
  },
});
