import { useState, useCallback } from "react";
import { MultiSelectField, useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

type DropdownData = { id: string } & Record<string, any>;

interface Props {
  onChange: (selectedRecords: DropdownData[]) => void;
  filters?: Record<string, any>;
}

export function GroupMultiSelect({ onChange, filters }: Props) {
  const [values, setValues] = useState<DropdownData[]>([]);
  const handleChange = useCallback(
    (values) => {
      setValues(values);
      onChange(values);
    },
    [setValues, onChange]
  );

  const selectQueryProps = useSelectQuery(
    (inputValue) => ["groups-table", { inputValue }] as const,
    function fetchGroups({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;
      return instance
        .get(`/groups-table`, {
          params: {
            marketing_name: inputValue ? inputValue : undefined,
            page: pageParam,
            ...(filters || {}),
          },
        })
        .then((response) => response.data as PaginatedResponse<DropdownData>);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );
  return (
    <MultiSelectField<DropdownData>
      value={values}
      onChange={handleChange}
      name="communities"
      label="Select Communities"
      getOptionValue={({ id }) => id}
      getOptionLabel={({ marketing_name }) => marketing_name}
      {...selectQueryProps}
    />
  );
}
