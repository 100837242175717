import { useCallback, useMemo } from "react";
import * as React from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerBackButton,
  DrawerContent,
  DrawerHeader,
  DrawerLoader,
  FormikSubmit,
  FormTitle,
  VStack,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import FormikAsyncOrganizationField from "@/common/FormikAsyncOrganizationField";
import FormikAsyncSensorField from "@/common/parking/FormikAsyncSensorField";
import FormikAsyncParkingGroupField from "@/common/parking/FormikAsyncParkingGroupField";
import SensorHeader from "@/common/parking/drawer/SensorHeader";
import { useSensorQuery, useUpdateSensorMutation } from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

export interface MigrateSensorProps {
  propertyId: string;
  sensorId?: string;
  navBackTitle?: string;
}

interface FormValues {
  sensor_id: string;
  property_id: string;
  organization_id: number;
}

const validationSchema = Yup.object({
  sensor_id: Yup.string().required("Please select a sensor."),
  property_id: Yup.string().required("Please select a group."),
  organization_id: Yup.number().required("Please select an organization."),
});

const MigrateSensor: React.FC<MigrateSensorProps> = ({
  propertyId,
  sensorId,
  navBackTitle,
}) => {
  const drawer = useDrawerNav();
  const { confirm } = useDialog();

  const { data: sensor, isLoading: loadingSensor } = useSensorQuery(sensorId);

  const [updateSensor] = useUpdateSensorMutation();

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(true);

      const confirmed = await confirm({
        title: "Migrate Sensor?",
        confirmText: "Migrate",
        confirmType: "destructive",
        description:
          "Are you sure you wish to change the property for this sensor?",
      });

      if (confirmed) {
        await updateSensor({
          sensor: { id: values.sensor_id, property_id: values.property_id },
        });

        drawer.reset();
      }
    },
    [confirm, drawer, updateSensor]
  );

  const initialValues: FormValues = useMemo(
    () => ({
      sensor_id: sensorId ?? "",
      property_id: "",
      organization_id: undefined,
    }),
    [sensorId]
  );

  return (
    <>
      {sensorId ? (
        <SensorHeader sensorId={sensorId} />
      ) : (
        <DrawerHeader title="Manage Sensor" />
      )}
      {navBackTitle ? (
        <DrawerBackButton onPress={drawer.pop}>{navBackTitle}</DrawerBackButton>
      ) : null}
      {loadingSensor ? (
        <DrawerLoader />
      ) : (
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          isInitialValid={true}
          validateOnChange={false}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values }) => (
            <>
              <DrawerContent>
                <FormTitle>Migrate Sensor</FormTitle>
                <VStack spacing={16}>
                  <FormikAsyncSensorField
                    label="Sensors"
                    name="sensor_id"
                    filterParams={{
                      property_id: propertyId,
                      assigned: false,
                    }}
                    sensorDevEui={sensor?.dev_eui ?? ""}
                    required={true}
                  />
                  <FormikAsyncOrganizationField
                    label="Organizations"
                    name="organization_id"
                    required={true}
                  />
                  {values.organization_id ? (
                    <FormikAsyncParkingGroupField
                      label="Groups"
                      name="property_id"
                      required={true}
                      removePropertyId={propertyId}
                      filterParams={{ organization_id: values.organization_id }}
                    />
                  ) : null}
                </VStack>
              </DrawerContent>
              <DrawerActions>
                <Button onPress={drawer.pop} variation="plain">
                  Cancel
                </Button>
                <FormikSubmit label="Migrate" submittingLabel="Migrating..." />
              </DrawerActions>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default MigrateSensor;
