import * as React from "react";

import { Tooltip, TooltipProps, useTheme } from "@smartrent/ui";

import { View, StyleSheet } from "react-native";

import { InformationSolid } from "@smartrent/icons";

import { ScopeOfWorkProps, ScopeOfWorkStatus } from "@/types/ScopeOfWorkProps";

import { formatUsersFullName } from "@/utils/format-username";

interface SowStatusBadgeProps
  extends Partial<TooltipProps>,
    Pick<ScopeOfWorkProps, "user" | "status"> {}

export const SowSubmittedByTooltip: React.FC<SowStatusBadgeProps> = ({
  user,
  status,
  ...props
}) => {
  const { colors } = useTheme();

  if (!user || status !== ScopeOfWorkStatus.Submitted) {
    return null;
  }
  return (
    <Tooltip
      name="submitted_by_tooltip"
      title={`Submitted By ${formatUsersFullName(user)}`}
      placement="right"
      numberOfLines={1}
      {...props}
    >
      <View style={styles.flexRow}>
        <InformationSolid color={colors.info} size={16} />
      </View>
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
});
