import { useMemo } from "react";
import * as React from "react";

import { HStack, StatusBadge, StatusBadgeProps } from "@smartrent/ui";

import { ScopeOfWorkStatus } from "@/types/ScopeOfWorkProps";

import { formatScopeOfWorkStatus } from "../utils";

interface SowStatusBadgeProps extends Omit<StatusBadgeProps, "children"> {
  children?: React.ReactText; // makes children prop optional
  parStatus: ScopeOfWorkStatus;
  isAnimating?: boolean;
}

export const SowStatusBadge: React.FC<SowStatusBadgeProps> = ({
  isAnimating,
  children,
  parStatus,
  ...props
}) => {
  const status = useMemo(() => {
    switch (parStatus) {
      case ScopeOfWorkStatus.Draft:
        return "default";
      case ScopeOfWorkStatus.ReadyForReview:
        return "success";
      case ScopeOfWorkStatus.Syncing:
        return "knowledge";
      case ScopeOfWorkStatus.Error:
        return "error";
      case ScopeOfWorkStatus.Submitted:
        return "knowledge";
      case ScopeOfWorkStatus.Revision:
        return "warning";
      case ScopeOfWorkStatus.Completed:
        return "default";
      default:
        return "default";
    }
  }, [parStatus]);

  const variation = useMemo(() => {
    switch (parStatus) {
      case ScopeOfWorkStatus.Draft:
        return "default";
      case ScopeOfWorkStatus.ReadyForReview:
        return "default";
      case ScopeOfWorkStatus.Submitted:
        return "default";
      case ScopeOfWorkStatus.Revision:
        return "default";
      case ScopeOfWorkStatus.Syncing:
        return "outlined";
      case ScopeOfWorkStatus.Error:
        return "default";
      case ScopeOfWorkStatus.Completed:
        return "outlined";
      default:
        return "default";
    }
  }, [parStatus]);

  if (!parStatus) {
    return null;
  }

  if (isAnimating) {
    return (
      <HStack align="center">
        {/* <Typography type="captionLarge">STATUS: </Typography> */}
        <StatusBadge status={status} variation={variation} {...props}>
          Loading...
        </StatusBadge>
      </HStack>
    );
  }
  return (
    <HStack align="center">
      <StatusBadge status={status} variation={variation} {...props}>
        {children ? children : formatScopeOfWorkStatus(parStatus)}
      </StatusBadge>
    </HStack>
  );
};
