import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { useTheme, Text } from "@smartrent/ui";

import { useIsDesktop } from "@/lib/hooks";

export interface PanelHeaderProps {
  title?: React.ReactNode;
  action?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  title,
  action,
  style,
  children,
}) => {
  const { colors } = useTheme();
  const isDesktop = useIsDesktop();

  return (
    <View
      style={[
        desktopStyles.root,
        {
          backgroundColor: colors.gray050,
          borderBottomColor: colors.gray200,
        },
        style,
      ]}
    >
      {title || action ? (
        <View
          style={
            isDesktop
              ? desktopStyles.titleContainer
              : mobileStyles.titleContainer
          }
        >
          {typeof title === "string" ? (
            <Text type="title3">{title}</Text>
          ) : (
            title
          )}
          {action}
        </View>
      ) : null}
      {children}
    </View>
  );
};

const desktopStyles = StyleSheet.create({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const mobileStyles = StyleSheet.create({
  titleContainer: {
    justifyContent: "space-between",
    alignItems: "center",
  },
});
