import { useCallback, useMemo } from "react";
import * as React from "react";

import { StyleSheet, View } from "react-native";

import {
  Button,
  Tooltip,
  Typography,
  useTheme,
  useToast,
  VStack,
} from "@smartrent/ui";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AxiosError } from "axios";

import { InformationSolid } from "@smartrent/icons";

import { SOW_QUERY_KEY, updateSowFn } from "@/api";
import { ScopeOfWorkProps, ScopeOfWorkStatus } from "@/types";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";
import { SowStatusBadge } from "../sow-report/SowStatusBadge";
import { formatScopeOfWorkStatus } from "../utils";

interface SowUpdateStatusButtonProps {
  status: ScopeOfWorkProps["status"];
}
export const SowUpdateStatusButton: React.FC<SowUpdateStatusButtonProps> = ({
  status,
}) => {
  const setToast = useToast();
  const queryClient = useQueryClient();
  const { colors } = useTheme();

  // USE SOW CONTEXT
  const {
    scopeOfWorkQuery: { data },
    scopeOfWorkQueryKey,
  } = useScopeOfWorkContext();

  const updateProps = useMemo(() => {
    switch (status) {
      case ScopeOfWorkStatus.Draft:
        return {
          ready_for_review_at: null,
          submitted_at: null,
          completed_at: null,
        };
      case ScopeOfWorkStatus.ReadyForReview:
        return {
          ready_for_review_at: data?.ready_for_review_at
            ? data?.ready_for_review_at
            : new Date().toISOString(),
          submitted_at: null,
          completed_at: null,
        };

      case ScopeOfWorkStatus.Submitted:
        return {
          ready_for_review_at: data?.ready_for_review_at
            ? data?.ready_for_review_at
            : new Date().toISOString(),
          submitted_at: data?.submitted_at
            ? data?.submitted_at
            : new Date().toISOString(),
          completed_at: null,
          passcode: "00000000",
        };
      case ScopeOfWorkStatus.Syncing:
        return {
          ready_for_review_at: data?.ready_for_review_at
            ? data?.ready_for_review_at
            : new Date().toISOString(),
          submitted_at: data?.submitted_at
            ? data?.submitted_at
            : new Date().toISOString(),
          completed_at: null,
        };
      // I do not think error can happen after complete
      //.. so we'll just clear out completed_at timestamp if we set to error
      case ScopeOfWorkStatus.Error:
        return {
          completed_at: null,
        };

      default:
        return {};
    }
  }, [data?.ready_for_review_at, data?.submitted_at, status]);

  const { isError, error, isLoading, mutateAsync } = useMutation<
    ScopeOfWorkProps,
    AxiosError,
    ScopeOfWorkProps
  >([SOW_QUERY_KEY, "update-status-button"], updateSowFn, {
    onMutate: async (variables) => {
      return variables;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(scopeOfWorkQueryKey);
      setToast({
        status: "success",
        message: `Update succeeded!`,
      });
    },
    onError: (err) => {
      queryClient.invalidateQueries(scopeOfWorkQueryKey);
      setToast({
        status: "error",
        message: `Update Status failed.`,
      });
    },
    retry: 0,
  });

  const handleOnPress = useCallback(async () => {
    return await mutateAsync({
      ...data,
      ...updateProps,
      status,
    });
  }, [data, mutateAsync, status, updateProps]);

  return (
    <VStack spacing={8} align="center">
      {isError ? (
        <Tooltip
          name={`update_error_${status}`}
          title={error?.message ? error.message : JSON.stringify(error)}
          placement="bottom"
          numberOfLines={10}
        >
          <View style={styles.flexRow}>
            <InformationSolid color={colors.error} size={16} />
            <Typography type="captionSmall" color="error" style={styles.text}>
              Error
            </Typography>
          </View>
        </Tooltip>
      ) : null}
      <Button
        onPress={handleOnPress}
        disabled={isLoading}
        variation="plain"
        textStyle={styles.salesforceIconText}
      >
        <SowStatusBadge parStatus={status} size="semibold.title.three">
          {`Set to ${formatScopeOfWorkStatus(status)}`}
        </SowStatusBadge>
      </Button>
    </VStack>
  );
};

const styles = StyleSheet.create({
  salesforceIconText: { color: "#00A1E0", paddingLeft: 8 },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  text: { paddingHorizontal: 4 },
});
