import { add, formatDistance, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";

import { InformationSolid, QuestionCircleBorder } from "@smartrent/icons";
import { Button, HStack, Tooltip, useTheme } from "@smartrent/ui";

import { useRetryFirmwareUpgradeMutation } from "@/api";

// if timestamp exists, convert from UTC & add 5 minutes to it
const getFirmwareUpgradeAttemptExpiresAt = (
  attemptedAt: Date | null,
  timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC"
) =>
  attemptedAt
    ? add(utcToZonedTime(attemptedAt, timeZone), { minutes: 5 })
    : null;

export const HubPrepRetryFirmwareUpgradeButton = ({
  serial,
  firmware_desired,
  firmware_update_required,
  firmware_upgrade_attempted_at,
  box,
}) => {
  const { colors } = useTheme();
  const [retryButtonAttemptExpiresAt, setRetryButtonAttemptExpiresAt] =
    useState<Date>(null);

  const [mutateAsync, { isLoading }] = useRetryFirmwareUpgradeMutation({
    onSettled: () =>
      setRetryButtonAttemptExpiresAt(
        getFirmwareUpgradeAttemptExpiresAt(new Date())
      ),
  });

  // box was fetched and it's online or we don't know
  const hubOnline = box && (!box.hasOwnProperty("online") || box?.online);
  const hubFirmware = box?.firmware;
  const firmwareMismatch = hubFirmware !== firmware_desired;
  const initialAttemptExpiresAt = firmware_upgrade_attempted_at
    ? getFirmwareUpgradeAttemptExpiresAt(firmware_upgrade_attempted_at)
    : null;

  const isAttemptInProgress = useCallback(
    (attemptExpiresAt) =>
      attemptExpiresAt ? isBefore(new Date(), attemptExpiresAt) : false,
    []
  );

  const getTimeRemaining = useCallback(
    (attemptExpiresAt) =>
      formatDistance(new Date(attemptExpiresAt), new Date(), {
        includeSeconds: true,
        addSuffix: true,
      }),
    []
  );

  const retryFirmwareDisabled = useMemo(
    () =>
      !hubOnline ||
      !box ||
      isAttemptInProgress(initialAttemptExpiresAt) ||
      isAttemptInProgress(retryButtonAttemptExpiresAt),
    [
      box,
      hubOnline,
      initialAttemptExpiresAt,
      isAttemptInProgress,
      retryButtonAttemptExpiresAt,
    ]
  );

  const tooltipText = useMemo(
    () =>
      !hubOnline && !box
        ? "Please Check If Hub Online With Box / Sessions Button"
        : !hubOnline
          ? "Cannot Retry Firmware, Hub Offline"
          : isAttemptInProgress(initialAttemptExpiresAt)
            ? `Retry available ${getTimeRemaining(initialAttemptExpiresAt)}`
            : isAttemptInProgress(retryButtonAttemptExpiresAt)
              ? `Retry available ${getTimeRemaining(retryButtonAttemptExpiresAt)}`
              : "Note: Hub messages will not update during any retry attempts, please add this hub to a new batch in order to see new hub messages.",
    [
      hubOnline,
      box,
      isAttemptInProgress,
      initialAttemptExpiresAt,
      getTimeRemaining,
      retryButtonAttemptExpiresAt,
    ]
  );

  if (
    !firmware_update_required ||
    !firmwareMismatch ||
    !firmware_upgrade_attempted_at
  ) {
    return null;
  }

  return (
    <HStack style={styles.root} align="center" spacing={8}>
      <Button
        accessibilityLabel="label"
        size="small"
        isLoading={isLoading}
        onPress={() => mutateAsync(serial)}
        disabled={retryFirmwareDisabled}
      >
        Retry Firmware Upgrade
      </Button>
      {!isLoading ? (
        <Tooltip
          titleStyle={styles.tooltip}
          name="retry_firmware_button"
          title={tooltipText}
          numberOfLines={2}
        >
          <View>
            {retryFirmwareDisabled ? (
              <InformationSolid
                color={colors.iconsWithinComponents}
                size={16}
              />
            ) : (
              <QuestionCircleBorder
                color={colors.iconsWithinComponents}
                size={16}
              />
            )}
          </View>
        </Tooltip>
      ) : null}
    </HStack>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 12,
    marginVertical: 12,
    width: "35%",
  },
  tooltip: { maxWidth: 450 },
});
