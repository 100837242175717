import * as React from "react";
import { ListRenderItemInfo, StyleSheet } from "react-native";

import { HStack, Link, StatusBadge, Typography, VStack } from "@smartrent/ui";

import { group as groupLink } from "@/utils/links";

interface Props {
  provider_name: string;
  group_name: string;
  group_id: string | number;
}

export const OrganizationLockedIntegrationsListItem: React.FC<
  ListRenderItemInfo<Props>
> = ({ item: { provider_name, group_name, group_id } }) => {
  return (
    <HStack align="stretch" style={styles.container}>
      <VStack>
        <Link href={groupLink(group_id)}>{group_name}</Link>
        <Typography>{provider_name}</Typography>
      </VStack>
      <StatusBadge
        status="error"
        variation="outlined"
        size="semibold.title.five"
      >
        Locked
      </StatusBadge>
    </HStack>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "space-between",
  },
});
