import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Text, useTheme } from "@smartrent/ui";
import { ChevronRight } from "@smartrent/icons";

interface QuickActionsFooterProps {
  onPress: () => void;
}

export const QuickActionsFooter: React.FC<QuickActionsFooterProps> = ({
  onPress,
}) => {
  const { colors } = useTheme();

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.root}>
        <Text type="title5" style={{ color: colors.primary }}>
          Quick Actions
        </Text>
        <ChevronRight />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 16,
  },
});
