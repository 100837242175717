import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import {
  FlatList,
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
} from "react-native";
import { isEmpty } from "lodash-es";

import { Typography } from "@smartrent/ui";

import {
  CredentialButton,
  useTppViewHook,
  ProvidersListEmpty,
  useTppViewProvidersHookProps,
  useTppViewMapCredentialsHook,
} from "@/common/third-party-providers";

import { useUser } from "@/layout/Context";

import { useGroup } from "@/pages/group/GroupContext";

import { GroupIntegrationsListItem } from "./GroupIntegrationsListItem";

export interface GroupIntegrationsListProps
  extends useTppViewProvidersHookProps {
  listKey: string;
  title: string;
  containerStyle: StyleProp<ViewStyle>;
  style: StyleProp<ViewStyle>;
}

export const GroupIntegrationsList: React.FC<GroupIntegrationsListProps> = ({
  listKey,
  title,
  tppc_scope,
  groupId,
  organizationId,
  containerStyle,
  style,
}) => {
  const { permissions } = useUser();
  const { group } = useGroup();

  const { providers } = useTppViewHook({
    tppc_scope,
    groupId,
    organizationId,
  });

  const [hasPermissions] = useState(
    permissions?.integration_credentials_v2 || false
  );

  const isButtonDisplayed = useMemo(
    () => hasPermissions && !isEmpty(providers),
    [hasPermissions, providers]
  );

  const [isVisible, setIsVisible] = useState(false);
  const handleButtonPress = useCallback(() => setIsVisible((v) => !v), []);

  // this is the magic that gives us credentials to display
  const {
    providersWithCredentials,
    isLoading: isLoading2,
    isError: isError2,
  } = useTppViewMapCredentialsHook({
    data: providers,
    groupId,
    enabled: hasPermissions,
  });
  return (
    <>
      <View
        // creds button ends up making this view a little bigger
        style={
          isButtonDisplayed
            ? containerStyle
            : [containerStyle, styles.adjustForButton]
        }
      >
        <Typography type="title5">{title}</Typography>

        {isButtonDisplayed ? (
          <CredentialButton onPress={handleButtonPress} isVisible={isVisible} />
        ) : null}
      </View>
      {hasPermissions && isButtonDisplayed && isVisible ? (
        isLoading2 ? (
          <View style={styles.centerAlign}>
            <ActivityIndicator />
          </View>
        ) : (
          <FlatList
            key={`${listKey}-credentials`}
            keyExtractor={(item, index) => `${listKey}-credentials-${index}`}
            testID={`${listKey}-credentials-list`}
            centerContent={true}
            style={style}
            data={providersWithCredentials}
            renderItem={(props) => (
              <GroupIntegrationsListItem
                {...props}
                displayCredentials={true}
                group={group}
              />
            )}
            ListEmptyComponent={ProvidersListEmpty}
            ListFooterComponent={
              isError2 ? (
                <Typography
                  style={styles.smallPaddingTop}
                  type="caption"
                  color="mutedTextError"
                >
                  Ooops! There was an error when fetching credentials..
                </Typography>
              ) : null
            }
          />
        )
      ) : (
        <FlatList
          key={listKey}
          keyExtractor={(item, index) => `${listKey}-${index}`}
          testID={`${listKey}-list`}
          centerContent={true}
          style={style}
          ListEmptyComponent={ProvidersListEmpty}
          data={providers}
          renderItem={(props) => (
            <GroupIntegrationsListItem
              {...props}
              displayCredentials={false}
              group={group}
            />
          )}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  adjustForButton: {
    paddingBottom: 12,
  },
  centerAlign: { alignItems: "center", marginTop: 8 },
  smallPaddingTop: {
    paddingTop: 4,
  },
});
