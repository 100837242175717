/* Dev Note: This file is deprecated, use sr-table/Table.tsx */

import { useCallback, useState, useEffect, useMemo, ReactNode } from "react";

import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from "react-native";

import { Text, SearchInput } from "@smartrent/ui";
import { CaretDown, CaretUp, CaretUpDown } from "@smartrent/icons";

import { useDebounce } from "@/lib/hooks";
import helpers from "@/lib/helpers";

import ReactSelect from "../ReactSelect";
import Icon from "../Icon";

import { GenericObject } from "../../types";

interface SelectFilterOptions {
  options: {
    label: string;
    value: string;
  }[];
}

export interface PaginatedTableColumn {
  field: string;
  label: string;
  style?: StyleProp<ViewStyle>;
  filter?: "text" | SelectFilterOptions;
  sortable?: boolean;
  render: (r: unknown) => ReactNode;
  hidden?: boolean;
}

const TEXT_FILTER = "text";
const ASCENDING = "asc";
const DESCENDING = "desc";

interface PaginatedTableColumnProps extends PaginatedTableColumn {}

const PaginatedTableColumnHeader: React.FC<PaginatedTableColumnProps> = ({
  field,
  label,
  sortable = false,
  filter,
  style,
}) => {
  const history = useHistory();
  const location = useLocation();
  const filters = useMemo(() => helpers.qsFromLocation(location), [location]);
  const [filterValue, setFilterValue] = useState<any>(filters[field]);
  const debouncedFilterValue = useDebounce(filterValue, 600);

  const updateFilters = useCallback(
    (updates: GenericObject) =>
      helpers.updateQS({
        history,
        location,
        // reset to page 1 when filters change
        update: { page: 1, ...updates },
      }),
    [history, location]
  );

  useEffect(() => {
    if (filter === TEXT_FILTER && debouncedFilterValue !== filters[field]) {
      updateFilters({ [field]: debouncedFilterValue });
    }
  }, [debouncedFilterValue, filters, updateFilters, field, filter]);

  const getSortIcon = () => {
    let icon;

    if (filters.sort === field) {
      icon = filters.dir === ASCENDING ? <CaretDown /> : <CaretUp />;
    } else {
      icon = <CaretUpDown />;
    }
    return icon;
  };

  return (
    <View style={[styles.filterHeaderCell, style]}>
      <TouchableOpacity
        disabled={!sortable}
        onPress={() => {
          updateFilters({
            sort: field,
            dir: filters.dir === ASCENDING ? DESCENDING : ASCENDING,
          });
        }}
      >
        <View style={styles.filterHeaderText}>
          <Text style={styles.filterHeaderTextText}>{label}</Text>
          {sortable && <Icon className="icon--gray-600" icon={getSortIcon()} />}
        </View>
      </TouchableOpacity>
      {filter && (
        <>
          {filter === TEXT_FILTER ? (
            <SearchInput
              onChangeText={(newValue) => {
                setFilterValue(newValue);
              }}
              onClearInput={() => {
                updateFilters({ [field]: "" });
                setFilterValue("");
              }}
              placeholder={`Enter ${label} ...`}
              value={filterValue ? filterValue : ""}
            />
          ) : (
            <ReactSelect
              isClearable
              isSearchable
              onChange={(newValue: any) =>
                updateFilters({ [field]: newValue?.value })
              }
              options={filter.options}
              value={
                filters[field]
                  ? filter.options.find(
                      (value) => value.value === filters[field]
                    )
                  : null
              }
            />
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  filterHeaderCell: {
    flex: 1,
    marginRight: 16,
    paddingHorizontal: 8,
    zIndex: 5,
    flexBasis: 1,
  },
  filterHeaderText: {
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  filterHeaderTextText: {
    fontSize: 14,
    fontWeight: "600",
  },
});

export default PaginatedTableColumnHeader;
