import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { useTheme } from "@smartrent/ui";

export interface CardHeaderProps {
  style?: StyleProp<ViewStyle>;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ children, style }) => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.cardHeader,
        { backgroundColor: colors.pageBackground },
        style,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  cardHeader: {
    padding: 18,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
