import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
export interface FixedHeightCardBodyProps {
  closedHeight?: number;
  open?: boolean;
  style?: StyleProp<ViewStyle>;
  overflowHidden?: boolean;
}

export const FixedHeightCardBody: React.FC<FixedHeightCardBodyProps> = ({
  children,
  style,
  closedHeight = 264,
  open = false,
  overflowHidden = false,
}) => {
  const overflow = useMemo(
    () => (open ? "visible" : overflowHidden ? "hidden" : "auto"),
    [open, overflowHidden]
  );
  const maxHeight = useMemo(
    () => (open ? "none" : closedHeight),
    [closedHeight, open]
  );

  return (
    <View style={[styles.cardBody, { maxHeight, overflow } as any, style]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  cardBody: {
    padding: 18,
    flexGrow: 1,
  },
});
