import { useCallback, useState } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import type { QueryFunction } from "@tanstack/react-query";

import {
  Panel,
  PanelBody,
  Table,
  useTableQuery,
  QueryKey,
  Stack,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import { useGlobalContext } from "@/layout/Context";

import { instance } from "@/lib/hooks";

import { formatDate } from "@/utils";
import {
  GroupsManagedWifiAccessPointsResponse,
  ManagedWifiAccessPoints,
} from "@/api/group";
import Layout from "@/layout/Layout";
import { UrlOrgGroupBreadcrumbs } from "@/common/breadcrumbs/UrlOrgGroupBreadcrumbs";

import { useGroup } from "./GroupContext";

export const GroupCommunityWifiAccessPoints: React.VFC = () => {
  const DEFAULT_PAGE_SIZE = 25;
  const { group } = useGroup();
  const { match } = useGlobalContext();

  const drawer = useDrawerNav();

  const [groupId] = useState(match?.params?.groupId);

  const fetchManagedWifiAccessPoints: QueryFunction<
    GroupsManagedWifiAccessPointsResponse
  > = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , filters] = queryKey;
    const { data } = await instance.get<GroupsManagedWifiAccessPointsResponse>(
      `/groups/${groupId}/managed-wifi/access-points`,
      {
        params: filters,
      }
    );
    return data;
  };

  const tableProps = useTableQuery<QueryKey, ManagedWifiAccessPoints, any>({
    fetch: fetchManagedWifiAccessPoints,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "managed-wifi-access-points-query-key",
      {},
      {
        ...filters,
        groupId,
        page,
        limit: pageSize,
        sort: sortColumn,
        dir: sortDirection,
      },
    ],
    defaultPageSize: DEFAULT_PAGE_SIZE,
    columns: [
      {
        name: "id",
        header: "ID",
        render: ({ row: { id } }) => id,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "name",
        header: "Name",
        render: ({ row: { name } }) => name,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "last_seen_at",
        header: "Last Seen At",
        render: ({ row: { last_seen_at } }) =>
          formatDate({ date: last_seen_at }),
      },
    ],
  });

  const handleRowPress = useCallback(
    (row) => drawer.push("manageWifiAccessPoints", row),
    [drawer]
  );

  return (
    <Layout>
      <Stack style={styles.linkTree}>
        <UrlOrgGroupBreadcrumbs currentPage="Manage Access Points" />
      </Stack>
      <Panel style={styles.panel}>
        <PanelBody>
          <Table<ManagedWifiAccessPoints>
            title={`${group.marketing_name} - Community Wifi Access Points`}
            noRecordsText="No Access Points Found"
            onRowPress={handleRowPress}
            {...tableProps}
          />
        </PanelBody>
      </Panel>
    </Layout>
  );
};
const styles = StyleSheet.create({
  panel: { height: "calc(100vh - 40px)" },
  linkTree: {
    padding: 24,
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
});
