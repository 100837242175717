import * as React from "react";
import { startCase } from "lodash-es";

import { ExclamationCircleSolid } from "@smartrent/icons";
import { HStack, Typography, useTheme, VStack } from "@smartrent/ui";

import { HubRefurbishErrorNames, RunHubRefurbishBatchJobError } from "@/types";

export const HubRefurbishErrorMessage: React.FC<
  RunHubRefurbishBatchJobError["error"]["context"]
> = ({ name, message }) => {
  const { colors } = useTheme();

  // these errors names should all be bubbled up from support-api
  const errorName = startCase(name || HubRefurbishErrorNames.UnexpectedError);

  const errorMessage =
    message ||
    "We encountered an unexpected error, please try again and contact engineering if this issue persists.";

  return (
    <VStack spacing={8} align="start" wrap={true} style={{ flex: 1 }}>
      <HStack align="center" spacing={4}>
        <ExclamationCircleSolid color={colors.mutedBadgeWarning} size={14} />{" "}
        <Typography type="bodySemibold">{errorName}</Typography>
      </HStack>
      <Typography type="body" numberOfLines={3}>
        {errorMessage}
      </Typography>
    </VStack>
  );
};
