import * as React from "react";
import classnames from "classnames";

interface LoadingDotsProps {
  color?: "primary" | "white" | "gray" | "black";
}

export const LoadingDots: React.FC<LoadingDotsProps> = ({
  color = "primary",
}) => {
  const dotClassName = classnames("loading-dots__dot", {
    // color === "black" is intentionally omitted
    "loading-dots__dot--primary": color === "primary",
    "loading-dots__dot--white": color === "white",
    "loading-dots__dot--gray-300": color === "gray",
  });

  return (
    <div className="loading-dots">
      <div className={dotClassName} />
      <div className={dotClassName} />
      <div className={dotClassName} />
    </div>
  );
};
