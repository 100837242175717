import * as React from "react";
import { View, StyleSheet } from "react-native";

import { StatusBadge, Typography, useTheme } from "@smartrent/ui";

import { ParkingProperty } from "@/types";

interface ModuleProps {
  name: string;
  enabled: boolean;
}

const Module: React.FC<ModuleProps> = ({ name, enabled }) => {
  return (
    <View style={styles.item}>
      <View>
        <Typography font="semibold" color="textSecondary">
          {name}:
        </Typography>
      </View>
      <View style={styles.status}>
        {enabled ? (
          <StatusBadge
            variation="outlined"
            status="success"
            size="semibold.title.five"
          >
            Enabled
          </StatusBadge>
        ) : (
          <StatusBadge
            variation="outlined"
            status="default"
            size="semibold.title.five"
          >
            Disabled
          </StatusBadge>
        )}
      </View>
    </View>
  );
};

interface ParkingModulesProps {
  parkingGroup: Partial<ParkingProperty>;
}

export const ParkingModules: React.FC<ParkingModulesProps> = ({
  parkingGroup,
}) => {
  const { colors } = useTheme();

  return (
    <View>
      <View
        style={[styles.sectionTitle, { borderBottomColor: colors.gray100 }]}
      >
        <Typography type="title5">MODULE INSIGHTS</Typography>
      </View>
      <Module name="Decals" enabled={parkingGroup.decal_module_enabled} />
      <Module
        name="Guest Parking"
        enabled={parkingGroup.guest_parking_module_enabled}
      />
      <Module
        name="Guest Payments"
        enabled={parkingGroup.guest_parking_payments_module_enabled}
      />
      <Module name="Maps" enabled={parkingGroup.maps_module_enabled} />
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  sectionTitle: {
    borderBottomWidth: 1,
    paddingBottom: 8,
    marginBottom: 16,
    marginTop: 16,
  },
  status: {
    display: "flex",
    justifyContent: "space-between",
  },
});
