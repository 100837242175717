import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import {
  Button,
  HStack,
  Typography,
  VStack,
  PanelHeader,
  Panel,
  useServerImages,
  ImageUploadField,
  ConfirmDialog,
  Dialog,
  DialogContent,
} from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";
import { Plus, Trash } from "@smartrent/icons";

import { EM_DASH } from "@/utils/chars";
import { ScopeOfWorkDeviceProps } from "@/types";

import {
  ProductPrice,
  useCreateSowDeviceImagesMutation,
  useDeleteSowDeviceImagesMutation,
} from "@/api";

import { formatDate, formatDateToBeHumanReadable } from "@/utils";

import { useDrawerNav } from "@/common/AppDrawer";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";

import { SowListItem } from "./SowListItem";
import { SowNotesItem } from "./SowNotesItem";
import { SowDevicePriceItem } from "./SowDevicePriceItem";

export const ScopeOfWorkDevice: React.FC<{
  device: ScopeOfWorkDeviceProps;
  productPrice?: ProductPrice;
  isPriceLoading: boolean;
}> = ({ device, productPrice, isPriceLoading }) => {
  const {
    visible: confirmVisible,
    onClose: onConfirmClose,
    onOpen: onConfirmOpen,
  } = useModalState();
  const {
    visible: uploadVisible,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useModalState();
  const [images, setImages] = useServerImages();
  const [imageId, setImageId] = React.useState<string>("");
  const [isImageUploading, setIsImageUploading] = React.useState(false);
  const [isImageDeleting, setIsImageDeleting] = React.useState(false);

  const drawer = useDrawerNav();

  const {
    scopeOfWorkQuery: { data: scopeOfWork },
  } = useScopeOfWorkContext();

  const [createDeviceImages] = useCreateSowDeviceImagesMutation();
  const [deleteDeviceImage] = useDeleteSowDeviceImagesMutation();

  const setImageToDelete = (imageId: string) => {
    setImageId(imageId);
    onConfirmOpen();
  };
  const confirmDeleteImage = async () => {
    if (!scopeOfWork) {
      return;
    }

    setIsImageDeleting(true);
    try {
      await deleteDeviceImage({
        scope_of_work_id: scopeOfWork.id,
        sow_device_id: device.id,
        imageId,
      });
    } catch (error) {
      console.log(error);
    }
    // delete image
    onConfirmClose();
    setIsImageDeleting(false);
  };

  const cancelUpload = () => {
    onUploadClose();
    setImages([]);
  };
  const uploadImages = async () => {
    if (images.length === 0 || !scopeOfWork) {
      return;
    }

    setIsImageUploading(true);

    try {
      await createDeviceImages({
        scope_of_work_id: scopeOfWork.id,
        sow_device_id: device.id,
        urls: images.map((image) => image.url),
      });
    } catch (error) {
      console.log(error);
    }

    onUploadClose();
    setIsImageUploading(false);
    setImages([]);
  };

  return (
    <Panel key={device.id}>
      <PanelHeader>
        <HStack spacing={8} align="center">
          <Typography type="title4">
            {device.sf_device_sku ? device.sf_device_sku : EM_DASH}
          </Typography>
        </HStack>
      </PanelHeader>
      <VStack spacing={16} style={styles.devicePadding}>
        <HStack spacing={32}>
          <SowListItem
            label="Quantity:"
            value={device.quantity ? device.quantity.toLocaleString() : EM_DASH}
          />
          <SowDevicePriceItem
            device={device}
            loading={isPriceLoading}
            productPrice={productPrice}
          />
          <SowListItem
            label="Notes Accepted:"
            value={device.notes ? (device.is_accepted ? "Yes" : "No") : "N/A"}
          />
          <SowListItem
            label="Notes Accepted At:"
            value={
              device.accepted_at
                ? `${formatDate({
                    date: device.accepted_at,
                  })} (${formatDateToBeHumanReadable({
                    date: device.accepted_at,
                  })})`
                : EM_DASH
            }
          />
        </HStack>
        <VStack spacing={8}>
          {device.notes ? (
            <SowNotesItem label="Notes:" value={device.notes} />
          ) : null}
          {scopeOfWork?.id && device?.scope_of_work_revision?.length > 0 ? (
            <HStack justify="end">
              <Button
                onPress={() => {
                  drawer.push("scopeOfWorkRevisions", {
                    revisions: device.scope_of_work_revision,
                    scope_of_work_id: scopeOfWork.id,
                    scope_of_work_device_id: device.id,
                    device_sku: device.sf_device_sku,
                  });
                }}
              >
                See Revision Notes
              </Button>
            </HStack>
          ) : null}
        </VStack>

        <HStack spacing={4} justify="start" style={{ overflow: "scroll" }}>
          <TouchableOpacity
            style={styles.addImageButton}
            onPress={onUploadOpen}
          >
            <Plus size={24} />
            <Typography
              type="title5"
              style={{ textAlign: "center" }}
              color="brand500"
            >
              Add Image
            </Typography>
          </TouchableOpacity>
          {device.scope_of_work_images.map((image) => (
            <View key={image.id} style={styles.image}>
              <img
                src={image?.url}
                style={{ borderRadius: 8, height: 156 }}
                alt="Device"
              />
              <TouchableOpacity
                style={styles.imageDeleteButton}
                onPress={() => setImageToDelete(image.id)}
              >
                <Trash size={16} />
              </TouchableOpacity>
            </View>
          ))}
        </HStack>
      </VStack>
      <Dialog
        titleID="imageUpload"
        visible={uploadVisible}
        onClose={cancelUpload}
      >
        <DialogContent>
          <View style={styles.dialogContent}>
            <ImageUploadField
              images={images}
              onFilesChange={(images) => setImages(images)}
              aspectRatio={1}
              label="Upload photos"
              multiple
              style={styles.imageUpload}
            />
            <HStack spacing={4} justify="space-between" width="100%">
              <Button onPress={cancelUpload} variation="plain">
                Cancel
              </Button>
              <Button
                onPress={uploadImages}
                disabled={images.length < 1}
                isLoading={isImageUploading}
              >
                Upload
              </Button>
            </HStack>
          </View>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title="Delete Image"
        description="Are you sure you want to permanently delete this image?"
        visible={confirmVisible}
        onClose={() => {
          onConfirmClose();
          setImageId("");
        }}
        onConfirm={confirmDeleteImage}
        confirmButtonLoading={isImageDeleting}
        testID="confirm-dialog"
      />
    </Panel>
  );
};

const styles = StyleSheet.create({
  dialogContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    alignItems: "center",
    overflowY: "scroll",
    maxHeight: 700,
  },
  imageUpload: {
    minWidth: 350,
    width: "100%",
  },
  addImageButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: 156,
    width: 100,
    userSelect: "none",
    cursor: "pointer",
    border: "1px grey dashed",
    borderRadius: 8,
  },
  image: {
    position: "relative",
  },
  imageDeleteButton: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 25,
    justifyContent: "center",
    position: "absolute",
    bottom: 6,
    right: 6,
    width: 32,
    height: 32,
    zIndex: 5,
  },
  devicePadding: { padding: 16 },
});
