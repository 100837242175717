import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { useField } from "formik";

import {
  SelectField,
  SelectOption,
  TextInputFieldHelperText,
  useTheme,
} from "@smartrent/ui";

import { useSensorsInfiniteQuery } from "@/api";
import { useDebounce } from "@/lib/hooks";
import { LoadingDots } from "@/common/LoadingDots";

export interface FormikAsyncSensorFieldProps {
  name: string;
  label: string;
  filterParams: any;
  required?: boolean;
  sensorDevEui?: string;
}

const FormikAsyncSensorField: React.FC<FormikAsyncSensorFieldProps> = ({
  name,
  label,
  filterParams,
  required,
  sensorDevEui,
}) => {
  const [textInput, setTextInput] = useState(sensorDevEui);
  const [, meta, helpers] = useField(name);
  const [value, setValue] = useState<SelectOption | undefined>(undefined);
  const { colors } = useTheme();

  const debouncedTextInput = useDebounce(textInput, 500);

  const { reducedData, isFetching, hasNextPage, fetchNextPage } =
    useSensorsInfiniteQuery({
      ...filterParams,
      dev_eui: debouncedTextInput,
      limit: 50,
    });

  const options = useMemo(
    () =>
      reducedData.map((sensor) => ({
        value: sensor.id,
        label: sensor.dev_eui,
      })),
    [reducedData]
  );

  const handleEndReached = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const handleChange = useCallback(
    (option: SelectOption) => {
      setValue(option);
      helpers.setValue(option?.value ?? "");
    },
    [helpers]
  );

  return (
    <>
      <SelectField
        name={name}
        label={label}
        onChange={handleChange}
        options={options}
        disabled={!!sensorDevEui}
        required={required}
        value={
          sensorDevEui
            ? options.find((option) => option.label === sensorDevEui)
            : value
        }
        StartAdornment={isFetching ? () => <LoadingDots color="gray" /> : null}
        onTextInputChange={setTextInput}
        flatListProps={{ onEndReached: handleEndReached }}
      />
      <TextInputFieldHelperText color={colors.error}>
        {meta.error}
      </TextInputFieldHelperText>
    </>
  );
};

export default FormikAsyncSensorField;
