import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Typography, Button, AlertDialog } from "@smartrent/ui";
import { Hub, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useHubRebootQuery } from "@/api/hub";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";
import { HubRequest } from "@/types";

export interface RebootHubProps {
  hub: HubRequest;
  unitId: number;
}
export const RebootHub: React.FC<RebootHubProps> = ({ hub, unitId }) => {
  const { onOpen: openHelp, onClose, visible } = useModalState();
  const [sendHubReboot] = useHubRebootQuery();

  const onClick = useCallback(async () => {
    await sendHubReboot({ serial: hub?.serial, unitId });
  }, [sendHubReboot, hub?.serial, unitId]);

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Reboot Hub</Typography>
            {!hub?.online ? (
              <Typography type="caption" color="warning">
                Hub is offline
              </Typography>
            ) : null}
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton
            disabled={!hub?.online}
            icon={Hub}
            onClick={() => {}}
          />
        </CardBody>
        <CardFooter>
          <Button disabled={!hub?.online} onPress={onClick}>
            Run Action
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="Reboot can be used if Heal Network is taking too long. Healing will stop automatically after 10 minutes if the heal couldn't be completed."
        visible={visible}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
});
