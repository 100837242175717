import * as React from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { HStack, Panel, PanelHeader, Typography } from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { ScopeOfWorkStatus } from "@/types";

import { SowUpdateStatusButton } from "../debug/SowUpdateStatusButton";

interface SowDebugActionsPanelProps {
  style: ViewStyle;
}

export const SowDebugActionsPanel: React.FC<SowDebugActionsPanelProps> = ({
  style,
}) => {
  const { permissions } = useUser();

  // only show this button for debug users for now
  if (!permissions?.debug_sow) {
    return null;
  }

  return (
    <Panel style={style}>
      <PanelHeader
        style={styles.header}
        EndAdornment={
          <HStack spacing={16} align="center">
            <SowUpdateStatusButton status={ScopeOfWorkStatus.Draft} />
            <SowUpdateStatusButton status={ScopeOfWorkStatus.ReadyForReview} />
            <SowUpdateStatusButton status={ScopeOfWorkStatus.Revision} />
            <SowUpdateStatusButton status={ScopeOfWorkStatus.Submitted} />
            <SowUpdateStatusButton status={ScopeOfWorkStatus.Syncing} />
            <SowUpdateStatusButton status={ScopeOfWorkStatus.Error} />
          </HStack>
        }
      >
        <Typography type="title2">Debug Actions</Typography>
      </PanelHeader>
    </Panel>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: "space-between",
  },
});
