import { useCallback } from "react";
import * as React from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerLoader,
  FormikPhoneInputField,
  FormikSubmit,
  FormikTextInputField,
  VStack,
} from "@smartrent/ui";
import { yupPhone } from "@smartrent/utils";

import { useGroupQuery, useOrderDecals, useOrganizationQuery } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import { NoRecords } from "@/common/sr-table/NoRecords";

interface Props {
  propertyId: string;
  groupId: number;
}

interface FormValues {
  count: number;
  phone_number: string;
}

const initialValues: FormValues = {
  count: undefined,
  phone_number: undefined,
};

const validationSchema = Yup.object({
  count: Yup.number().required("Please enter the count.").integer().positive(),
  phone_number: yupPhone().required("Please enter a phone number."),
});

const OrderDecals: React.FC<Props> = ({ propertyId, groupId }) => {
  const drawer = useDrawerNav();

  const { data: group, isLoading: loadingGroup } = useGroupQuery(groupId);
  const { data: organization, isLoading: loadingOrganization } =
    useOrganizationQuery(group?.organization_id ?? undefined);

  const loading = loadingGroup || loadingOrganization;

  const [orderDecals] = useOrderDecals();

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(true);

      await orderDecals({
        propertyId,
        count: values.count,
        marketingName: group.marketing_name,
        city: group.city,
        state: group.state,
        streetAddress1: group.street_address_1,
        streetAddress2: group.street_address_2,
        zip: group.zip,
        phoneNumber: values.phone_number,
        organizationName: organization?.organization?.name ?? "",
      });

      drawer.pop();
    },
    [group, orderDecals, propertyId, organization, drawer]
  );

  if (!loading && !group) {
    return <NoRecords title="Failed to Load Group" />;
  }

  if (!loading && !organization) {
    return <NoRecords title="Failed to Load Organization" />;
  }

  return loading ? (
    <DrawerLoader />
  ) : (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <VStack spacing={16}>
            <FormikTextInputField
              name="count"
              label="Quantity of Decals"
              required
            />
            <FormikPhoneInputField
              name="phone_number"
              label="Phone Number"
              required
            />
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button onPress={drawer.pop} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label="Order" submittingLabel="Ordering..." />
        </DrawerActions>
      </>
    </Formik>
  );
};

export default OrderDecals;
