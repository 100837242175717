import { useCallback } from "react";
import * as React from "react";
import { TouchableOpacity } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";
import { Pencil } from "@smartrent/icons";

import { useDrawerNav } from "@/common/AppDrawer";

interface EditSignActionProps {
  signId: string;
  propertyId: string;
  variation?: "plain" | "icon";
}

export const EditSignAction: React.FC<EditSignActionProps> = ({
  signId,
  propertyId,
  variation = "icon",
}) => {
  const { colors } = useTheme();
  const drawer = useDrawerNav();

  const handleEdit = useCallback(
    () => drawer.push("editSign", { signId, propertyId }),
    [drawer, signId, propertyId]
  );

  return variation === "icon" ? (
    <TouchableOpacity onPress={handleEdit}>
      <Pencil color={colors.primary} />
    </TouchableOpacity>
  ) : (
    <TouchableOpacity onPress={handleEdit}>
      <Typography color="primary" type="title5">
        EDIT
      </Typography>
    </TouchableOpacity>
  );
};
