import { useCallback } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { Button, HStack, Typography, VStack } from "@smartrent/ui";

import { Pencil, UnitRoom } from "@smartrent/icons";

import { Accordion } from "@/common/Accordion";

import { useGlobalContext } from "@/layout/Context";

import { formatAddress } from "@/utils/format-address";
import { getOrgName } from "@/utils";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";

import { SowListItem } from "./SowListItem";

export const SowPropertySection: React.FC = () => {
  const { user, history } = useGlobalContext();

  // USE SOW CONTEXT
  const {
    pathname,
    scopeOfWorkQuery: { data },
  } = useScopeOfWorkContext();

  const handleEditOnPress = useCallback(
    () => history.push(`${pathname}/details`),
    [history, pathname]
  );

  return (
    <Accordion
      initialOpenState={false}
      isDisabled={false}
      title={
        <HStack align="center" justify="space-between" style={styles.flex}>
          <HStack align="center" spacing={4}>
            <UnitRoom size={40} />
            <Typography type="title2">Property Details</Typography>
          </HStack>
          {user?.permissions?.manage_sow ? (
            <Button
              iconLeft={Pencil}
              onPress={handleEditOnPress}
              style={styles.buttonSpacing}
              variation="muted"
              size="x-small"
            >
              Edit
            </Button>
          ) : null}
        </HStack>
      }
      style={styles.accordion}
    >
      <VStack spacing={16}>
        <HStack spacing={128} style={styles.propertyPadding}>
          <VStack spacing={16} justify="space-between">
            <SowListItem
              label="Organization Name:"
              value={getOrgName(data?.organization, data?.group)}
            />
            <SowListItem
              label="Property Name:"
              value={data?.group?.marketing_name}
            />
            <SowListItem label="Address:" value={formatAddress(data?.group)} />
          </VStack>
          <VStack spacing={16} justify="space-between">
            <SowListItem
              label="Units:"
              value={data?.scope_of_work_detail?.units_count}
            />
            <SowListItem
              label="Floors:"
              value={data?.scope_of_work_detail?.floors_count}
            />
            <SowListItem
              label="Floor Plans:"
              value={data?.scope_of_work_detail?.floor_plans_count}
            />
          </VStack>
          <VStack spacing={16} justify="space-between">
            <SowListItem
              label="Phases:"
              value={data?.scope_of_work_detail?.phases_count}
            />
            <SowListItem
              label="Cell Quality:"
              value={data?.scope_of_work_detail?.cell_quality}
            />
          </VStack>
        </HStack>
      </VStack>
    </Accordion>
  );
};

const styles = StyleSheet.create({
  accordion: {
    width: "100%",
    flex: 1,
  },
  buttonSpacing: { marginRight: 16 },
  propertyPadding: { padding: 16 },
  flex: { width: "100%", flex: 1 },
});
