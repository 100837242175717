import * as React from "react";
import { GestureResponderEvent, StyleSheet } from "react-native";

import { Button, StatusBadgeProps } from "@smartrent/ui";
import { Invisible, Visible } from "@smartrent/icons";

interface CredentialsButtonProps extends Partial<StatusBadgeProps> {
  onPress: (event: GestureResponderEvent) => void;
  isVisible: boolean;
}
export const CredentialButton: React.FC<CredentialsButtonProps> = ({
  onPress,
  isVisible,
}) => {
  return (
    <Button
      size="small"
      variation="plain"
      onPress={onPress}
      contentStyle={styles.buttonContent}
      textStyle={styles.buttonText}
      iconRight={isVisible ? Invisible : Visible}
    >
      {isVisible ? "Hide Credentials" : "Show Credentials"}
    </Button>
  );
};

const styles = StyleSheet.create({
  buttonContent: {
    flexWrap: "wrap",
    paddingVertical: 6,
    paddingLeft: 16,
    paddingRight: 2,
  },
  buttonText: { fontSize: 12, lineHeight: 18, marginRight: -7 },
});
