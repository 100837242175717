import { useMemo } from "react";
import * as React from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";

import {
  Button,
  DrawerActions,
  DrawerContent,
  FormikSubmit,
  FormikMultiSelectField,
  MultiSelectOption,
  VStack,
} from "@smartrent/ui";

import { VendorApplication, GroupProps } from "@/types";
import { useGroupsQuery } from "@/api";

export interface GroupVendorApplicationFormValues {
  groupIds: number[];
}

interface GroupVendorApplicationFormProps {
  vendorApplication: VendorApplication;
  onSubmit: (
    values: GroupVendorApplicationFormValues,
    actions: FormikHelpers<GroupVendorApplicationFormValues>
  ) => void;
  onCancel: () => void;
}

const initialValues = {
  groupIds: [],
};

const validationSchema = Yup.object({
  groupIds: Yup.array().min(1).required("Please select a group."),
});

export const GroupVendorApplicationForm: React.FC<
  GroupVendorApplicationFormProps
> = ({ vendorApplication, onSubmit, onCancel }) => {
  const { data: groups } = useGroupsQuery(vendorApplication.organization_id, {
    active: true,
  });

  // remove groups that are already assigned to this vendor application
  const groupOptions = useMemo<MultiSelectOption[]>(
    () =>
      groups
        ?.filter(
          (group: GroupProps) =>
            !vendorApplication.groups?.find((g) => g.id == group.id)
        )
        ?.map((group: GroupProps) => ({
          label: group.marketing_name,
          value: group.id,
        })) ?? [],
    [vendorApplication, groups]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <VStack spacing={8}>
            {groupOptions?.length ? (
              <FormikMultiSelectField
                name="groupIds"
                label="Groups"
                options={groupOptions}
              />
            ) : null}
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button onPress={onCancel} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label="Assign" />
        </DrawerActions>
      </>
    </Formik>
  );
};
