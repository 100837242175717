import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleProp, ViewStyle, StyleSheet } from "react-native";
import { compact } from "lodash-es";

import { Button } from "@smartrent/ui";

import { useParkingPropertyQuery } from "@/api";
import { Panel, PanelKeyValueList } from "@/common/panel";
import { useUser } from "@/layout/Context";
import { formatCents, formatPercent } from "@/lib/helpers";
import { useDrawerNav } from "@/common/AppDrawer";

interface Props {
  propertyId: string;
  style?: StyleProp<ViewStyle>;
}

export const GuestPaymentsPanel: React.FC<Props> = ({ propertyId, style }) => {
  const { permissions } = useUser();
  const drawer = useDrawerNav();

  const { data: parkingProperty, isLoading } =
    useParkingPropertyQuery(propertyId);

  const items = useMemo(
    () =>
      compact([
        {
          label: "Stripe Connected Account",
          value: parkingProperty?.stripe_connect_id ?? "\u2014",
        },
        {
          label: "Hourly Rate",
          value: formatCents(parkingProperty?.guest_parking_rate) ?? "\u2014",
        },

        {
          label: "Max Session Limit",
          value:
            formatCents(parkingProperty?.guest_parking_session_max_subtotal) ??
            "\u2014",
        },
        {
          label: "Sales Tax %",
          value:
            formatPercent(parkingProperty?.guest_parking_tax_rate) ?? "\u2014",
        },
        {
          label: "SmartRent Application Fee %",
          value:
            formatPercent(parkingProperty?.guest_parking_application_fee) ??
            "\u2014",
        },
      ]),
    [parkingProperty]
  );

  const handleManage = useCallback(
    () => drawer.push("editPaymentConfiguration", { propertyId }),
    []
  );

  return (
    <Panel
      title="Payments"
      style={[styles.root, style]}
      action={
        permissions.manage_parking_payments ? (
          <Button onPress={handleManage}>Manage</Button>
        ) : null
      }
    >
      <PanelKeyValueList loading={isLoading} items={items} />
    </Panel>
  );
};

const styles = StyleSheet.create({
  root: {
    minWidth: 300,
  },
});
