import { useEffect } from "react";

import { Button, Panel, Result } from "@smartrent/ui";
import { AddPerson } from "@smartrent/icons";

import Helpers from "@/lib/helpers";
import PublicPageLayout from "@/layout/PublicPageLayout";
import helpers from "@/lib/helpers";

export default function CompleteOnboarding(props) {
  useEffect(() => {
    setTimeout(() => {
      Helpers.auth.logout();
    }, 5000);
  }, []);

  return (
    <PublicPageLayout>
      <Panel style={{ padding: 16 }}>
        <Result
          title="Welcome!"
          description={`You don't appear to have a role assigned in Okta. Please contact your administrator to get this resolved. You will be logged out in 5 seconds.`}
          Icon={AddPerson}
        />
        <Button onPress={() => helpers.auth.logout()}>Logout</Button>
      </Panel>
    </PublicPageLayout>
  );
}
