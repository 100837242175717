import { startCase } from "lodash-es";
import { useMemo } from "react";
import * as React from "react";
import { ListRenderItemInfo, StyleSheet, View } from "react-native";

import {
  LinearProgress,
  Link,
  ListItemContainer,
  Typography,
  VStack,
} from "@smartrent/ui";

import { HubRefurbishErrorNames, RunHubRefurbishBatchJobError } from "@/types";

import { useVirtualBoxHook } from "@/pages/hub-refurbish/hooks/useVirtualBoxHook";
import { HubRefurbishJobErrorLockMessage } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobErrorLockMessage";
import { HubRefurbishErrorMessage } from "@/pages/hub-refurbish/HubRefurbishErrorMessage";

export const HubRefurbishJobErrorsListItem: React.FC<
  ListRenderItemInfo<RunHubRefurbishBatchJobError>
> = ({ item: hubRefurbishJobError }) => {
  // we want the colors to match with the hub in the virtual grid
  const { progressColor } = useVirtualBoxHook({
    ...hubRefurbishJobError,
  });

  const isHubLocked = useMemo(
    () =>
      hubRefurbishJobError?.error?.context?.name ===
      HubRefurbishErrorNames.HubIsLocked,
    [hubRefurbishJobError?.error?.context?.name]
  );

  return (
    <ListItemContainer style={styles.root}>
      <View style={styles.leftDetail}>
        <Link href={`/hubs/${hubRefurbishJobError.serial}`} style={styles.link}>
          {hubRefurbishJobError.serial}
        </Link>
      </View>

      <View style={styles.detail}>
        {isHubLocked ? (
          <HubRefurbishJobErrorLockMessage
            {...hubRefurbishJobError.error.context}
          />
        ) : (
          <HubRefurbishErrorMessage {...hubRefurbishJobError?.error?.context} />
        )}
      </View>

      <VStack spacing={4} align="end" style={styles.rightDetail}>
        <LinearProgress
          width={30}
          progress={hubRefurbishJobError.progress}
          color={progressColor}
        />

        <Typography type="bodySmall">
          {`\tStep ( ${hubRefurbishJobError.step + 1} of ${
            hubRefurbishJobError.totalSteps
          } ) Failed`}
        </Typography>

        <Typography type="bodySmall" style={styles.errorDetailsText}>
          {startCase(hubRefurbishJobError.stepName)}
        </Typography>
      </VStack>
    </ListItemContainer>
  );
};

const styles = StyleSheet.create({
  root: {
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: 0,
    flex: 1,
    flexGrow: 1,
    alignItems: "flex-start",
    justifyContent: "space-between",
    maxWidth: 1200,
    paddingVertical: 16,
  },

  link: { maxWidth: 225 },
  errorDetailsText: {
    maxWidth: 150,
    textAlign: "right",
  },

  leftDetail: { flex: 2, flexWrap: "wrap", width: 225 },
  detail: { flex: 3 },
  rightDetail: { flex: 3 },
});
