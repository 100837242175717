import { useState } from "react";
import * as React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";
import {
  CloudSync,
  Pairing,
  Hub,
  Person,
  IconProps,
  Messages,
} from "@smartrent/icons";

import { HubProps, PermissionProps } from "@/types";

import BoxGet from "@/common/zipato/BoxGet";
import HubLogs from "@/common/zipato/HubLogs";
import HubRedisStats from "@/common/zipato/HubRedisStats";
import ZipatoAccount from "@/common/zipato/ZipatoAccount";
import { HubMessages } from "@/common/zipato/HubMessages";

const logStates = {
  NONE: "none",
  LOGS: "logs",
  BOX: "box",
  BOX_SESSION: "box_session",
  ZIPATO_ACCOUNT: "zipato_account",
  HUB_MESSAGES: "hub_messages",
};

interface HubTroubleshootingProps {
  hub?: HubProps | { serial: string; unit_id?: number; type?: string };
  permissions: PermissionProps;
  logsCallback?: (filters: Record<string, any>) => void;
  redisStatsCallback?: (filters: Record<string, any>) => void;
  leftAligned?: boolean;
}

export const HubTroubleshooting: React.FC<HubTroubleshootingProps> = ({
  hub,
  permissions,
  logsCallback,
  redisStatsCallback,
  leftAligned,
}) => {
  const [currentLog, setCurrentLog] = useState(logStates.NONE);
  const { colors } = useTheme();

  if (!(hub?.serial && permissions.troubleshoot)) {
    return null;
  }

  const genTab = (
    logState: string,
    Icon: React.ComponentType<IconProps>,
    text: string
  ) => {
    const isActive = currentLog === logState;

    return (
      <TouchableOpacity
        onPress={() => {
          setCurrentLog(logState);
        }}
        style={[
          styles.tab,
          { borderColor: colors.border },
          {
            backgroundColor: isActive ? colors.animatedPlaceholder : undefined,
          },
          leftAligned ? styles.tabLeft : null,
        ]}
      >
        <Icon />
        <Typography type="caption" style={styles.text}>
          {text}
        </Typography>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <View
        style={[
          styles.tabContainer,
          leftAligned ? styles.tabContainerLeft : null,
        ]}
      >
        {hub?.type === "zipato" || hub?.type === "smartrent"
          ? genTab(logStates.LOGS, CloudSync, "Logs")
          : null}
        {hub && hub?.unit_id && hub?.type === "zipato"
          ? genTab(logStates.BOX, Pairing, "Box Get")
          : null}
        {hub?.type === "zipato"
          ? genTab(logStates.BOX_SESSION, Hub, "Box / Session")
          : null}
        {hub?.type === "zipato"
          ? genTab(logStates.ZIPATO_ACCOUNT, Person, "Zipato Account")
          : null}
        {genTab(logStates.HUB_MESSAGES, Messages, "Hub Messages")}
      </View>

      {currentLog === logStates.LOGS ? (
        <HubLogs
          filters={{ type: hub.type, serial: hub.serial }}
          onFetch={logsCallback}
        />
      ) : null}
      {currentLog === logStates.BOX ? <BoxGet unit_id={hub?.unit_id} /> : null}
      {currentLog === logStates.BOX_SESSION ? (
        <HubRedisStats serial={hub.serial} onFetch={redisStatsCallback} />
      ) : null}
      {currentLog === logStates.ZIPATO_ACCOUNT ? (
        <ZipatoAccount serial={hub.serial} />
      ) : null}
      {currentLog === logStates.HUB_MESSAGES ? (
        <HubMessages serial={hub.serial} />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  tabContainerLeft: {
    justifyContent: "flex-start",
  },
  tab: {
    width: 100,
    alignItems: "center",
    justifyContent: "space-around",
    marginRight: 8,
    marginBottom: 8,
    paddingVertical: 8,
    borderRadius: 6,
    borderWidth: 1,
  },
  tabLeft: {
    marginRight: 8,
  },
  text: {
    marginTop: 8,
  },
});
