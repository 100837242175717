import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

import { EM_DASH } from "@/utils/chars";

export interface ListValueProps {
  label?: string;
  value: string | number | null;
  padBottom?: boolean;
  compact?: boolean;
}

export const ListValue: React.FC<ListValueProps> = ({
  label,
  value,
  padBottom = true,
}) => {
  return (
    <View style={padBottom ? styles.bottomPadding : null}>
      {label ? <Typography color="textSecondary">{label}</Typography> : null}
      <Typography style={styles.value}>{value ? value : EM_DASH}</Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  bottomPadding: { marginBottom: 16 },
  value: { fontWeight: "bold" },
});
