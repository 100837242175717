import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { Formik, FormikHelpers } from "formik";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  VStack,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import {
  GroupForm,
  GroupFormValues,
  validationSchema,
} from "@/common/groups/GroupForm";

import { useUpdateGroupMutation } from "@/api";

import { GroupProps } from "@/types";
import { useGroup } from "@/pages/group/GroupContext";
import { useGlobalContext } from "@/layout/Context";

interface UpdateGroupProps extends Partial<GroupProps> {
  id: number;
}

export const UpdateGroup: React.FC<UpdateGroupProps> = (group) => {
  const { refetchGroup } = useGroup();
  const { setToast } = useGlobalContext();

  const { pop } = useDrawerNav();

  const [updateGroup] = useUpdateGroupMutation();

  const handleCancel = useCallback(() => pop(), [pop]);

  const handleSubmit = useCallback(
    async (
      values: GroupFormValues,
      actions: FormikHelpers<GroupFormValues>
    ) => {
      actions.setSubmitting(true);
      try {
        const body = validationSchema.cast(values); // transform using yup validation rules
        const { id } = group;
        await updateGroup({ id, ...body });
        refetchGroup();
        pop();
        setToast({
          type: "success",
          title: "Success",
          message: "Successfully updated group.",
        });
      } catch (error) {
        const solicitErrors = (error as any)?.response?.data?.errors;
        if (solicitErrors && solicitErrors.length) {
          solicitErrors.forEach(({ field, description }) =>
            actions.setFieldError(field, description)
          );
        } else {
          pop();
          console.error(error);
          setToast({
            type: "error",
            title: "Error",
            message: "Something went wrong. Please contact engineering.",
          });
        }
      }
      actions.setSubmitting(false);
    },
    [updateGroup, group, refetchGroup, setToast, pop]
  );

  const initialValues = useMemo<GroupFormValues>(
    () => ({
      marketing_name: group?.marketing_name ?? "",
      property_code: group?.property_code ?? "",
      temperature_scale: group?.temperature_scale ?? "",
      street_address_1: group?.street_address_1 ?? "",
      street_address_2: group?.street_address_2 ?? "",
      group_image: group?.group_image ?? "",
      timezone: group?.timezone ?? "",
      city: group?.city ?? "",
      state: group?.state ?? "",
      zip: group?.zip ?? "",
      country: group?.country ?? "",
      lat: group?.lat ? parseFloat(group?.lat as string) : null,
      lng: group?.lng ? parseFloat(group?.lng as string) : null,
    }),

    [group]
  );

  return (
    <>
      <DrawerHeader title="Group Settings" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <>
          <DrawerContent contentContainerStyle={styles.drawerContent}>
            <VStack spacing={8}>
              <GroupForm {...initialValues} />
            </VStack>
          </DrawerContent>
          <DrawerActions>
            <Button onPress={handleCancel} variation="plain">
              Cancel
            </Button>
            <FormikSubmit label="Save" submittingLabel="Saving..." />
          </DrawerActions>
        </>
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  drawerContent: { paddingTop: 16 },
});
