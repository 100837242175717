import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { useField } from "formik";

import {
  SelectField,
  SelectOption,
  TextInputFieldHelperText,
  useTheme,
} from "@smartrent/ui";

import { GroupListFilters, useGroupsInfiniteQuery } from "@/api";
import { useDebounce } from "@/lib/hooks";
import { LoadingDots } from "@/common/LoadingDots";

export interface FormikAsyncParkingGroupFieldProps {
  name: string;
  label: string;
  required?: boolean;
  removePropertyId?: string;
  filterParams?: GroupListFilters;
}

const NO_GROUPS_MESSAGE = "No groups found with parking enabled";

const FormikAsyncParkingGroupField: React.FC<
  FormikAsyncParkingGroupFieldProps
> = ({ name, label, required, filterParams, removePropertyId = "" }) => {
  const [textInput, setTextInput] = useState("");
  const debouncedTextInput = useDebounce(textInput, 500);
  const [value, setValue] = useState<SelectOption | undefined>(undefined);
  const [, meta, helpers] = useField(name);
  const { colors } = useTheme();

  const { reducedData, isFetching, hasNextPage, fetchNextPage } =
    useGroupsInfiniteQuery({
      ...filterParams,
      parking_enabled: true,
      marketing_name: debouncedTextInput ?? undefined,
      active: true,
      limit: 50,
    });

  const options = useMemo(
    () =>
      reducedData
        .filter((group) => group.uuid !== removePropertyId)
        .map((group) => ({
          value: group.uuid,
          label: group.marketing_name,
        })),
    [reducedData, removePropertyId]
  );

  const handleEndReached = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const handleChange = useCallback(
    (option: SelectOption) => {
      setValue(option);
      helpers.setValue(option?.value ?? "");
    },
    [helpers]
  );

  useEffect(() => {
    if (options) {
      if (options.length === 0 && !meta.error && !value && !isFetching) {
        helpers.setError(NO_GROUPS_MESSAGE);
      }

      if (options.length && meta.error === NO_GROUPS_MESSAGE) {
        helpers.setError(undefined);
      }
    }
  }, [options, helpers, meta, value, isFetching]);

  return (
    <>
      <SelectField
        name={name}
        label={label}
        onChange={handleChange}
        options={options}
        value={value}
        required={required}
        StartAdornment={isFetching ? () => <LoadingDots color="gray" /> : null}
        onTextInputChange={setTextInput}
        flatListProps={{ onEndReached: handleEndReached }}
      />
      <TextInputFieldHelperText color={colors.error}>
        {meta.error}
      </TextInputFieldHelperText>
    </>
  );
};

export default FormikAsyncParkingGroupField;
