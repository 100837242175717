import * as React from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";

import {
  LegacyListItem,
  useTheme,
  Typography,
  TypographyProps,
} from "@smartrent/ui";
import { ChevronRight } from "@smartrent/icons";

interface ActionItemProps {
  onPress: () => void;
  title: string;
  color?: TypographyProps["color"];
  disabled?: boolean;
}

export const ActionItem: React.FC<ActionItemProps> = ({
  onPress,
  title,
  color = "primary",
  disabled = false,
}) => {
  const { colors } = useTheme();
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <LegacyListItem
        item={{
          value: (
            <Typography type="title4" color={color}>
              {title}
            </Typography>
          ),
        }}
        size="normal"
        style={[styles.itemContainer, { borderColor: colors.gray200 }]}
      >
        <View>
          <ChevronRight color={colors.primary} />
        </View>
      </LegacyListItem>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    borderBottomWidth: 1,
  },
});
