import * as Sentry from "@sentry/react";

import { isAxiosError } from "./axios-helpers";

const IGNORED_ERROR_CODES = [422, 401, 404];

if (process.env.REACT_APP_SENTRY_DSN) {
  // release is set on the window & comes from sentry webpack plugin
  Sentry.init({
    initialScope: {
      tags: { jsPackage: "support-react" },
    },
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "TypeError: a[b].target.className.indexOf is not a function",
      "ReferenceError: 'Promise' is undefined",
      "SecurityError: Blocked a frame with origin",
      "Loading chunk",
    ],
    beforeSend: (event, hint) => {
      // filter out axios errors that have a response status code
      if (
        hint?.originalException &&
        isAxiosError(hint.originalException) &&
        !!hint.originalException.response?.status &&
        IGNORED_ERROR_CODES.includes(hint.originalException.response?.status)
      ) {
        return null;
      }
      return event;
    },
  });
}
