import { useState, useCallback } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Button, Typography } from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import {
  useWhiteLabelConfigQuery,
  useUpdateWhiteLabelConfigMutation,
} from "@/api";

import { LoadingDots } from "@/common/LoadingDots";
import Card from "@/common/Card";
import FormikModal from "@/common/formik/Modal";

import { useGroup } from "../GroupContext";

import { WhiteLabelConfigHeader } from "./whiteLabelConfig/WhiteLabelConfigHeader";

export const GroupWhiteLabelConfigCard: React.FC = () => {
  const { group } = useGroup();
  const { permissions } = useUser();
  const [showEnableModal, setShowEnableModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const { data: whiteLabelConfigData, isFetching } = useWhiteLabelConfigQuery({
    groupId: group.id,
  });
  const [updateWhiteLabelConfigMutation] = useUpdateWhiteLabelConfigMutation();

  const whiteLabelConfigEnabled = whiteLabelConfigData?.enabled ?? false;

  const enableWhiteLabelConfig = useCallback(async () => {
    await updateWhiteLabelConfigMutation({
      groupId: group.id,
      payload: { enabled: true },
    });
    setShowEnableModal(false);
  }, [updateWhiteLabelConfigMutation, group]);

  const disableWhiteLabelConfig = useCallback(async () => {
    await updateWhiteLabelConfigMutation({
      groupId: group.id,
      payload: { enabled: false },
    });
    setShowDisableModal(false);
  }, [updateWhiteLabelConfigMutation, group]);

  return (
    <Card heading={<WhiteLabelConfigHeader />}>
      <View style={styles.loadingWrapper}>
        {isFetching && (
          <View style={styles.loading}>
            <LoadingDots />
          </View>
        )}
        {whiteLabelConfigEnabled && !isFetching && (
          <View>
            <Typography type="caption">
              ResApp Rebranding is currently enabled
            </Typography>
            {permissions.white_label_config && (
              <Button
                onPress={() => setShowDisableModal(true)}
                disabled={!group}
                style={styles.mt16}
              >
                Disable ResApp Rebranding
              </Button>
            )}
          </View>
        )}

        {!whiteLabelConfigEnabled && !isFetching && (
          <View>
            <Typography type="caption">
              ResApp Rebranding is currently disabled
            </Typography>
            {permissions.white_label_config && (
              <View>
                <Button
                  onPress={() => setShowEnableModal(true)}
                  disabled={!group}
                  style={styles.mt16}
                >
                  Enable ResApp Rebranding
                </Button>
              </View>
            )}
          </View>
        )}
      </View>

      {showEnableModal && (
        <FormikModal
          title="Enable ResApp Rebranding"
          toggleModal={() => setShowEnableModal(false)}
          onSubmit={() => enableWhiteLabelConfig()}
          confirmText="Enable"
        >
          Are you sure you'd like to enable ResApp Rebranding?
        </FormikModal>
      )}

      {showDisableModal && (
        <FormikModal
          title="Disable ResApp Rebranding"
          toggleModal={() => setShowDisableModal(false)}
          onSubmit={() => disableWhiteLabelConfig()}
          confirmText="Disable"
        >
          Are you sure you'd like to disable ResApp Rebranding?
        </FormikModal>
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  loadingWrapper: { width: "100%" },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mt16: { marginTop: 16 },
});
