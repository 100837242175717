import * as React from "react";
import { StyleSheet } from "react-native";

import { StatusBadge, StatusBadgeProps } from "@smartrent/ui";

interface StatusBadgeDetailViewProps extends Partial<StatusBadgeProps> {
  condition: boolean;
}
export const StatusBadgeDetailView: React.FC<StatusBadgeDetailViewProps> = ({
  condition,
  children,
  style,
}) => {
  return (
    <StatusBadge
      status={condition ? "success" : "default"}
      variation="outlined"
      style={style ? [styles.statusBadge, style] : styles.statusBadge}
      size="semibold.title.five"
    >
      {children ? children : condition ? "Enabled" : "Disabled"}
    </StatusBadge>
  );
};

const styles = StyleSheet.create({
  statusBadge: { maxWidth: 80, marginLeft: 8 },
});
