import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { first } from "lodash-es";

import { Button, Link } from "@smartrent/ui";

import { DecalListItem, useDecalsPaginatedQuery } from "@/api";
import { useUser } from "@/layout/Context";
import { ColumnOptions, Table } from "@/common/sr-table";
import { useDrawerNav } from "@/common/AppDrawer";

import DecalStatusPill from "./DecalStatusPill";

interface Props {
  groupId: number;
  propertyId: string;
  style?: StyleProp<ViewStyle>;
}

const PAGE_SIZE = 5;

const formatAssignments = (row: DecalListItem) => {
  if (row.active) {
    const spaces = row.spaces ?? [];
    const sections = row.sections ?? [];
    const totalAssignments = spaces.length + sections.length;

    if (totalAssignments > 1) {
      return `${totalAssignments} Assignments`;
    }

    if (spaces.length === 1) {
      return first(spaces).space_number;
    }

    if (sections.length === 1) {
      return first(sections).name;
    }
  }

  return "\u2014";
};

const formatAssignees = (decal: DecalListItem) => {
  const residents = decal.residents;
  if (decal.active && residents && residents.length > 0) {
    const resident = first(residents);

    return residents.length === 1 ? (
      <Link
        href={`${decal.baseURL}/manager/units/${resident.unit_id}/overview`}
        target="_blank"
        type="title5"
      >
        {[resident.first_name, resident.last_name].join(" ")}
      </Link>
    ) : (
      `${residents.length} Residents`
    );
  }

  return "\u2014";
};

export const DecalTable: React.FC<Props> = ({ groupId, propertyId, style }) => {
  const [page, setPage] = useState(1);
  const { permissions } = useUser();
  const drawer = useDrawerNav();
  const { data, isLoading } = useDecalsPaginatedQuery(propertyId, page, {
    limit: PAGE_SIZE,
  });

  const decals = data?.records ?? [];
  const totalRecords = data?.total_records ?? 0;
  const currentPage = data?.current_page ?? 1;
  const totalPages = data?.total_pages ?? 1;

  const columns = useMemo<ColumnOptions<DecalListItem>[]>(
    () => [
      {
        name: "status",
        header: "Status",
        maxWidth: 128,
        render: ({ row }) => <DecalStatusPill decal={row} />,
      },
      {
        name: "code",
        header: "Number",
        maxWidth: 80,
        render: ({ row }) => row.code,
      },
      {
        name: "assignee",
        header: "Assignee(s)",
        maxWidth: 160,
        render: ({ row }) => formatAssignees(row),
      },
      {
        name: "license_plate",
        header: "License Plate",
        maxWidth: 144,
        render: ({ row }) =>
          row.vehicle && row.active ? row.vehicle.license_plate : "\u2014",
      },
      {
        name: "assignments",
        header: "Assigned Spaces/Sections",
        maxWidth: 160,
        render: ({ row }) => formatAssignments(row),
      },
      {
        name: "inserted_at",
        header: "Created At",

        render: ({ row }) =>
          row.inserted_at
            ? new Date(row.inserted_at).toLocaleString()
            : "\u2014",
      },
      {
        name: "assigned_at",
        header: "Assigned At",

        render: ({ row }) =>
          row.assigned_at
            ? new Date(row.assigned_at).toLocaleString()
            : "\u2014",
      },
      {
        name: "archived_at",
        header: "Archived At",

        render: ({ row }) =>
          row.deleted_at ? new Date(row.deleted_at).toLocaleString() : "\u2014",
      },
    ],
    []
  );

  const keyExtractor = useCallback((row) => row.id, []);

  const handlePageChange = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleCreatePress = useCallback(
    () => drawer.push("orderDecals", { groupId, propertyId }),
    [drawer, groupId, propertyId]
  );

  return (
    <Table<DecalListItem>
      title="Decals"
      keyExtractor={keyExtractor}
      data={decals}
      columns={columns}
      currentPage={currentPage}
      totalPages={totalPages}
      totalRecords={totalRecords}
      onPageChange={handlePageChange}
      loading={isLoading}
      pageSize={PAGE_SIZE}
      action={
        permissions.manage_parking ? (
          <Button onPress={handleCreatePress}>Create</Button>
        ) : null
      }
      noRecordsText="No Decals Found"
      style={style}
    />
  );
};
