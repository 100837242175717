import * as React from "react";
import { View, StyleSheet } from "react-native";

import Skeleton from "react-loading-skeleton";

import { Text, useTheme } from "@smartrent/ui";

import { useIsDesktop } from "@/lib/hooks";

import { PaginatedTableColumn } from "./PaginatedTableColumnHeader";

interface PaginatedTableRowSkeletonProps {
  columns: PaginatedTableColumn[];
  row: number;
}

const PaginatedTableRowSkeleton: React.FC<PaginatedTableRowSkeletonProps> = ({
  columns,
  row,
}) => {
  const isDesktop = useIsDesktop();
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.containerStyles,
        { borderBottomColor: colors.gray200 },
        !isDesktop && styles.mobileContainerStyles,
      ]}
    >
      {columns.map((column) => {
        return (
          <View key={`row${row}_${column.field}`} style={styles.columnStyles}>
            <Text style={styles.textStyles}>
              <Skeleton width={100} />
            </Text>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainerStyles: {
    flexDirection: "column",
  },
  containerStyles: {
    alignItems: "center",
    cursor: "pointer",
    flexDirection: "row",
    borderBottomWidth: 1,
    paddingVertical: 22,
  },
  columnStyles: {
    flex: 1,
    paddingHorizontal: 12,
    width: "100%",
  },
  textStyles: {
    flex: 1,
    fontSize: 14,
    fontWeight: "500",
  },
});

export default PaginatedTableRowSkeleton;
