import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { Button, Text } from "@smartrent/ui";

const Ellipsis: React.FC = React.memo(() => <Text type="caption">...</Text>);

interface PageButtonProps {
  page: number;
  disabled?: boolean;
  label?: string;
  onPress?: (page: number) => void;
}

const PageButton: React.FC<PageButtonProps> = React.memo(
  ({ page, disabled, label, onPress }) => {
    const handlePress = useCallback(() => {
      onPress && onPress(page);
    }, [onPress, page]);

    return (
      <Button
        variation="outlined"
        onPress={handlePress}
        disabled={disabled}
        style={{ shadowOpacity: 0 }}
      >
        {label ? label : page.toLocaleString()}
      </Button>
    );
  }
);

interface PaginatorProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  style?: StyleProp<ViewStyle>;
}

/**
 * Paginator is a display-only pagination component. For a fully-baked API that
 * uses query string pagination, use UrlPaginator. If you don't want to use
 * query string pagination, you can do something like this:
 *
 *    const [page, setPage] = useState(1);
 *    <Paginator page={page} onPageChange={setPage} totalPages={totalPages} />
 */
export const Paginator: React.FC<PaginatorProps> = React.memo(
  ({ onPageChange, page, totalPages, style }) => {
    // if there are 0 or 1 pages, this component can't render anything sensible
    if (!totalPages || totalPages === 1) {
      return null;
    }

    if (!page || page < 1) {
      page = 1;
    }

    return (
      <View style={[styles.root, style]}>
        <PageButton
          page={page - 1}
          disabled={page <= 1}
          label="&laquo; Prev"
          onPress={onPageChange}
        />

        {page - 2 >= 1 ? <Ellipsis /> : null}

        {page === totalPages && page - 2 >= 1 ? (
          <PageButton page={page - 2} onPress={onPageChange} />
        ) : null}

        {page > 1 ? (
          <PageButton page={page - 1} onPress={onPageChange} />
        ) : null}

        <PageButton disabled page={page} />

        {page < totalPages ? (
          <PageButton page={page + 1} onPress={onPageChange} />
        ) : null}

        {page === 1 && page + 2 <= totalPages ? (
          <PageButton page={page + 2} onPress={onPageChange} />
        ) : null}

        {page + 2 <= totalPages ? <Ellipsis /> : null}

        <PageButton
          page={page + 1}
          disabled={page >= totalPages}
          label="Next &raquo;"
          onPress={onPageChange}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  root: {
    flex: 1,

    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",

    maxWidth: 400,
  },
});
