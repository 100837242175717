import { useMemo, useState } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Link, Typography } from "@smartrent/ui";

import { isEmpty } from "lodash-es";

import { StatusBadgeDetailView } from "@/common/StatusBadgeDetailView";

import { groupsTourUrl } from "@/utils/links";

import { useIsMobileCard } from "@/hooks/breakpoints";

import { useGroup } from "../../GroupContext";

export interface GroupToursModuleProps {
  containerStyle: any;
}

export const GroupToursModule: React.FC<GroupToursModuleProps> = ({
  containerStyle,
}) => {
  const { groupTourConfig } = useGroup();
  const isMobileCard = useIsMobileCard();

  const [hasGroupTourConfig] = useState(!isEmpty(groupTourConfig));
  const [hasGroupTourConfigEnabled] = useState(
    hasGroupTourConfig && groupTourConfig.enabled
  );
  const [showGroupTourLink] = useState(
    hasGroupTourConfig && hasGroupTourConfigEnabled
  );

  const groupsTourURL = useMemo(
    () => groupsTourUrl({ uuid: groupTourConfig?.uuid }),
    [groupTourConfig?.uuid]
  );

  if (!hasGroupTourConfig) {
    return null;
  }

  return (
    <>
      <View style={containerStyle}>
        <Typography type="title5">Self Guided Tours</Typography>
        <StatusBadgeDetailView condition={hasGroupTourConfigEnabled} />
      </View>
      {showGroupTourLink ? (
        <View style={styles.toursLinkContainer}>
          <Link target="_blank" href={groupsTourURL} selectable={true}>
            <TouchableOpacity style={styles.flexRowCenter}>
              <Typography
                color="link"
                style={isMobileCard ? mobileStyles.toursLink : styles.toursLink}
              >
                {groupsTourURL}
              </Typography>
            </TouchableOpacity>
          </Link>
        </View>
      ) : null}{" "}
    </>
  );
};

const mobileStyles = StyleSheet.create({
  toursLink: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 240,
    fontSize: 16,
  },
});

const styles = StyleSheet.create({
  toursLinkContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 12,
    paddingLeft: 8,
  },
  flexRowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  toursLink: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 380,
    fontSize: 16,
  },
});
