import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";
// import {
//   FloppyDisk,
//   Online,
//   Pairing,
//   QuestionCircleBorder,
//   Search,
//   Signal,
// } from "@smartrent/icons";

import { HubAccount, HubRequest, UnitModel } from "@/types";

// import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
// import { CircleIconButton } from "@/common/CircleIconButton";

import {
  BoxSync,
  DetachHub,
  RebootHub,
  RegisterHub,
  SyncCellStats,
  ResetZWave,
  HubOffline,
  // UpgradeFirmware,
} from "@/pages/hub-page/quick-controls/";

import { useUser } from "@/layout/Context";
import { useAccountQuery } from "@/api/zipato";

// const actions = [
//   { title: "Save All", icon: FloppyDisk },
//   { title: "Check Status", icon: Online },
//   { title: "Reset Z-Wave", icon: Pairing },
//   { title: "Discover Devices", icon: Search },
//   { title: "Sync Cell Status", icon: Signal },
// ];

export interface QuickControlsProps {
  unit: UnitModel;
  hub: HubRequest;
  hubAccount: HubAccount;
}

export const QuickControls: React.FC<QuickControlsProps> = ({
  unit,
  hub,
  hubAccount,
}) => {
  const { permissions } = useUser();
  const hubAccountAttached = useMemo(
    () => hubAccount && hubAccount.attached,
    [hubAccount]
  );
  const { data: zipato } = useAccountQuery(
    {
      serial: hub?.serial,
    },
    { enabled: !!hub?.serial && hub?.type === "zipato" }
  );

  return (
    <>
      <Typography type="title">Quick Controls</Typography>

      <View style={styles.actions}>
        <HubOffline hub={hub} />
        <RebootHub hub={hub} unitId={unit?.unit?.id} />
        {/* <UpgradeFirmware hub={hub} /> */}
        <BoxSync hub={hub} unitId={unit?.unit?.id} />
        {permissions.advanced_hub_troubleshooting ? (
          <ResetZWave hub={hub} unitId={unit?.unit?.id} />
        ) : null}
        <SyncCellStats hub={hub} serial={hub?.serial} />
        {permissions.advanced_hub_troubleshooting &&
        hubAccountAttached &&
        zipato?.zipato_user_id !== undefined &&
        !zipato?.zipato_user_id ? (
          <DetachHub serial={hub?.serial} />
        ) : null}
        {permissions.advanced_hub_troubleshooting &&
        !hubAccountAttached &&
        zipato?.zipato_user_id !== undefined &&
        zipato?.zipato_user_id ? (
          <RegisterHub serial={hub?.serial} />
        ) : null}

        {/* {actions.map(({ title, icon }, index) => (
          <Card key={index} style={styles.card}>
            <CardHeader>
              <Typography>{title}</Typography>
              <QuestionCircleBorder size={22} />
            </CardHeader>
            <CardBody style={styles.cardBody}>
              <CircleIconButton icon={icon} onClick={() => {}} />
            </CardBody>
            <CardFooter>
              <Button>Run Action</Button>
            </CardFooter>
          </Card>
        ))} */}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  actions: {
    marginTop: 16,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});
