import { useEffect, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";

import { Typography } from "@smartrent/ui";

import { BULLET } from "@/utils/chars";

import { useHubLogsQuery } from "@/api/zipato";
import { valueToHumanReadableString } from "@/utils/human-readable-values";
import { formatDate, formatDateToBeHumanReadable } from "@/utils";
import { transformMessage } from "@/utils/hubLogs";

const HubLog = ({ message, timestamp }) => {
  const { label, type, tip } = useMemo(
    () => transformMessage(message),
    [message]
  );

  const messageIsData = useMemo(() => /^\{/.test(label), [label]);

  return (
    <View style={styles.log}>
      {/* flex direction is column*/}
      <View style={styles.logMessage}>
        {/* flex direction is row*/}
        <Typography style={styles.circleMargin} type="title" color={type}>
          {BULLET}
        </Typography>
        {messageIsData ? (
          <View>
            {Object.entries(JSON.parse(label)).map((entry, index) => (
              <Typography key={index} type="title6">
                {valueToHumanReadableString(entry[0])}:{" "}
                {valueToHumanReadableString(entry[1])}
              </Typography>
            ))}
          </View>
        ) : (
          <Typography type="title5">{label}</Typography>
        )}
      </View>
      <View>
        <Typography
          style={styles.logBody}
          type="title6"
          color="textSecondary"
          numberOfLines={1}
          lineBreakMode="head"
          ellipsizeMode="head"
        >
          {tip}
        </Typography>
      </View>
      <View>
        <Typography
          style={styles.logTimestamp}
          type="body"
          color="textSecondary"
        >
          {formatDateToBeHumanReadable({ date: timestamp })} (
          {formatDate({ date: timestamp, pattern: "P p z" })})
        </Typography>
      </View>
    </View>
  );
};

interface HubLogs {
  serial: string;
  fetchData: string;
}
export const HubLogs: React.FC<HubLogs> = ({ serial, fetchData }) => {
  const { data, isError, refetch } = useHubLogsQuery({ serial: serial });

  useEffect(() => {
    fetchData === "hubLog" ? refetch() : null;
  }, [fetchData, refetch]);

  {
    isError ? (
      <View style={styles.error}>
        <Typography color="error">No logs found</Typography>
      </View>
    ) : null;
  }

  return (
    <View style={styles.logsContainer}>
      {(data?.length ? data : []).map(({ message, timestamp }, idx) => (
        <HubLog message={message} timestamp={timestamp} key={idx} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  logsContainer: {
    maxHeight: 500,
    overflow: "auto",
    padding: 16,
  } as Omit<ViewStyle, "overflow">,
  log: {
    alignItems: "flex-start",
  },
  logMessage: {
    paddingTop: 4,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  circleMargin: {
    justifyContent: "flex-start",
    lineHeight: 20,
    paddingRight: 4,
  },
  logBody: {
    paddingLeft: 16,
  },
  logTimestamp: {
    paddingLeft: 16,
    fontSize: 12,
  },
  error: {
    padding: 8,
    backgroundColor: "#ff0000",
  },
});
