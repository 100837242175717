import { useCallback } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import {
  ActivityIndicator,
  Typography,
  StatusBadge,
  useTheme,
} from "@smartrent/ui";

import { useWebhooksQuery } from "@/api";

import Card from "@/common/Card";

import Helpers from "@/lib/helpers";
import { useUser } from "@/layout/Context";
import { SmsWebhookProps } from "@/types";
import { redactLinks } from "@/utils";

const smsListOptions = (logs: SmsWebhookProps[]) => {
  return logs?.map((log, index) => ({
    label: Helpers.formatDate(log?.inserted_at),
    value: redactLinks(log?.body ?? ""),
    key: index.toString(),
    status: log?.status,
  }));
};

interface SMSWebhookCardProps {
  phone: string;
}
export const SMSWebhookCard = ({ phone }: SMSWebhookCardProps) => {
  const { permissions } = useUser();

  const { colors } = useTheme();

  const convertStatus = useCallback((status) => {
    switch (status) {
      case "delivered":
        return "success";
      case "undelivered":
        return "error";
      case "sent":
        return "knowledge";
      default:
        return "default";
    }
  }, []);

  const smsWebhooksQuery = useWebhooksQuery(phone, {
    enabled: !!(phone && permissions?.view_masked_user_detail),
  });

  return (
    <Card heading={<Typography type="title4">SMS Webhooks</Typography>}>
      <View style={styles.listContainer}>
        {smsWebhooksQuery.isLoading ? (
          <View style={styles.loadingWrapper}>
            <ActivityIndicator />
          </View>
        ) : (
          <FlatList
            data={smsListOptions(smsWebhooksQuery.reducedData)}
            keyExtractor={(item: any) => item.key}
            ItemSeparatorComponent={() => {
              return (
                <View
                  style={[
                    {
                      backgroundColor: colors.border,
                    },
                    styles.separator,
                  ]}
                />
              );
            }}
            renderItem={(itemProps: any) => {
              return (
                <View style={styles.padding}>
                  <View style={styles.label}>
                    <Typography type="title4" color="primary">
                      {itemProps.item.label}
                    </Typography>
                    <StatusBadge
                      status={convertStatus(itemProps.item.status)}
                      size="semibold.title.two"
                    >
                      {itemProps.item.status}
                    </StatusBadge>
                  </View>
                  {itemProps.item.value ? (
                    <Typography
                      type="caption"
                      style={[
                        {
                          borderLeftColor: colors.gray200,
                        },
                        styles.bodyAvailable,
                      ]}
                    >
                      {itemProps.item.value}
                    </Typography>
                  ) : (
                    <Typography
                      type="caption"
                      style={[
                        { borderLeftColor: colors.warning },
                        styles.bodyUnavailable,
                      ]}
                    >
                      Message body unavailable
                    </Typography>
                  )}
                </View>
              );
            }}
            onEndReachedThreshold={0.25}
            onEndReached={() => {
              if (smsWebhooksQuery.hasNextPage) {
                smsWebhooksQuery.fetchNextPage();
              }
            }}
            ListEmptyComponent={() =>
              smsWebhooksQuery.isLoading ? (
                <View style={styles.emptyMessages}>
                  <ActivityIndicator />
                </View>
              ) : (
                <View style={styles.emptyMessages}>
                  <Typography style={styles.smsWebhookText}>
                    No SMS messages found
                  </Typography>
                </View>
              )
            }
          />
        )}
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  padding: { padding: 8 },
  loadingWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  listContainer: {
    maxHeight: "600px",
  },
  smsWebhookText: {
    marginTop: 16,
    color: "#656B6F",
  },
  emptyMessages: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 16,
  },
  bodyUnavailable: {
    borderLeftWidth: 3,
    marginVertical: 6,
    paddingVertical: 12,
    paddingLeft: 12,
    fontStyle: "italic",
  },
  bodyAvailable: {
    borderLeftWidth: 3,
    marginVertical: 6,
    paddingVertical: 12,
    paddingLeft: 12,
  },
  label: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  separator: { height: 1, width: "95%", marginLeft: "2.5%" },
});
