import * as React from "react";
import { StyleSheet } from "react-native";

import { Grid, Statistic } from "@smartrent/ui";

import { Home } from "@smartrent/icons";

import { PropertyHealthReportStats } from "@/types";

interface PropertyHealthStatisticsProps {
  stats: PropertyHealthReportStats;
  isInstallInProgress: boolean;
}

export const PropertyHealthStatistics: React.FC<
  PropertyHealthStatisticsProps
> = ({ isInstallInProgress, stats }) => {
  if (isInstallInProgress) {
    return (
      <Grid numColumns={2} style={styles.root} gap={32}>
        <Statistic
          title="Total Units"
          value={stats.total_units}
          type="number"
          leftIcon={Home}
        />
        <Statistic
          title="Total Impacted Units"
          value={stats.total_unhealthy_units}
          type="number"
          leftIcon={Home}
          valueColor="mutedTextWarning"
        />
      </Grid>
    );
  }
  return (
    <Grid numColumns={3} style={styles.root} gap={32}>
      <Statistic
        title="Total Units"
        value={stats.total_units}
        type="number"
        leftIcon={Home}
      />

      <Statistic
        title="Healthy Units"
        value={stats.total_healthy_units}
        type="number"
        leftIcon={Home}
        valueColor="mutedTextSuccess"
      />
      <Statistic
        title="Unhealthy Units"
        value={stats.total_unhealthy_units}
        type="number"
        leftIcon={Home}
        valueColor="mutedTextWarning"
      />
    </Grid>
  );
};

const styles = StyleSheet.create({
  root: { flexGrow: 1 },
});
