import { useCallback, useState } from "react";
import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { SelectField } from "@smartrent/ui";

import { SalesForceProperty } from "@/types";
import { selectQueryPropsSalesforceProperties } from "@/api";

interface SalesforcePropertySelectProps {
  initialValue?: SalesForceProperty;
  value?: SalesForceProperty;
  setValue: (value: SalesForceProperty) => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const SalesforcePropertySelect: React.FC<
  SalesforcePropertySelectProps
> = ({
  initialValue,
  value: property,
  setValue: setProperty,
  disabled,
  style,
}) => {
  const [propertyFieldDirty, setPropertyFieldDirty] = useState(false);

  const onChangeProperty = useCallback(
    (value) => {
      setPropertyFieldDirty(true);
      setProperty(value || { Id: "", Name: "" });
    },
    [setProperty]
  );

  return (
    <SelectField<SalesForceProperty>
      label="Salesforce Property"
      name="salesforce_property_id"
      value={
        propertyFieldDirty ? property : (initialValue as SalesForceProperty)
      }
      onChange={onChangeProperty}
      getOptionValue={(opp) => (opp?.Id ? opp.Id : "")}
      getOptionLabel={(opp) => (opp?.Name ? opp.Name : "")}
      {...selectQueryPropsSalesforceProperties()}
      disabled={disabled}
      style={style}
    />
  );
};
