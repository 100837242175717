import { useCallback, useMemo } from "react";
import * as React from "react";

import { ActionItem } from "@/common/ActionItem";
import { useUpdatePropertyMutation } from "@/api";
import { ParkingProperty } from "@/types";
import { useDialog } from "@/lib/contexts/dialog";

interface ToggleDecalModuleActionProps {
  property: ParkingProperty;
  onSuccess: () => void;
}

export const ToggleDecalModuleAction: React.FC<
  ToggleDecalModuleActionProps
> = ({ property, onSuccess }) => {
  const { confirm } = useDialog();
  const [updateProperty] = useUpdatePropertyMutation();

  const enabled = useMemo(
    () => property?.decal_module_enabled ?? false,
    [property]
  );

  const title = useMemo(
    () => `${enabled ? "Disable" : "Enable"} Decal Module`,
    [enabled]
  );

  const color = useMemo(() => (enabled ? "error" : "primary"), [enabled]);

  const message = useMemo(
    () => `Successfully ${enabled ? "disabled" : "enabled"} decal module.`,
    [enabled]
  );

  const errorMessage = useMemo(
    () => `Error ${enabled ? "disabling" : "enabling"} decal module.`,
    [enabled]
  );

  const handlePress = useCallback(async () => {
    const confirmed = await confirm({
      title: `${title}?`,
      confirmText: `${enabled ? "Disable" : "Enable"}`,
      confirmType: enabled ? "destructive" : "primary",
      description: `Are you sure you want to ${
        enabled ? "disable" : "enable"
      } the decal module?`,
    });

    if (confirmed) {
      const updatedProperty: ParkingProperty = {
        ...property,
        decal_module_enabled: !enabled,
      };

      await updateProperty({
        property: updatedProperty,
        message,
        errorMessage,
      });
      onSuccess();
    }
  }, [
    confirm,
    title,
    enabled,
    property,
    updateProperty,
    message,
    errorMessage,
    onSuccess,
  ]);

  return <ActionItem onPress={handlePress} title={title} color={color} />;
};
