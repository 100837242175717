import * as React from "react";
import { QueryFunction, QueryKey } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import {
  ActivityIndicator,
  Button,
  Panel,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";
import { Flip } from "@smartrent/icons";

import { useDummyAnimationHook } from "@/hooks/dummy-animation";
import { EmployeeRole, UserProps } from "@/types";
import { instance } from "@/lib/hooks";
import { useUser } from "@/layout/Context";
import {
  UserGroupsTableApiResponse,
  UserGroupsTableProps,
} from "@/types/AssignedUserGroupsTableProps";
import { useRotateVacantAccessCodeMutation } from "@/api";

export interface AssignedUserGroupsTableProps {
  user: UserProps;
  role: EmployeeRole;
}

const DEFAULT_PAGE_SIZE = 25;

export const AssignedUserGroupsTable: React.FC<
  AssignedUserGroupsTableProps
> = ({ user, role }) => {
  const history = useHistory();
  const { permissions } = useUser();
  const canRotateVacantAccessCodes =
    permissions?.rotate_vacant_access_codes &&
    role?.access_code_permission_receive_vacant_code;

  const getQueryKey = ({
    filters,
    page,
    pageSize,
    sortColumn,
    sortDirection,
  }) => [
    "user-groups-table-query-key",
    {
      user: user.id,
    },
    {
      ...filters,
      page,
      limit: pageSize,
      sort: sortColumn,
      dir: sortDirection,
    },
  ];

  const fetchGroups: QueryFunction<UserGroupsTableApiResponse> = async ({
    queryKey,
  }: {
    queryKey: QueryKey;
  }) => {
    const [, , filters] = queryKey;
    const { data } = await instance.get<UserGroupsTableApiResponse>(
      `/users/${user.id}/assigned-groups`,
      {
        params: filters,
      }
    );
    return data;
  };

  const tableProps = useTableQuery<any, UserGroupsTableProps, any>({
    fetch: fetchGroups,
    getQueryKey: getQueryKey,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    columns: [
      {
        name: "id",
        header: "Id",
        render: ({ row: { id } }) => <Typography>{id}</Typography>,
        maxWidth: 100,
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "marketing_name",
        header: "Name",
        render: ({ row: { marketing_name } }) => (
          <Typography>{marketing_name}</Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "vacant_access_codes_enabled",
        header: "Vacant Access Codes",
        render: ({ row: { id, vacant_access_codes_enabled } }) =>
          vacant_access_codes_enabled ? (
            canRotateVacantAccessCodes ? (
              <RotateVacantAccessCodeButton groupId={id} userId={user.id} />
            ) : (
              <Typography>Enabled</Typography>
            )
          ) : (
            <Typography>Disabled</Typography>
          ),
      },
    ],
  });

  return (
    <Panel>
      <Table<UserGroupsTableProps>
        title="Assigned Groups"
        noRecordsText="No Assigned Groups"
        onRowPress={({ id }) => {
          history.push(`/groups/${id}`);
        }}
        showClearFiltersButton
        action={
          role?.setting_assign_all_properties ? (
            <Typography
              type="bodySemibold"
              color="textSecondary"
              style={{
                fontStyle: "italic",
              }}
            >
              Assigned to All Groups
            </Typography>
          ) : null
        }
        {...tableProps}
      />
    </Panel>
  );
};

const RotateVacantAccessCodeButton = ({ groupId, userId }) => {
  const [rotateVacantAccessCode, { isLoading, isError, isSuccess }] =
    useRotateVacantAccessCodeMutation();
  const { isAnimating } = useDummyAnimationHook({ isLoading });

  return (
    <Button
      disabled={isLoading || isError || isSuccess}
      onPress={() =>
        rotateVacantAccessCode({ group_id: groupId, user_id: userId })
      }
      size="small"
      iconLeft={isAnimating ? ActivityIndicator : Flip}
      variation="plain"
    >
      Rotate Vacant Access Codes
    </Button>
  );
};
