import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import {
  ActivityIndicator,
  Typography,
  Button,
  AlertDialog,
} from "@smartrent/ui";
import { QuestionCircleBorder, Pairing } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useResetZWaveMutation } from "@/api/hub";
import { HubRequest } from "@/types";
import { useDialog } from "@/lib/contexts/dialog";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";

export interface ResetZWaveProps {
  hub: HubRequest;
  unitId: number;
}
export const ResetZWave: React.FC<ResetZWaveProps> = ({ hub, unitId }) => {
  const { onOpen: openHelp, onClose, visible } = useModalState();
  const { confirm } = useDialog();
  const [sendResetZWave, { isLoading }] = useResetZWaveMutation();

  const onClick = useCallback(async () => {
    const confirmed = await confirm({
      title: "Reset Z-Wave?",
      confirmText: "Reset",
      confirmType: "destructive",
      description:
        "Resetting Z-Wave will unpair all devices synced with this hub.",
    });

    if (confirmed) {
      await sendResetZWave({ serial: hub.serial, unitId });
    }
  }, [confirm, sendResetZWave, hub, unitId]);

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Reset Z-Wave</Typography>
            {!hub?.online ? (
              <Typography type="caption" color="warning">
                Hub is offline
              </Typography>
            ) : null}
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton
            disabled={!hub?.online}
            icon={Pairing}
            onClick={() => {}}
          />
        </CardBody>
        <CardFooter>
          <Button
            disabled={isLoading || !hub?.online}
            style={styles.disabledButtonStyle}
            onPress={onClick}
          >
            {isLoading ? <ActivityIndicator /> : "Run Action"}
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="Reset should be used if you want to unpair all devices."
        visible={visible}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
  disabledButtonStyle: { width: 134.25, height: 40 },
});
