import { useCallback } from "react";
import { View } from "react-native";
import { StatusBadge, Typography, useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { SmsWebhookProps } from "../types/SmsWebhookProps";

export function SmsWebhookCard({ webhook }: { webhook: SmsWebhookProps }) {
  const { colors } = useTheme();

  const convertStatus = useCallback((status) => {
    switch (status) {
      case "delivered":
        return "success";
      case "undelivered":
        return "error";
      case "sent":
        return "knowledge";
      default:
        return "default";
    }
  }, []);

  return (
    <View
      style={{
        borderBottomColor: colors.gray300,
        borderBottomWidth: 1,
        padding: 18,
        margin: 12,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography type="title4" color="primary">
          {Helpers.formatDate(webhook.inserted_at)}
        </Typography>
        <StatusBadge
          status={convertStatus(webhook.status)}
          size="semibold.title.two"
        >
          {webhook.status}
        </StatusBadge>
      </View>
      {webhook.body ? (
        <Typography
          type="caption"
          style={{
            borderLeftWidth: 3,
            borderLeftColor: colors.gray200,
            marginVertical: 6,
            paddingVertical: 12,
            paddingLeft: 12,
          }}
        >
          {webhook.body}
        </Typography>
      ) : (
        <Typography
          type="caption"
          style={{
            borderLeftWidth: 3,
            borderLeftColor: colors.warning,
            marginVertical: 6,
            paddingVertical: 12,
            paddingLeft: 12,
            fontStyle: "italic",
          }}
        >
          Message body unavailable
        </Typography>
      )}
    </View>
  );
}
