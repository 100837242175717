import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Typography, Button, AlertDialog, ConfirmDialog } from "@smartrent/ui";
import { WireGlobe, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useHubPrepMutation } from "@/api";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";

export interface RegisterHubProps {
  serial: string;
}
export const RegisterHub: React.FC<RegisterHubProps> = ({ serial }) => {
  const {
    onOpen: openHelp,
    onClose: onCloseHelp,
    visible: visibleHelp,
  } = useModalState();
  const { onOpen: onOpenConfirm, onClose, visible } = useModalState();
  const [sendHubPrep] = useHubPrepMutation();

  const onDialogConfirm = useCallback(async () => {
    onClose();
    await sendHubPrep({
      serials: [serial],
      advancedOptions: { registrationRequired: true },
      action: "advanced",
    });
  }, [sendHubPrep, serial, onClose]);

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Register Hub</Typography>
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton icon={WireGlobe} onClick={() => {}} />
        </CardBody>
        <CardFooter>
          <Button onPress={onOpenConfirm}>Run Action</Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="Register hub should be used if the hub is attached in CMW, and does not have a Zipato account."
        visible={visibleHelp}
        onClose={onCloseHelp}
      />

      <ConfirmDialog
        title="Are you sure?"
        visible={visible}
        onClose={onClose}
        onConfirm={onDialogConfirm}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
});
