import { useMemo, useCallback } from "react";
import * as React from "react";
import { Text, Button } from "@smartrent/ui";
import { useHistory } from "react-router-dom";

import { Table, ColumnOptions } from "@/common/sr-table";
import { useDrawerNav } from "@/common/AppDrawer";
import { useGlobalContext, useUser } from "@/layout/Context";
import { useVendorApplicationsQuery } from "@/api";

import { VendorApplication } from "@/types";

export const VendorTable: React.FC = () => {
  const { match } = useGlobalContext();
  const { permissions } = useUser();
  const drawer = useDrawerNav();
  const history = useHistory();

  const organizationId = match.params.organizationId;

  const { data, isLoading } = useVendorApplicationsQuery(organizationId);

  const vendorApplications = data?.records;
  const currentPage = data?.current_page;
  const totalPages = data?.total_pages;
  const totalRecords = data?.total_records;

  const keyExtractor = useCallback((row: VendorApplication) => `${row.id}`, []);

  const columns = useMemo<ColumnOptions<VendorApplication>[]>(
    () => [
      {
        name: "name",
        header: "Name",
        sortable: true,
        render: ({ row }) => <Text>{row.name}</Text>,
      },
      {
        name: "key",
        header: "Key",
        sortable: true,
        render: ({ row }) => <Text>{row.key}</Text>,
      },
      {
        name: "max_codes_per_unit",
        header: "Max Codes Per Unit",
        sortable: true,
        render: ({ row }) => <Text>{row.max_codes_per_unit}</Text>,
      },
    ],
    []
  );

  const handleRowClick = useCallback(
    (vendorApplication) => {
      history.push(
        `/organizations/${organizationId}/vendor-applications/${vendorApplication.id}`
      );
    },
    [history]
  );

  const handleCreateClick = useCallback(
    () => drawer.push("createVendorApplication", { organizationId }),
    [drawer, organizationId]
  );

  return (
    <Table<VendorApplication>
      title="Vendor Applications"
      loading={isLoading}
      data={vendorApplications || []}
      noRecordsText="No Vendor Applications"
      columns={columns}
      keyExtractor={keyExtractor}
      onRowPress={handleRowClick}
      currentPage={currentPage}
      totalPages={totalPages}
      totalRecords={totalRecords}
      action={
        permissions.modify_vendor_applications ? (
          <Button onPress={handleCreateClick}>Add New Vendor</Button>
        ) : null
      }
    />
  );
};
