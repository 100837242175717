import { useMemo } from "react";
import * as React from "react";

import { StyleSheet } from "react-native";

import {
  Chip,
  ChipProps,
  HStack,
  Typography,
  TypographyProps,
  useTheme,
  VStack,
} from "@smartrent/ui";

import { upperCase } from "lodash-es";

import { useIsDesktop } from "@/hooks/breakpoints";

import { formatDate, isToday } from "@/utils";
import { EmailWebhookView, SmsWebhookProps } from "@/types";

export interface WebhookLabelProps {
  isPanelExtended: boolean;
  chipColor: ChipProps["color"];
  chipText: string;
  chipIcon: ChipProps["iconLeft"];
  recipient: TypographyProps["children"];
  inserted_at: SmsWebhookProps["inserted_at"] | EmailWebhookView["inserted_at"];
  message: string;
  messageEmpty: string;
}
export const WebhookLabel: React.FC<WebhookLabelProps> = ({
  isPanelExtended,
  chipColor,
  chipText,
  chipIcon,
  recipient,
  inserted_at,
  message,
  messageEmpty,
}) => {
  const { spacing } = useTheme();
  const isDesktop = useIsDesktop();

  const insertedAt = useMemo(() => {
    let pattern = "";

    if (isToday({ date: inserted_at })) {
      pattern = "p"; // 5:00 PM .. if current day
    } else if (isDesktop) {
      pattern = "MMM d"; // Mar 1 .. desktop has less room
    } else {
      pattern = "MMM d, yyyy"; // Mar 1, 2023 .. mobile has more room
    }
    return formatDate({ date: inserted_at, pattern });
  }, [isDesktop, inserted_at]);

  return (
    <VStack spacing={spacing.xs} style={styles.root}>
      <Chip
        corners="sharp"
        style={styles.chip}
        textStyle={styles.chipText}
        variation="muted"
        size="small"
        iconLeft={chipIcon}
        color={chipColor}
      >
        {upperCase(chipText)}
      </Chip>

      <HStack style={styles.centerRow} flexChildren={true} width="100%">
        <Typography
          type="title5"
          color="mutedTextPrimary"
          numberOfLines={1}
          style={styles.recipient}
        >
          {recipient}
        </Typography>

        <Typography
          type="title6"
          color="mutedTextPrimary"
          numberOfLines={1}
          style={styles.insertedAt}
        >
          {insertedAt}
        </Typography>
      </HStack>

      <Typography
        type="body"
        numberOfLines={isDesktop ? 1 : 2}
        style={styles.subject}
      >
        {isPanelExtended ? "" : message ? message.slice(0, 125) : messageEmpty}
      </Typography>
    </VStack>
  );
};

const styles = StyleSheet.create({
  root: { flex: 1 },
  centerRow: { justifyContent: "space-between" },
  chip: { alignSelf: "flex-start" },
  chipText: { fontSize: 16 },
  recipient: { minWidth: 150 },
  subject: { maxWidth: 480 },
  insertedAt: { alignSelf: "flex-end" },
});
