import { useState, useMemo, useCallback, useEffect } from "react";
import * as React from "react";
import { StyleSheet, FlatList } from "react-native";

import { map, startCase } from "lodash-es";

import {
  ActivityIndicator,
  FlatListItemSeparator,
  PanelHeader,
  Panel,
  HStack,
  Typography,
  Button,
} from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { useHubPrepLogQuery } from "@/api/hub";

import { valueToHumanReadableString } from "@/utils/human-readable-values";

import { NoContent } from "@/pages/hub-page/NoContent";
import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";

export interface HubPrepLogsProps {
  hubSerial: string;
}

const DataList: React.FC<{ prepLogs }> = ({ prepLogs }) => (
  <FlatList
    data={prepLogs}
    keyExtractor={({ key }) => key}
    renderItem={({ item }) => (
      <ListItemLabelValueCompact
        label={startCase(item.key)}
        value={
          item.key !== "firmware"
            ? valueToHumanReadableString(item.value)
            : item.value
        }
      />
    )}
    ItemSeparatorComponent={FlatListItemSeparator}
  />
);

export const HubPrepLogs: React.FC<HubPrepLogsProps> = ({ hubSerial }) => {
  const [initiallyClicked, setInitiallyClicked] = useState(false);
  const [ableToRefresh, setAbleToRefresh] = useState(true);

  const { data, isLoading, isFetching, isError, isRefetchError, refetch } =
    useHubPrepLogQuery(
      { serial: hubSerial },
      { enabled: !!hubSerial && initiallyClicked }
    );

  useEffect(() => {
    let loader;

    if (!isLoading && !isFetching) {
      loader = window.setTimeout(() => {
        setAbleToRefresh(true);
      }, 1500);
    }

    return () => {
      window.clearTimeout(loader);
    };
  }, [isLoading, isFetching]);

  const prepLogs = useMemo(() => {
    return map(data as any, (value, key) => ({ key, value }));
  }, [data]);

  const fetch = useCallback(() => {
    setInitiallyClicked(true);
    setAbleToRefresh(false);
    refetch();
  }, [refetch]);

  return (
    <Panel style={styles.panel}>
      <PanelHeader>
        <HStack justify="space-between" style={styles.headerWidth}>
          <Typography type="title4">Hub Prep Logs</Typography>
          {ableToRefresh ? (
            <Button variation="plain" onPress={fetch} disabled={isFetching}>
              {isFetching ? <ActivityIndicator size={24} /> : <Refresh />}
            </Button>
          ) : (
            <ActivityIndicator size={24} />
          )}
        </HStack>
      </PanelHeader>
      {!isLoading && !isFetching && initiallyClicked && ableToRefresh ? (
        isError || isRefetchError ? (
          <HStack style={styles.noContentWrapper} justify="center">
            <HStack justify="center">
              <NoContent isError={true} />
            </HStack>
          </HStack>
        ) : (
          <DataList prepLogs={prepLogs} />
        )
      ) : (
        <HStack justify="center" style={styles.noContentWrapper}>
          <NoContent />
        </HStack>
      )}
    </Panel>
  );
};

const styles = StyleSheet.create({
  headerWidth: {
    width: "100%",
  },

  panel: {
    width: "100%",
  },
  noContentWrapper: {
    padding: 16,
    width: "100%",
  },
});
