import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  FormikSelectField,
  StaticTextField,
  Typography,
  useTheme,
} from "@smartrent/ui";
import { InformationOutline } from "@smartrent/icons";

import { useDrawerNav } from "@/common/AppDrawer";

import {
  usePropertyTransferMutation,
  selectQueryPropsGroups,
  selectQueryPropsOrg,
} from "@/api";
import { useGlobalContext } from "@/layout/Context";

import { OrganizationProps } from "@/types";

interface PropertyTransferProps {
  organization: OrganizationProps;
}

export const PropertyTransfer: React.FC<PropertyTransferProps> = ({
  organization,
}) => {
  const { colors } = useTheme();
  const { pop } = useDrawerNav();

  const { setToast } = useGlobalContext();

  const [propertyTransfer] = usePropertyTransferMutation();

  const initialValues = useMemo<Partial<OrganizationProps>>(
    () => ({
      name: organization?.name ?? "",
      group_id: "",
      to_organization_id: "",
    }),
    [organization]
  );

  const validationSchema = Yup.object({
    group_id: Yup.string().required("Must select a group"),
    to_organization_id: Yup.string().required(
      "Must provide a destination organization"
    ),
  });

  const handleCancel = useCallback(() => pop(), [pop]);

  const handleSubmit = useCallback(
    async (
      values: {
        id: string;
        group_id: string;
        to_organization_id: string;
      },
      actions: FormikHelpers<Partial<OrganizationProps>>
    ) => {
      actions.setSubmitting(true);

      try {
        const { id } = organization;
        await propertyTransfer({
          id,
          group_id: values.group_id,
          to_organization_id: values.to_organization_id,
        });
        pop();
        setToast({
          type: "success",
          title: "Success",
          message: "Successfully initiated transfer.",
        });
      } catch (error) {
        const solicitErrors = (error as any)?.response?.data?.errors;
        pop();
        setToast({
          type: "error",
          title: "Error",
          message:
            solicitErrors ||
            "Something went wrong. Please contact engineering.",
        });
      }
      actions.setSubmitting(false);
    },
    [organization, propertyTransfer, pop, setToast]
  );

  return (
    <>
      <DrawerHeader
        title="Property Transfer"
        subtitle="Use the options below to transfer a group to a new organization"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <>
            <DrawerContent contentContainerStyle={styles.drawerContent}>
              <Typography style={styles.drawerContent}>
                Select the group and destination organization:
              </Typography>
              <StaticTextField
                label="Current Organization"
                value={organization.name}
                onPress={() => {}}
                disabled
              />
              <FormikSelectField
                label="Select a Group to Transfer"
                name="group_id"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ marketing_name }) => marketing_name}
                {...selectQueryPropsGroups({ selectedOrg: organization })}
              />
              <FormikSelectField
                label="Select a Destination Organization"
                name="to_organization_id"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                {...selectQueryPropsOrg()}
              />
              <View
                style={{
                  margin: 6,
                  backgroundColor: colors.listBackgroundHover,
                  borderRadius: 4,
                  borderColor: colors.border,
                  borderWidth: 1,
                  padding: 16,
                }}
              >
                <Typography type="title5">
                  <InformationOutline size={20} /> Transfer Group will perform
                  these actions:
                </Typography>

                <Typography>1. Unassign all users</Typography>
                <Typography>2. Delete access codes</Typography>
                <Typography>3. Delete region</Typography>
                <Typography>4. Update settings</Typography>
                <Typography>5. Delete notifications</Typography>
                <Typography>6. Delete vendor applications</Typography>
                <Typography>7. Transfer Group to new Org</Typography>
              </View>
            </DrawerContent>

            <DrawerActions>
              <Button onPress={handleCancel} variation="plain">
                Cancel
              </Button>
              <FormikSubmit
                label="Transfer"
                submittingLabel="Transferring..."
              />
            </DrawerActions>
          </>
        )}
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  drawerContent: { paddingTop: 16 },
});
