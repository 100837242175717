import { useCallback } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { Link } from "@smartrent/ui";

import { useGlobalContext } from "@/layout/Context";
import { groupsAllUnitsTableNativeId } from "@/utils/native-ids";
import { useLayout } from "@/hooks/use-layout";
import { PropertyHealthReport } from "@/types";

export const AllUnitsTableLink: React.FC<
  PropertyHealthReport["unhealthyUnits"]
> = ({ count, link }) => {
  const { history } = useGlobalContext();
  const { scrollTo } = useLayout();

  const onPress = useCallback(() => {
    const tableElement = document.getElementById(groupsAllUnitsTableNativeId);
    history.push(link);
    if (tableElement) {
      scrollTo({ y: tableElement.offsetTop });
    }
  }, [history, link, scrollTo]);

  return (
    <Link type="bodyLargeSemibold" style={styles.link} onPress={onPress}>
      {" "}
      View All Unhealthy Units {count ? `(${count})` : null}
    </Link>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecorationThickness: "from-font",
    fontSize: 14,
  },
});
