import * as React from "react";
import { DrawerContent, DrawerHeader, VStack } from "@smartrent/ui";

import { GroupProps } from "@/types";

import { GroupReportsDeviceReportButton } from "./GroupReportsDeviceReportButton";
import { GroupJobsReportButton } from "./GroupJobsReportButton";
import { GroupReportsHubStatusReportButton } from "./GroupReportsHubStatusReportButton";

interface Props extends Partial<GroupProps> {
  id: number;
}

export const GroupReportsDrawer: React.FC<Props> = (group) => {
  return (
    <>
      <DrawerHeader title={`${group.marketing_name} Reports`} />
      <DrawerContent>
        <VStack spacing={8}>
          <GroupJobsReportButton />
          <GroupReportsDeviceReportButton />
          <GroupReportsHubStatusReportButton />
        </VStack>
      </DrawerContent>
    </>
  );
};
