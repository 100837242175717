import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import {
  ActivityIndicator,
  Typography,
  Button,
  AlertDialog,
} from "@smartrent/ui";
import { Motion, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useHubBoxSyncQuery } from "@/api/hub";
import { HubRequest } from "@/types";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";

export interface BoxSync {
  hub: HubRequest;
  unitId: number;
}
export const BoxSync: React.FC<BoxSync> = ({ hub, unitId }) => {
  const { onOpen: openHelp, onClose, visible } = useModalState();
  const [sendHubBoxSync, { isLoading }] = useHubBoxSyncQuery();

  const onClick = useCallback(async () => {
    await sendHubBoxSync({ serial: hub.serial, unitId });
  }, [sendHubBoxSync, hub, unitId]);

  if (hub?.type !== "zipato") {
    return null;
  }

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Box Sync</Typography>
            {!hub?.online ? (
              <Typography type="caption" color="warning">
                Hub is offline
              </Typography>
            ) : null}
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton
            disabled={!hub?.online}
            icon={Motion}
            onClick={() => {}}
          />
        </CardBody>
        <CardFooter>
          <Button
            disabled={isLoading || !hub?.online}
            style={styles.disabledButtonStyle}
            onPress={onClick}
          >
            {isLoading ? <ActivityIndicator /> : "Run Action"}
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description='This action can be run if the device responds to remote commands during inclusion, but the attributes (i.e. "Locked") are not being updated from orange to green. After a successful box sync, be sure to click "Configure Device" again, which may help with receiving attribute values.'
        visible={visible}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
  disabledButtonStyle: { width: 134.25, height: 40 },
});
