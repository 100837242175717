import { useFormikContext } from "formik";
import { useCallback } from "react";
import * as React from "react";
import { TextInput } from "react-native";
import { useQueryClient } from "@tanstack/react-query";

import { useDebouncedCallback } from "@smartrent/hooks";
import { FormikTextareaField, TextInputBaseProps } from "@smartrent/ui";

import {
  HubRefurbishContext,
  useHubRefurbishContext,
} from "@/pages/hub-refurbish/HubRefurbishContext";

import {
  HubRefurbishFormikFieldLabels,
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
  RefurbishHub,
  RefurbishHubDict,
} from "@/types/HubRefurbishProps";

interface SerialsInputProps {
  serials: HubRefurbishContext["serials"];
  innerRef: React.Ref<TextInput>;
  textInputProps?: TextInputBaseProps;
  disabled?: boolean;
}

const REDIS_STATS_DEBOUNCE_DURATION = 500;

// this component is separated out so we can use the useFormikContext() hook
export const HubRefurbishStepSerialsInput: React.FC<SerialsInputProps> = ({
  serials,
  innerRef,
  textInputProps,
  disabled,
}) => {
  const queryClient = useQueryClient();
  const { validateField, setFieldTouched, setFieldValue } = useFormikContext();
  const { hubsDict, setHubsDict } = useHubRefurbishContext();

  // formik only displays validation errors when the field is 'touched'
  const validateSerialsField = useCallback(() => {
    validateField(HubRefurbishFormikFieldNames.Serials);
    setFieldTouched(HubRefurbishFormikFieldNames.Serials);
  }, [setFieldTouched, validateField]);

  // do a full cleanup of serials whenever we click '(x)' clear all button on text area input
  const handleClearButtonClick = useCallback(() => {
    setHubsDict({});
    setFieldValue(HubRefurbishFormikFieldNames.Serials, []);
    queryClient.removeQueries([HubRefurbishReactQueryKeys.RedisStats]);
  }, [queryClient, setFieldValue, setHubsDict]);

  // let users see their serial numbers instantly
  const setSerialsAsYouType = useCallback(
    ({ nativeEvent: { text } }) => {
      // check if serials is empty
      if (text === "" && serials?.length !== 0) {
        setHubsDict({});
        queryClient.removeQueries([HubRefurbishReactQueryKeys.RedisStats]);
        return;
      }

      // transform text into array of serials
      const list = text
        .split("\n")
        .map((serial: string) => serial.trimStart().toUpperCase())
        .filter((serial) => serial !== "");

      // check if we're empty again
      if (!list?.length) {
        setHubsDict({});
        queryClient.removeQueries([HubRefurbishReactQueryKeys.RedisStats]);
        validateSerialsField();
        return;
      }

      // check for duplicates
      const listSet = new Set(list);
      if (list.length !== listSet.size) {
        validateSerialsField();
        return;
      }

      const updatedHubsDict = list.reduce(
        (reduceDict: RefurbishHubDict, serial: RefurbishHub["serial"]) => {
          reduceDict[serial] = hubsDict[serial] ? hubsDict[serial] : { serial };
          return reduceDict;
        },
        {}
      );

      setHubsDict(updatedHubsDict);
    },
    [hubsDict, queryClient, serials?.length, setHubsDict, validateSerialsField]
  );

  // add debounce to setting serials since it's a pretty expensive operation to perform
  const handleOnChangeText = useDebouncedCallback(
    setSerialsAsYouType,
    REDIS_STATS_DEBOUNCE_DURATION
  );

  return (
    <FormikTextareaField
      ref={innerRef}
      name={HubRefurbishFormikFieldNames.Serials}
      label={HubRefurbishFormikFieldLabels.Serials}
      // handle when user clicks (x) button
      // this was sometimes happening here instead of in textInput.OnChange
      // so I put it here & in the textInput.OnChange callback .. -_-
      transformValue={(val) => {
        if (val === "") {
          handleClearButtonClick();
        }
        return val.replaceAll(/disabled_/gi, "");
      }}
      textInputProps={{
        style: { minHeight: 320, ...textInputProps }, // StyleSheet doesn't work so use object literal here
        onChange: handleOnChangeText,
      }}
      required
      disabled={disabled}
    />
  );
};
