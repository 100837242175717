import { useCallback, useMemo } from "react";
import * as React from "react";
import { TouchableOpacity } from "react-native";
import { Button, Text, useTheme } from "@smartrent/ui";
import { TrashX } from "@smartrent/icons";

import { VendorApplication, GroupProps } from "@/types";
import { Table, ColumnOptions } from "@/common/sr-table";
import { useDrawerNav } from "@/common/AppDrawer";
import { useUser } from "@/layout/Context";
import { useUnassignGroupFromVendorApplicationMutation } from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

interface VendorApplicationGroupsTableProps {
  vendorApplication: VendorApplication;
}

export const VendorApplicationGroupsTable: React.FC<
  VendorApplicationGroupsTableProps
> = ({ vendorApplication }) => {
  const { permissions } = useUser();
  const { colors } = useTheme();
  const { confirm } = useDialog();
  const drawer = useDrawerNav();
  const [unassignGroupFromVendorApplication] =
    useUnassignGroupFromVendorApplicationMutation();

  const keyExtractor = useCallback((row: GroupProps) => `${row.id}`, []);

  const handleAssignGroupClicked = useCallback(
    () =>
      drawer.push("assignGroupToVendorApplication", {
        vendorApplication: vendorApplication,
      }),
    [vendorApplication]
  );

  const handleUnassignGroupClicked = useCallback(
    async (row) => {
      const confirmed = await confirm({
        title: "Unassign Group",
        confirmText: "Unassign",
        description:
          "Are you sure you want to unassign this group from this vendor application? This vendor will no longer be able to make requests for the group.",
      });

      if (confirmed) {
        await unassignGroupFromVendorApplication({
          organizationId: vendorApplication.organization_id,
          vendorApplicationId: vendorApplication.id,
          groupId: row.id,
        });
      }
    },
    [vendorApplication]
  );

  const columns = useMemo<ColumnOptions<GroupProps>[]>(
    () => [
      {
        name: "id",
        header: "ID",
        sortable: true,
        render: ({ row }) => <Text>{row.id}</Text>,
      },
      {
        name: "marketing_name",
        header: "Name",
        sortable: true,
        render: ({ row }) => <Text>{row.marketing_name}</Text>,
      },
      {
        name: "property_code",
        header: "Property Code",
        sortable: true,
        render: ({ row }) => <Text>{row.property_code}</Text>,
      },
      {
        name: "unassign",
        header: null,
        maxWidth: 24,
        render: ({ row }) => (
          <TouchableOpacity onPress={() => handleUnassignGroupClicked(row)}>
            <TrashX size={24} color={colors.error} />
          </TouchableOpacity>
        ),
      },
    ],
    []
  );

  return (
    <Table<GroupProps>
      title="Assigned Groups"
      loading={false}
      data={vendorApplication.groups || []}
      noRecordsText="No Assigned Groups"
      columns={columns}
      keyExtractor={keyExtractor}
      onRowPress={null}
      action={
        permissions.modify_vendor_applications ? (
          <Button onPress={handleAssignGroupClicked}>Assign Group</Button>
        ) : null
      }
    />
  );
};
