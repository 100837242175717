import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerLoader,
  FormikMultiSelectField,
  FormikSubmit,
  FormikTextInputField,
  FormTitle,
  MultiSelectOption,
  VStack,
} from "@smartrent/ui";

import {
  formatConstructionType,
  BookingType,
  SpaceType,
} from "@smartrent/parking";

import { Sign } from "@/types";
import { useSpacesInfiniteQuery } from "@/api";

import NoRecords from "@/common/NoRecords";

export interface SignFormValues {
  location: string;
  space_ids: string[];
}

interface SignFormProps {
  propertyId: string;
  onSubmit: (
    values: SignFormValues,
    actions: FormikHelpers<SignFormValues>
  ) => void;
  onCancel: () => void;
  sign?: Sign;
  action?: React.ReactElement;
  isLoading?: boolean;
}

const validationSchema = Yup.object({
  location: Yup.string().nullable().required("Please enter a location.").trim(),
  space_ids: Yup.array().optional(),
});

export const SignForm: React.FC<SignFormProps> = ({
  propertyId,
  onSubmit,
  onCancel,
  sign,
  action,
  isLoading = false,
}) => {
  const title = useMemo(() => (sign ? "Edit Sign" : ""), [sign]);

  const submitText = useMemo(() => (sign ? "Update" : "Create"), [sign]);

  const { reducedData: spaces, isLoading: loadingSpaces } =
    useSpacesInfiniteQuery(propertyId, {
      type: SpaceType.Guest,
      booking_type: BookingType.Assigned,
      active: true,
      limit: 1000,
    });

  const { reducedData: assignedSpaces, isLoading: loadingAssignedSpaces } =
    useSpacesInfiniteQuery(propertyId, {
      type: SpaceType.Guest,
      booking_type: BookingType.Assigned,
      sign_id: sign?.id,
      limit: 1000,
    });

  const loading = useMemo(
    () => isLoading || loadingSpaces || loadingAssignedSpaces,
    [isLoading, loadingSpaces, loadingAssignedSpaces]
  );

  const spaceOptions = useMemo<MultiSelectOption[]>(() => {
    if (spaces && spaces.length > 0) {
      return spaces.map((space) => ({
        label: `${space.space_number} \u2014 ${formatConstructionType(
          space.construction_type
        )} ${space?.area ? `\u2014 ${space.area.name}` : ""}`,
        value: space.id,
      }));
    }

    return [];
  }, [spaces]);

  const initialValues = useMemo(
    () => ({
      location: sign?.location ?? "",
      space_ids: sign ? assignedSpaces?.map((space) => space.id) ?? [] : [],
    }),
    [sign, assignedSpaces]
  );

  return loading ? (
    <DrawerLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <View style={styles.header}>
            <FormTitle>{title}</FormTitle>
            {action}
          </View>
          <VStack spacing={8}>
            <FormikTextInputField name="location" label="Location" />
            {spaceOptions.length > 0 ? (
              <FormikMultiSelectField
                name="space_ids"
                options={spaceOptions}
                label="Spaces"
              />
            ) : (
              <NoRecords title="No Spaces Available" />
            )}
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button onPress={onCancel} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label={submitText} />
        </DrawerActions>
      </>
    </Formik>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
