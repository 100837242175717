import * as React from "react";
import { BreadcrumbPage, Breadcrumbs } from "@smartrent/ui";

import { useUnit } from "../UnitContext";

interface UnitBreadcrumbsProps {
  subCrumbs?: BreadcrumbPage[];
}

export const UnitBreadcrumbs: React.FC<UnitBreadcrumbsProps> = ({
  subCrumbs = [],
}) => {
  const { unit, group, organization } = useUnit();
  const breadcrumbs = React.useMemo(
    () => [
      { label: organization.name, to: `/organizations/${organization.id}` },
      { label: group.marketing_name, to: `/groups/${group.id}` },
      {
        label: unit.marketing_name,
        to: `/units/${unit.id}`,
      },
      ...subCrumbs,
    ],
    [
      group.id,
      group.marketing_name,
      organization.id,
      organization.name,
      subCrumbs,
      unit.id,
      unit.marketing_name,
    ]
  );

  return <Breadcrumbs items={breadcrumbs} />;
};
