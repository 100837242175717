import * as React from "react";
import { HStack, PanelHeader } from "@smartrent/ui";

import { UnitBreadcrumbs } from "./UnitBreadcrumbs";

interface UnitInfoHeaderProps {}

const UnitInfoHeader: React.FC<UnitInfoHeaderProps> = () => {
  return (
    <PanelHeader>
      <HStack spacing={8}>
        <UnitBreadcrumbs />
      </HStack>
    </PanelHeader>
  );
};

export default UnitInfoHeader;
