import { useMemo, useState, useCallback } from "react";
import { startCase, orderBy } from "lodash-es";
import { View, StyleSheet } from "react-native";
import { differenceInDays } from "date-fns";

import {
  BatteryFull,
  BatteryMid,
  BatteryLow,
  CaretDown,
  CaretRight,
  Connection,
  HeartEmpty,
  SerialNumber,
  Offline,
  Online,
} from "@smartrent/icons";

import {
  Button,
  StatusBadge,
  Typography,
  useTheme,
  HStack,
  VStack,
} from "@smartrent/ui";

import { formatDate } from "@/utils";
import { EM_DASH } from "@/utils/chars";
import {
  lockInstalledWithoutAccess,
  installInProgress,
  getInstaller,
} from "@/utils/device";

import Card from "@/common/Card";
import { CircleWithInfo } from "@/common/icons/CircleWithInfo";
import { useDrawerNav } from "@/common/AppDrawer";
import { DataDogLink } from "@/common/links/DataDogLink";
import { SmartLinkQuickLaunch } from "@/common/links/SmartLinks";
import { deviceLogs } from "@/utils/datadog";
import Helpers from "@/lib/helpers";
import { useUser } from "@/layout/Context";

import HoverText from "@/common/HoverText";

import {
  DeviceProps,
  UnitProps,
  DeviceAttributeProps,
  OrganizationProps,
} from "../../types";

import { HistoricBatteryLevelGraph } from "../DeviceBatteryHistory";

import DeviceAttribute from "./DeviceAttribute";
import AccessCodesOnLock from "./AccessCodeOnLock";

import { useUnit } from "./UnitContext";

export default function Device({
  device,
  unit,
  accessCodes,
  attributes,
  unmappedAttributes,
  organization,
}: {
  device: DeviceProps;
  unit: UnitProps;
  accessCodes: any;
  unmappedAttributes: DeviceAttributeProps[];
  attributes: DeviceAttributeProps[];
  organization: OrganizationProps;
}) {
  const { permissions } = useUser();
  const drawer = useDrawerNav();
  const { colors } = useTheme();
  const [showGraph, setShowGraph] = useState(false);
  const { deletedDevices } = useUnit();

  const deleteReasonTranslated = useCallback((deleteReason) => {
    switch (deleteReason) {
      case "delete":
        return "Device deleted";
      case "exclude":
        return "Device excluded";
      case "hub_delete":
        return "Hub deleted";
      case "reset":
        return "Z-Wave network reset";

      default:
        return "";
    }
  }, []);

  const matchingDeletedDevices = useMemo(() => {
    if (!device.dsk) {
      return [];
    }
    return orderBy(
      deletedDevices.filter((dd) => dd.dsk === device.dsk),
      "deleted_at",
      "desc"
    );
  }, [deletedDevices, device]);

  const cardActions = useMemo(() => {
    const cardActions = [];

    if (permissions.device_settings) {
      cardActions.push({
        label: "Update Device Settings",
        onSubmit: () => drawer.push("deviceSettings", { device }),
      });
    }

    return cardActions;
  }, [permissions, drawer, device]);

  const toggleGraph = useCallback(
    () => setShowGraph((showGraph) => !showGraph),
    []
  );

  const heartbeartColor = useMemo(() => {
    if (!device.heartbeat_at) {
      return colors.error;
    }

    const daysSinceLastHeartbeat = differenceInDays(
      new Date(),
      new Date(device.heartbeat_at)
    );

    if (daysSinceLastHeartbeat >= 30) {
      return colors.error;
    } else if (daysSinceLastHeartbeat >= 7) {
      return colors.warning;
    }

    return colors.primary;
  }, [device, colors]);

  const isDeviceOnline = useMemo(() => {
    // Hub device
    if (device.online !== undefined) {
      return !!device.online;
    }
    // Third party device
    else {
      const connectedAttribute = attributes.find(
        (attribute) => attribute.name === "connected"
      );
      return connectedAttribute ? connectedAttribute.last_read_state : true;
    }
  }, [device, attributes]);

  return (
    <Card
      key={device.id}
      heading={
        <View>
          <Typography>{startCase(device.residents_name)}</Typography>
          {device.primary_lock ? (
            <Typography color="textSecondary" type="title6">
              primary
            </Typography>
          ) : null}
          {device.device_descriptor_id ? (
            device.manufacturer === "generic" ? null : (
              <Typography type="title4">
                {device.manufacturer} {device.model}
              </Typography>
            )
          ) : (
            <View style={[styles.badgeBox, { borderColor: colors.warning }]}>
              <Typography
                color="warning"
                type="title6"
                style={styles.alignTextCenter}
              >
                No device descriptor rules found, this device may not properly
                report device attribute changes and residents could have issues
                controlling this device.
              </Typography>
            </View>
          )}
          {device.internal_name &&
          device.internal_name !== device.residents_name ? (
            <Typography type="title6" color="textSecondary">
              Internal Name: ({startCase(device.internal_name)})
            </Typography>
          ) : null}

          {unit &&
          device &&
          organization &&
          permissions.core_platform_access ? (
            <SmartLinkQuickLaunch
              title="Device Overview"
              href={`/manager/groups/${unit?.group_id}/units/${unit?.id}/devices/${device?.id}/status`}
              organizationUrl={organization?.url}
            />
          ) : null}
          {device.warning ? (
            <View style={[styles.badgeBox, { borderColor: colors.warning }]}>
              <Typography
                color="warning"
                type="title6"
                style={styles.alignTextCenter}
              >
                Device in "warning" state Communication with device failing
                intermittently If this continues, the device will be marked as
                offline.
              </Typography>
            </View>
          ) : null}
          {device.access_codes_broken ? (
            <View style={[styles.badgeBox, { borderColor: colors.error }]}>
              <Typography
                color="error"
                type="title6"
                style={styles.alignTextCenter}
              >
                Access codes broken, exclude and include lock to fix!!
              </Typography>
            </View>
          ) : null}
          {lockInstalledWithoutAccess(device) ? (
            <View style={[styles.badgeBox, { borderColor: colors.error }]}>
              <Typography
                color="error"
                type="title6"
                style={styles.alignTextCenter}
              >
                Lock misconfigured, please exclude and include lock to fix!
              </Typography>
            </View>
          ) : null}
          {!device.show_on_dashboard ? (
            <Typography color="textSecondary" type="body">
              Not shown on mobile app dashboard(s)
            </Typography>
          ) : null}
          {device.battery_powered && device.battery_level !== null ? (
            <View>
              <View style={styles.batteryWrapper}>
                {device.battery_level > 50 ? (
                  <BatteryFull size={16} color={colors.green} />
                ) : device.battery_level > 25 ? (
                  <BatteryMid size={16} color={colors.amber} />
                ) : (
                  <BatteryLow size={16} color={colors.error} />
                )}
                <Typography>
                  {device.hasOwnProperty("battery_level")
                    ? `${device.battery_level}%`
                    : "-"}
                </Typography>
                <Button variation="plain" onPress={toggleGraph}>
                  {!showGraph ? (
                    <CaretRight size={16} color="#000" />
                  ) : (
                    <CaretDown size={16} color="#000" />
                  )}
                </Button>
              </View>

              {showGraph ? (
                <View style={styles.graphWrapper}>
                  <HistoricBatteryLevelGraph
                    deviceId={device?.id}
                    unit={unit}
                  />
                </View>
              ) : null}
            </View>
          ) : null}
          {!device.battery_powered ? (
            <Typography>Not battery powered</Typography>
          ) : null}
        </View>
      }
      actions={cardActions}
    >
      <View>
        <View style={styles.quickInfoContainer}>
          <CircleWithInfo
            circleColor={isDeviceOnline ? colors.primary : colors.error}
            icon={
              isDeviceOnline ? (
                <Online color="white" size={20} />
              ) : (
                <Offline color="white" size={20} />
              )
            }
            label="Status"
            value={isDeviceOnline ? "Online" : "Offline"}
          />
          <CircleWithInfo
            circleColor={heartbeartColor}
            icon={<HeartEmpty color="white" size={20} />}
            label="Last Heartbeat"
            value={
              device.heartbeat_at
                ? formatDate({
                    date: device.heartbeat_at,
                    pattern: "P",
                    timeZone: "UTC",
                  })
                : EM_DASH
            }
          />
          <CircleWithInfo
            circleColor={colors.primary}
            icon={<Connection color="white" size={20} />}
            label="Network Type"
            value={
              device.network_type === "zwave"
                ? "Z-Wave"
                : startCase(device.network_type)
            }
          />
          {device?.firmware ? (
            <CircleWithInfo
              circleColor={colors.primary}
              icon={<SerialNumber color="white" size={20} />}
              label="Firmware"
              value={device.firmware}
            />
          ) : null}
        </View>
        <AccessCodesOnLock
          device={device}
          accessCodes={accessCodes[device.id]}
          permissions={permissions}
        />

        {attributes.map((da) => (
          <DeviceAttribute
            key={da.id}
            da={da}
            unit={unit}
            accessCodes={accessCodes}
          />
        ))}

        {permissions.view_technical_details
          ? unmappedAttributes.map((da) => (
              <DeviceAttribute
                key={da.id}
                da={da}
                unit={unit}
                accessCodes={accessCodes}
              />
            ))
          : null}

        {matchingDeletedDevices?.length ? (
          <View
            style={[styles.viewBorderTop, { borderTopColor: colors.gray200 }]}
          >
            <View>
              <Typography type="title6" color="error600">
                Delete History
              </Typography>
            </View>
            {matchingDeletedDevices.map((deletedDevice, idx) => (
              <HStack
                align="stretch"
                key={deletedDevice.id}
                style={
                  idx !== 0
                    ? [styles.deletedDeviceSpacer, styles.spaceBetween]
                    : [styles.spaceBetween]
                }
              >
                <View style={styles.flexShrink}>
                  <Typography type="caption" color="textSecondary">
                    {Helpers.formatDate(deletedDevice.deleted_at)}
                  </Typography>

                  <Typography type="title6">
                    {deletedDevice.internal_name}{" "}
                    {deletedDevice.internal_name !== device.internal_name ? (
                      <HoverText
                        color={Helpers.colors.orange}
                        tooltipText={`Device name of "${deletedDevice.internal_name}" does not match "${device.internal_name}". This may indicate the device was not installed in the correct location or was mislabeled.`}
                      />
                    ) : null}
                  </Typography>
                </View>
                <Typography type="title6" color="textSecondary">
                  {deleteReasonTranslated(deletedDevice.delete_reason)}
                </Typography>
              </HStack>
            ))}
          </View>
        ) : null}

        <View
          style={[styles.viewBorderTop, { borderTopColor: colors.gray200 }]}
        >
          {!device.secure ? (
            <View style={styles.secureInclusionWrapper}>
              <StatusBadge
                status={device.type === "entry_control" ? "error" : "knowledge"}
                variation={
                  device.type === "entry_control" ? "default" : "outlined"
                }
                size="semibold.title.three"
              >
                Secure Inclusion Failed
              </StatusBadge>
            </View>
          ) : null}
          <Typography
            style={styles.dskMarginTop}
            type="caption"
            color="textSecondary"
          >
            DSK: {device.dsk || "\u2014"}
          </Typography>
          <View style={styles.verticalMargin}>
            <Typography type="title5" color="textSecondary">
              {installInProgress(device)
                ? `Installed ${getInstaller(device)} on `
                : `Legacy install on `}
              {Helpers.formatDate(device.inserted_at, "L LT")}
            </Typography>
            {device.installation_status !== "complete" ? (
              <View style={[styles.badgeBox, { borderColor: colors.error }]}>
                <Typography
                  color="error"
                  type="title6"
                  style={styles.alignTextCenter}
                >
                  Device install in progress, CMW background tasks such as
                  adding access codes will not be performed until device
                  installation is complete via the Installer Tab in CMW.
                </Typography>
              </View>
            ) : null}
          </View>
          <VStack>
            {permissions.view_device_identifiers ? (
              <Typography type="caption" color="textSecondary">
                (zw_mf_id: {device.zw_mf_id}, remote_id: {device.remote_id})
              </Typography>
            ) : null}
            {permissions.view_technical_details ? (
              <View style={styles.flexRow}>
                <Typography type="caption" color="textSecondary">
                  (id: {device.id}, device descriptor id:{" "}
                  {device.device_descriptor_id || "n/a"}, product_id:{" "}
                  {device.product_id}, product_type_id: {device.product_type_id}
                  )
                </Typography>
                <DataDogLink
                  href={deviceLogs(device)}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </View>
            ) : null}
          </VStack>
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  alignTextCenter: { textAlign: "center" },
  batteryWrapper: { flexDirection: "row", alignItems: "center" },
  dskMarginTop: { marginTop: 8 },
  deletedDeviceSpacer: { marginTop: 8 },
  spaceBetween: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
  },
  flexShrink: {
    flexShrink: 1,
  },
  graphWrapper: { flexDirection: "row", justifyContent: "center" },
  secureInclusionWrapper: { marginTop: 8, marginBottom: 8, width: "50%" },
  badgeBox: {
    borderWidth: 1,
    borderRadius: 4,
    padding: 4,
    marginVertical: 8,
  },
  viewBorderTop: {
    borderTopWidth: 1,
    padding: 4,
  },
  verticalMargin: { marginVertical: 8 },
  flexRow: { flexDirection: "row" },
  quickInfoContainer: {
    marginBottom: 8,
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
