import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { Button, FormikTextInputField } from "@smartrent/ui";

import { useSensorsPaginatedQuery } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import { SensorStatusPill } from "@/common/parking/SensorStatusPill";
import { useUser } from "@/layout/Context";
import { ColumnOptions, Table } from "@/common/sr-table";
import { ParkingSensor } from "@/types";

interface Props {
  groupId: number;
  propertyId: string;
  style?: StyleProp<ViewStyle>;
}

const PAGE_SIZE = 5;

export const SensorTable: React.FC<Props> = ({
  groupId,
  propertyId,
  style,
}) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const drawer = useDrawerNav();
  const { permissions } = useUser();

  const { data, isLoading } = useSensorsPaginatedQuery({
    property_id: propertyId,
    page,
    ...filters,
    limit: PAGE_SIZE,
  });

  const sensors = data?.records ?? [];
  const totalRecords = data?.total_records ?? 0;
  const currentPage = data?.current_page ?? 1;
  const totalPages = data?.total_pages ?? 1;

  const columns = useMemo<ColumnOptions<ParkingSensor>[]>(
    () => [
      {
        name: "status",
        header: "Status",
        render: ({ row }) => <SensorStatusPill parkingSensor={row} />,
      },
      {
        name: "dev_eui",
        header: "Serial Number",
        render: ({ row }) => row.dev_eui,
        filter: () => (
          <FormikTextInputField name="dev_eui" label="Serial Number" />
        ),
      },
      {
        name: "battery_level",
        header: "Battery Percentage",
        render: ({ row }) => row.battery_percentage ?? "\u2014",
      },
      {
        name: "location",
        header: "Location",
        render: ({ row }) => row?.active_space?.space_number ?? "\u2014",
      },
    ],
    []
  );

  const handleManagePress = useCallback(
    () => drawer.push("migrateSensor", { propertyId }),
    [drawer, propertyId]
  );

  const handlePageChange = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleFiltersChange = useCallback(
    (filters) => setFilters(filters),
    [setFilters]
  );

  const handleRowPress = useCallback(
    (record: ParkingSensor) =>
      drawer.push("sensorActions", { sensorId: record.id, propertyId }),
    [drawer, propertyId]
  );

  const keyExtractor = useCallback((row: ParkingSensor) => row.id, []);

  return (
    <Table<ParkingSensor>
      title="Sensors"
      keyExtractor={keyExtractor}
      data={sensors}
      columns={columns}
      currentPage={currentPage}
      totalPages={totalPages}
      totalRecords={totalRecords}
      onRowPress={handleRowPress}
      onPageChange={handlePageChange}
      filterViewTitle="Filter Sensors"
      filters={filters}
      onFiltersChange={handleFiltersChange}
      tableClearFiltersAction
      drawerClearFiltersAction
      pageSize={PAGE_SIZE}
      loading={isLoading}
      action={
        permissions.manage_parking ? (
          <Button onPress={handleManagePress}>Manage</Button>
        ) : null
      }
      noRecordsText="No Sensors Found"
      style={style}
    />
  );
};
