import { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { create } from "zustand";
import { parseISO, format } from "date-fns";

import * as Yup from "yup";

import { Formik } from "formik";

import {
  Button,
  Form,
  FormikSubmit,
  FormikTextareaField,
  Typography,
  VStack,
  useStatusBanner,
  useTheme,
} from "@smartrent/ui";
import { ExclamationCircleOutline, Flag } from "@smartrent/icons";

import { useUser } from "@/layout/Context";

import { UpdateFlagTaskRequest, useUpdateFlagTask } from "@/api/tasks";

import Modal from "../../modal/Modal";

type OpenModalParams = {
  taskFlagId: string;
  createdOn: string;
  reason: string;
  createdByEmail: string | null;
  completedByEmail: string | null;
  completedOn: string | null;
};

type UseResolveTaskFlagModal = {
  isOpen: boolean;
  taskFlagId: string | null;
  createdByEmail: string | null;
  completedByEmail: string | null;
  createdOn: string;
  completedOn: string | null;
  reason: string;
  close(): void;
  openModal(params: OpenModalParams): void;
};

const schema = Yup.object().shape({
  reason: Yup.string().required(),
});

export const ACCIDENTAL_DOUBLE_CLICK_GRACE_TIME = 600;

export const useResolveTaskFlagModal = create<UseResolveTaskFlagModal>(
  (set) => ({
    isOpen: false,
    taskFlagId: null,
    createdByEmail: "",
    completedByEmail: "",
    completedOn: "",
    createdOn: "",
    reason: "",
    close: () => set(() => ({ isOpen: false, taskFlagId: null })),
    openModal: (params: OpenModalParams) =>
      set(() => ({ isOpen: true, ...params })),
  })
);

export function ResolveTaskFlagModal() {
  const { colors } = useTheme();
  const { addStatusBanner, clearStatusBanner } = useStatusBanner();
  const [isConfirmShowing, setIsConfirmShowing] = useState(true);
  const [isResolveDisabled, setResolveDisabled] = useState(false);

  const {
    close,
    isOpen,
    taskFlagId,
    createdByEmail,
    completedByEmail,
    reason,
    createdOn,
    completedOn,
  } = useResolveTaskFlagModal();
  const { mutateAsync: updateFlagTaskMutate } = useUpdateFlagTask(taskFlagId);
  const { email } = useUser();

  const onSubmit = useCallback(
    async (values) => {
      const payload: UpdateFlagTaskRequest = {
        reason: values.reason,
        completed_at: new Date(),
      };

      if (email) {
        payload.completed_by_email = email;
      }

      await updateFlagTaskMutate(payload, {
        onSuccess: () => close(),
        onError: () => {
          addStatusBanner({
            title: "Could not create task flag. Try again.",
            numberOfLines: 1,
            icon: ExclamationCircleOutline,
            status: "error",
            actions: [
              {
                label: "DISMISS",
                onPress: () => clearStatusBanner(),
              },
            ],
          });
        },
      });

      return false;
    },
    [close, updateFlagTaskMutate, addStatusBanner, clearStatusBanner, email]
  );

  useEffect(() => {
    if (isOpen) {
      setIsConfirmShowing(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isConfirmShowing) {
      setResolveDisabled(true);
      setTimeout(() => {
        setResolveDisabled(false);
      }, ACCIDENTAL_DOUBLE_CLICK_GRACE_TIME);
    }
  }, [isConfirmShowing]);

  return (
    <Modal titleId="flag-task-modal" isOpen={isOpen} onClose={close}>
      <Modal.Header style={{ borderBottomColor: colors.border }}>
        <Typography style={styles.title}>
          <Flag />
          Flag Task:
        </Typography>
      </Modal.Header>
      <Modal.Content scrollable={true}>
        <VStack>
          <Typography style={styles.instructions} numberOfLines={1}>
            Flagged by: {createdByEmail}
          </Typography>

          <Typography style={styles.instructions} numberOfLines={1}>
            {createdOn
              ? format(parseISO(createdOn), "yyyy-MM-dd h:mm aa")
              : null}
          </Typography>

          <Typography style={styles.instructions} numberOfLines={1}>
            Reason: "{reason}"
          </Typography>

          {completedByEmail ? (
            <Typography style={styles.instructions} numberOfLines={1}>
              Resolved By: {completedByEmail}
            </Typography>
          ) : null}

          {completedOn ? (
            <Typography style={styles.instructions} numberOfLines={1}>
              {format(parseISO(completedOn), "yyyy-MM-dd h:mm aa")}
            </Typography>
          ) : null}

          <Formik
            initialValues={{ reason }}
            onSubmit={onSubmit}
            validationSchema={schema}
            enableReinitialize
          >
            <Form>
              <View style={styles.textareaContainer}>
                <FormikTextareaField
                  name="reason"
                  label="Reason"
                  textInputProps={{
                    multiline: true,
                    style: styles.textarea,
                  }}
                />
              </View>

              {isConfirmShowing ? (
                <Button
                  textStyle={styles.button}
                  onPress={() => setIsConfirmShowing(false)}
                >
                  Resolve
                </Button>
              ) : (
                <FormikSubmit
                  textStyle={[styles.button]}
                  disabled={isResolveDisabled}
                  label="Are you sure?"
                />
              )}
              <Button
                textStyle={styles.button}
                variation="plain"
                onPress={close}
              >
                Cancel
              </Button>
            </Form>
          </Formik>
        </VStack>
      </Modal.Content>
    </Modal>
  );
}

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
  },
  instructions: {
    width: 500,
  },
  textareaContainer: {
    marginVertical: 16,
  },
  textarea: {
    flex: 1,
    minHeight: 250,
  },
  button: {
    fontSize: 16,
  },
});
