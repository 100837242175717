import { useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import { formatDistance } from "date-fns";

import { Clock, Lock } from "@smartrent/icons";
import { HStack, Typography, useTheme, VStack } from "@smartrent/ui";

import { RunHubRefurbishBatchJobError } from "@/types";
import { formatDate } from "@/utils";

export const HubRefurbishJobErrorLockMessage: React.FC<
  RunHubRefurbishBatchJobError["error"]["context"]
> = ({ expiresAt, ttl }) => {
  const { colors } = useTheme();

  const humanReadableFormat = useMemo(
    () =>
      ttl
        ? `(${formatDistance(ttl * 1000, 0, {
            includeSeconds: true,
            addSuffix: true,
          })})`
        : "",
    [ttl]
  );

  return (
    <VStack
      spacing={8}
      align="start"
      wrap={true}
      width="100%"
      style={styles.root}
    >
      <HStack align="center" spacing={4}>
        <Lock color={colors.warning} size={14} />
        <Typography type="bodySemibold">Hub is Locked</Typography>
      </HStack>

      <HStack align="center" spacing={4}>
        <Clock color={colors.warning} size={14} />
        <Typography type="body">
          Lock Expires at {formatDate({ date: expiresAt, pattern: "p" })}
        </Typography>
        <Typography type="caption"> {humanReadableFormat}</Typography>
      </HStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  root: { paddingVertical: 16 },
});
