import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Typography, Button, AlertDialog } from "@smartrent/ui";
import { Offline, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useHubOfflineMutation } from "@/api/hub";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";
import { HubRequest } from "@/types";

export interface HubOfflineProps {
  hub: HubRequest;
}
export const HubOffline: React.FC<HubOfflineProps> = ({ hub }) => {
  const { onOpen: openHelp, onClose, visible } = useModalState();
  const [sendHubOnlineRequest] = useHubOfflineMutation();

  const onClick = useCallback(async () => {
    await sendHubOnlineRequest({ serial: hub?.serial });
  }, [sendHubOnlineRequest, hub?.serial]);

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Hub Offline</Typography>
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton icon={Offline} onClick={() => {}} />
        </CardBody>
        <CardFooter>
          <Button disabled={!hub?.online} onPress={onClick}>
            Run Action
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="When should I use Hub Offline? If the hub has a solid green light but SmartRent Manager shows the Hub as offline, this may update SmartRent Manager."
        visible={visible}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
});
