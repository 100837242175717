import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  FormikSelectField,
  StaticTextField,
  Typography,
  useTheme,
  FieldSeparator,
  Spacer,
  HStack,
  VStack,
  FileUploadField,
  ServerFile,
  LinearProgress,
  Result,
  ConfirmDialog,
  SelectField,
} from "@smartrent/ui";
import {
  Check,
  ClipboardTask,
  Copy,
  ExclamationCircleOutline,
  Information,
  Motion,
  Warning,
} from "@smartrent/icons";
import { useClipboard, useModalState } from "@smartrent/hooks";

import { useDrawerNav } from "@/common/AppDrawer";

import { useGlobalContext } from "@/layout/Context";

import { GroupProps, OrganizationProps } from "@/types";
import { selectQueryPropsGroups, selectQueryPropsOrg } from "@/api";
import {
  UnitTransferCheckResults,
  useUnitCheckMutation,
  useUnitTransferMutation,
  useUnitTransferResults,
} from "@/api/unit-transfer";
import { createCsv } from "@/lib/helpers";

interface UnitTransferProps {
  organization: OrganizationProps;
}

interface UnitTransferFormValues {
  to_organization_id?: string;
  to_group_id?: string;
}

export const UnitTransfer: React.FC<UnitTransferProps> = ({ organization }) => {
  const { colors } = useTheme();
  const { pop } = useDrawerNav();
  const { setToast } = useGlobalContext();
  const { visible: isModalVisible, onOpen, onClose } = useModalState();

  const [toGroup, setToGroup] = useState<GroupProps>();
  const [fromGroupId, setFromGroupId] = useState<string | number>();
  const [unitCsvUrl, setUnitCsvUrl] = useState<string>();
  const [jobId, setJobId] = useState<string>("");

  const [unitTransfer] = useUnitTransferMutation();

  const initialValues = useMemo<UnitTransferFormValues>(
    () => ({
      to_organization_id: "",
    }),
    []
  );

  const validationSchema = Yup.object({
    to_organization_id: Yup.string().required(
      "Must provide a destination organization"
    ),
  });

  const handleSubmit = useCallback(
    async (
      values: UnitTransferFormValues,
      actions: FormikHelpers<UnitTransferFormValues>
    ) => {
      actions.setSubmitting(true);

      if (!toGroup || !unitCsvUrl) {
        setToast({
          type: "error",
          title: "Error",
          message:
            "Please select a group and upload a unit list csv file that passes all checks before submitting.",
        });
        return;
      }

      onOpen();

      actions.setSubmitting(false);
    },
    [onOpen, setToast, toGroup, unitCsvUrl]
  );

  const handleConfirm = useCallback(async () => {
    if (!toGroup || !unitCsvUrl || !fromGroupId) {
      onClose();
      return;
    }
    try {
      const result = await unitTransfer({
        target_group_id: toGroup?.id,
        source_group_id: fromGroupId,
        units_csv_url: unitCsvUrl,
      });

      setJobId(result.unit_transfer_job_id);

      onClose();
    } catch (error) {
      const solicitErrors = (error as any)?.response?.data?.errors;
      setToast({
        type: "error",
        title: "Error",
        message:
          solicitErrors || "Something went wrong. Please contact engineering.",
      });
    }
  }, [onClose, setToast, toGroup, unitCsvUrl, unitTransfer, fromGroupId]);

  return (
    <>
      <DrawerHeader
        title="Unit Transfer"
        subtitle="Use the options below to transfer units to a new group."
      />
      <DrawerContent contentContainerStyle={styles.drawerContent}>
        <UnitTransferUnitCheckForm
          organization={organization}
          jobId={jobId}
          onCheck={(csvUrl, fromGroupId) => {
            setUnitCsvUrl(csvUrl);
            if (fromGroupId !== null) {
              setFromGroupId(fromGroupId);
            }
          }}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <>
              {/* Destination Org and Group */}
              <Typography
                type="bodySmallSemibold"
                color="helperText"
                style={styles.sectionTitle}
              >
                SELECT DESTINATION ORG AND GROUP
              </Typography>
              <FormikSelectField
                label="Organization"
                name="to_organization_id"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                {...selectQueryPropsOrg()}
              />
              <SelectField
                label="Group"
                name="to_group_id"
                value={toGroup}
                onChange={(value) => setToGroup(value)}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ marketing_name }) => marketing_name}
                {...selectQueryPropsGroups({
                  selectedOrg: values.to_organization_id
                    ? { id: values.to_organization_id }
                    : undefined,
                })}
              />

              {/* Transfer Warning */}
              <View
                style={[
                  {
                    backgroundColor: colors.listBackgroundHover,
                    borderColor: colors.border,
                  },
                  styles.transferWarningContainer,
                ]}
              >
                <Typography type="title6">
                  Transfer Units will perform these actions:
                </Typography>

                <Typography>
                  1. Check that units are not part of a bulk work order
                  <br />
                  2. Check that Zipato hubs have hub accounts
                  <br />
                  3. Remove vacant access codes from locks
                  <br />
                  4. Unassign users from work orders
                  <br />
                  5. Transfer Units to new Org and Group
                </Typography>
              </View>

              <DrawerActions>
                <Button onPress={pop} variation="plain" disabled={!!jobId}>
                  Cancel
                </Button>
                <FormikSubmit
                  label="Transfer"
                  submittingLabel="Initiating..."
                  disabled={!unitCsvUrl || !!jobId}
                />
              </DrawerActions>

              <FieldSeparator />
              <UnitTransferResults jobId={jobId} />
              <FieldSeparator />
            </>
          )}
        </Formik>
      </DrawerContent>
      <ConfirmDialog
        title="Unit Transfer"
        description="Are you sure that you want to submit the unit transfer?"
        visible={isModalVisible}
        onConfirm={handleConfirm}
        onClose={onClose}
      />
    </>
  );
};

interface UnitCheckFormValues {
  name?: string;
  group_id?: string;
}

interface UnitTransferUnitCheckFormProps {
  organization: OrganizationProps;
  jobId?: string;
  onCheck: (unit_csv_url: string, fromGroupId: string | null) => void;
}

export const UnitTransferUnitCheckForm: React.FC<
  UnitTransferUnitCheckFormProps
> = ({ organization, jobId, onCheck }) => {
  const { setToast } = useGlobalContext();
  const [unitCsv, setUnitCsv] = useState<ServerFile>();
  const [unitCheckResults, setUnitCheckResults] =
    useState<UnitTransferCheckResults>();

  const [unitTransferCheck] = useUnitCheckMutation();

  const initialValues = useMemo<UnitCheckFormValues>(
    () => ({
      name: organization?.name ?? "",
      group_id: "",
    }),
    [organization]
  );

  const validationSchema = Yup.object({
    group_id: Yup.string().required("Must select a group"),
  });

  const hasWarnings =
    unitCheckResults?.unit_without_hub_accounts.length ||
    unitCheckResults?.units_in_bulk_work_order.length ||
    unitCheckResults?.units_in_construction_mode.length ||
    unitCheckResults?.units_not_in_group.length;

  const handleSubmit = useCallback(
    async (
      values: UnitCheckFormValues,
      actions: FormikHelpers<UnitCheckFormValues>
    ) => {
      actions.setSubmitting(true);

      try {
        if (!values.group_id || !unitCsv) {
          setToast({
            type: "error",
            title: "Error",
            message:
              "Please select a group and upload a unit list csv file before checking units.",
          });
          return;
        }
        const results = await unitTransferCheck({
          group_id: values.group_id,
          units_csv_url: unitCsv.url,
        });

        setUnitCheckResults(results);

        // if no unit check errors, then send the url to the parent component
        if (
          !results.unit_without_hub_accounts.length &&
          !results.units_in_bulk_work_order.length &&
          !results.units_in_construction_mode.length &&
          !results.units_not_in_group.length
        ) {
          onCheck(unitCsv.url, values.group_id);
        }
      } catch (error) {
        const solicitErrors = (error as any)?.response?.data?.errors;
        setToast({
          type: "error",
          title: "Error",
          message:
            solicitErrors ||
            "Something went wrong. Please contact engineering.",
        });
      }
      actions.setSubmitting(false);
    },
    [onCheck, setToast, unitCsv, unitTransferCheck]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          <Typography
            type="bodySmallSemibold"
            color="helperText"
            style={styles.sectionTitle}
          >
            SELECT GROUP TO TRANSFER FROM
          </Typography>
          <StaticTextField
            label="Current Organization"
            value={organization.name}
            onPress={() => {}}
            disabled
          />
          <FormikSelectField
            label="Group"
            name="group_id"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ marketing_name }) => marketing_name}
            required
            {...selectQueryPropsGroups({ selectedOrg: organization })}
          />

          <Spacer height={8} />
          <FieldSeparator />

          {/* Upload Unit List */}
          <Typography
            type="bodySmallSemibold"
            color="helperText"
            style={styles.sectionTitle}
          >
            UPLOAD UNIT LIST
          </Typography>
          <FileUploadField
            accept={[".csv"]}
            label=""
            multiple={false}
            required
            files={unitCsv ? [unitCsv] : []}
            onFilesChange={(files) => {
              onCheck("", null);
              setUnitCsv(files && files.length > 0 ? files[0] : undefined);
            }}
          />
          <Spacer height={8} />
          <FormikSubmit
            label="Upload and Check Units"
            submittingLabel="Checking..."
            disabled={!values.group_id || !unitCsv || !!jobId}
          />

          {/* Unit List Warnings */}
          <Spacer height={16} />
          {unitCheckResults && !hasWarnings && (
            <>
              <FieldSeparator />
              <HStack align="center" style={styles.unitCheckContainer}>
                <Check size={24} />
                <Spacer width={8} />
                <Typography type="title6">No warnings found.</Typography>
              </HStack>
            </>
          )}
          <UnitListWarning
            title="Unit IDs not in Group:"
            ids={unitCheckResults?.units_not_in_group || []}
          />
          <UnitListWarning
            title="Unit IDs part of a bulk work order group:"
            ids={unitCheckResults?.units_in_bulk_work_order || []}
          />
          <UnitListWarning
            title="Unit IDs not registered under hub accounts:"
            ids={unitCheckResults?.unit_without_hub_accounts || []}
          />
          <UnitListWarning
            title="Unit IDs under construction:"
            ids={unitCheckResults?.units_in_construction_mode || []}
          />
          <FieldSeparator />
          <Spacer height={16} />
        </>
      )}
    </Formik>
  );
};

interface UnitListWarningProps {
  title: string;
  ids: string[];
}

const UnitListWarning: React.FC<UnitListWarningProps> = ({ title, ids }) => {
  const { clipboardStatus, onCopy } = useClipboard();

  if (!ids.length) {
    return null;
  }

  const text =
    ids.length >= 3
      ? `${ids.slice(0, 3).join(", ")} +${ids.length - 3} more`
      : ids.join(", ");

  return (
    <>
      <FieldSeparator />
      <VStack style={styles.unitCheckContainer}>
        <HStack align="center">
          <Warning size={24} />
          <Spacer width={8} />
          <Typography type="title6">{title}</Typography>
        </HStack>
        <Spacer height={8} />
        <HStack align="center" style={styles.justifyBetween}>
          <Typography type="body">{text}</Typography>
          <Button onPress={() => onCopy(ids.join(","))} variation="plain">
            {clipboardStatus === "Copied" ? (
              <Check size={24} />
            ) : (
              <Copy size={24} />
            )}
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

interface UnitTransferResultsProps {
  jobId: string;
}
const UnitTransferResults: React.FC<UnitTransferResultsProps> = ({ jobId }) => {
  const { pop } = useDrawerNav();
  const [transferCompleted, setTransferCompleted] = useState<boolean>(false);

  const { data: unitTransferResults } = useUnitTransferResults(
    {
      job_id: jobId,
    },
    {
      enabled: !!jobId && !transferCompleted,
      refetchInterval: 1000,
      onSuccess(data) {
        if (data.status === "completed") {
          setTransferCompleted(true);
        }
      },
    }
  );

  const downloadResults = useCallback(() => {
    if (!unitTransferResults || unitTransferResults.status !== "completed") {
      return;
    }

    const csv = createCsv(
      unitTransferResults.results,
      ["id", "status", "reason"],
      { quoteValues: false }
    );

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `unit-transfer-results-${new Date().toISOString()}.csv`;
    a.click();
  }, [unitTransferResults]);

  if (!unitTransferResults) {
    return (
      <Result
        Icon={Information}
        title="No Transfer Results"
        description="Transfer units to see the results here."
      />
    );
  }

  return (
    <VStack style={styles.unitCheckContainer} align="stretch" spacing={16}>
      <LinearProgress
        width={290}
        progress={Math.floor(
          (unitTransferResults.results.length /
            unitTransferResults.total_units) *
            100
        )}
      />
      <VStack align="stretch" width="100%" spacing={8}>
        <HStack align="center" spacing={4}>
          <ClipboardTask size={16} />
          <Typography type="bodySemibold">
            {`${unitTransferResults.results.length} of ${unitTransferResults.total_units} `}
            processed
          </Typography>
        </HStack>
        <HStack align="center" spacing={4}>
          <Motion size={16} />
          <Typography type="bodySemibold">
            {
              unitTransferResults.results.filter((r) => r.status === "ok")
                .length
            }{" "}
            transferred
          </Typography>
        </HStack>
        <HStack align="center" spacing={4}>
          <ExclamationCircleOutline size={16} />
          <Typography type="bodySemibold">
            {
              unitTransferResults.results.filter((r) => r.status === "error")
                .length
            }{" "}
            errors
          </Typography>
        </HStack>
        <Button
          onPress={downloadResults}
          disabled={unitTransferResults.status !== "completed"}
        >
          Download Results CSV
        </Button>
        <Button onPress={pop} variation="plain">
          Close
        </Button>
      </VStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  drawerContent: { paddingTop: 8, paddingBottom: 8 },
  sectionTitle: { paddingTop: 8, paddingBottom: 16 },
  unitCheckContainer: { padding: 16 },
  justifyBetween: { justifyContent: "space-between" },
  transferWarningContainer: {
    margin: 6,
    borderRadius: 4,
    borderWidth: 1,
    padding: 16,
  },
});
