import * as React from "react";

import { View, StyleSheet } from "react-native";

import { Tooltip, TooltipProps, useTheme } from "@smartrent/ui";

import { InformationSolid } from "@smartrent/icons";

export const UsersToolTipPlaceholder: React.VFC = () => {
  const { colors } = useTheme();
  return (
    <View style={styles.margin}>
      <InformationSolid color={colors.pageBackground} size={12} />
    </View>
  );
};

export interface UsersToolTipProps extends Partial<TooltipProps> {
  name: TooltipProps["name"];
  title: TooltipProps["title"];
}
export const UsersToolTip: React.FC<UsersToolTipProps> = (props) => {
  const { colors } = useTheme();
  return (
    <Tooltip {...props}>
      <View style={styles.margin}>
        <InformationSolid color={colors.gray300} size={12} />
      </View>
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  margin: { marginHorizontal: 2 },
});
