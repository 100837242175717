import { useCallback } from "react";
import * as React from "react";
import { View } from "react-native";

import { DrawerLoader } from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { useParkingPropertyQuery } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";

import { DisableParkingAction } from "./DisableParkingAction";
import { ToggleDecalModuleAction } from "./ToggleDecalModuleAction";
import { ToggleGuestModuleAction } from "./ToggleGuestModuleAction";
import { ImportParkingUsersAction } from "./ImportParkingUsersAction";
import { ToggleGuestPaymentModuleAction } from "./ToggleGuestPaymentModuleAction";
import { ToggleMapModuleAction } from "./ToggleMapModuleAction";

interface ParkingQuickActionsProps {
  groupId: number;
  propertyId: string;
}

export const ParkingQuickActions: React.FC<ParkingQuickActionsProps> = ({
  groupId,
  propertyId,
}) => {
  const drawer = useDrawerNav();
  const { permissions } = useUser();

  const { data: parkingProperty, isLoading } =
    useParkingPropertyQuery(propertyId);

  const handleSuccess = useCallback(() => drawer.pop(), [drawer]);

  return isLoading ? (
    <DrawerLoader />
  ) : permissions.manage_parking && parkingProperty ? (
    <View>
      <DisableParkingAction groupId={groupId} onSuccess={handleSuccess} />
      <>
        <ImportParkingUsersAction
          groupId={groupId}
          propertyId={propertyId}
          onSuccess={handleSuccess}
        />
        <ToggleDecalModuleAction
          property={parkingProperty}
          onSuccess={handleSuccess}
        />
        <ToggleGuestModuleAction
          property={parkingProperty}
          onSuccess={handleSuccess}
        />
        {parkingProperty?.guest_parking_module_enabled &&
        permissions.manage_parking_payments ? (
          <ToggleGuestPaymentModuleAction
            property={parkingProperty}
            onSuccess={handleSuccess}
          />
        ) : null}
        <ToggleMapModuleAction
          property={parkingProperty}
          onSuccess={handleSuccess}
        />
      </>
    </View>
  ) : null;
};
