const logoPath = getLogoPath();

interface SupportRentLogoProps {
  size?: number;
}
export const SupportRentLogo = ({ size }: SupportRentLogoProps) => {
  return <img src={logoPath} height={size || 64} width={size || 64} />;
};

function getLogoPath() {
  switch (window.location.hostname) {
    case "localhost":
      return "/assets/images/logo-dev.svg";
    case "support.qa-smartrent.com":
      return "/assets/images/logo-qa.svg";
    default:
      return "/assets/images/logo.svg";
  }
}
