import * as React from "react";
import { every, some } from "lodash-es";

import { Redirect } from "react-router-dom";

import { useUser } from "@/layout/Context";

import { PermissionsProp } from "../types";

interface RequirePermissionProps {
  permission: PermissionsProp | PermissionsProp[];
  mode?: "all" | "any";
  redirectTo?: string;
}

export const RequirePermission: React.FC<RequirePermissionProps> = ({
  permission: requiredPermissions,
  mode = "all",
  redirectTo = "/",
  children,
}) => {
  const { id: userId, permissions } = useUser();

  if (!Array.isArray(requiredPermissions)) {
    requiredPermissions = [requiredPermissions];
  }

  const checkFn = mode === "all" ? every : some;

  if (
    userId &&
    !checkFn(
      requiredPermissions,
      (requiredPermission) => permissions[requiredPermission]
    )
  ) {
    return <Redirect to={redirectTo} />;
  }

  return <>{children}</>;
};

export default RequirePermission;
