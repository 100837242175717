import { useCallback } from "react";
import * as React from "react";
import { TouchableOpacity } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { useEnableSignMutation } from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

import { LoadingDots } from "@/common/LoadingDots";

interface EnableSignActionProps {
  signId: string;
  propertyId: string;
  variation?: "plain" | "icon";
}

export const EnableSignAction: React.FC<EnableSignActionProps> = ({
  signId,
  propertyId,
  variation = "icon",
}) => {
  const { colors } = useTheme();
  const { confirm } = useDialog();

  const [enableSign, { isLoading }] = useEnableSignMutation();

  const handleEnable = useCallback(async () => {
    const confirmed = await confirm({
      title: "Enable Sign",
      confirmText: "Enable",
      description:
        "Are you sure you want to enable this sign? It will not enable prior space assignments.",
    });

    if (confirmed) {
      await enableSign({ signId, propertyId });
    }
  }, [signId, propertyId, enableSign, confirm]);

  return variation === "icon" ? (
    <TouchableOpacity onPress={handleEnable}>
      {isLoading ? <LoadingDots /> : <Refresh color={colors.primary} />}
    </TouchableOpacity>
  ) : (
    <TouchableOpacity onPress={handleEnable}>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <Typography type="title5" color="primary">
          ENABLE
        </Typography>
      )}
    </TouchableOpacity>
  );
};
