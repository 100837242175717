import { useMemo } from "react";
import * as React from "react";
import {
  ListRenderItemInfo,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { Stack, Typography } from "@smartrent/ui";

import { camelCase, startCase } from "lodash-es";

import { useIsMobileCard } from "@/hooks/breakpoints";

import { CopyText } from "../CopyText";

interface CredentialsListItemProps extends ListRenderItemInfo<any> {
  size?: string;
  style?: StyleProp<ViewStyle>;
}

export const CredentialsListItem: React.FC<CredentialsListItemProps> = ({
  item: [key, value],
  size,
  style,
}) => {
  const isMobileCard = useIsMobileCard();

  const textToShow = useMemo(() => {
    const text = String(value);
    if (text === "true" || text === "false") {
      return text ? "Yes" : "No";
    }
    return text;
  }, [value]);

  return (
    <Stack
      direction={isMobileCard || size === "large" ? "column" : "row"}
      align={isMobileCard || size === "large" ? "start" : "center"}
      style={[isMobileCard || size === "large" ? styles.padding : {}, style]}
      spacing={isMobileCard || size === "large" ? 4 : 8}
    >
      <Typography
        color="textSecondary"
        type={size === "large" ? "title3" : "title6"}
      >
        {startCase(camelCase(key))}:
      </Typography>

      {value ? (
        <View>
          <CopyText
            iconSize={size === "large" ? 18 : 14}
            text={String(value)}
            style={styles.copyText}
          >
            <Typography
              type={size === "large" ? "bodyLarge" : "bodySmall"}
              color="textSecondary"
              style={isMobileCard ? styles.textMobile : styles.text}
              numberOfLines={1}
            >
              {textToShow}
            </Typography>
          </CopyText>
        </View>
      ) : (
        <Typography
          type={size === "large" ? "bodyLarge" : "bodySmall"}
          color="mutedBackgroundGrayscale"
          numberOfLines={1}
        >
          None
        </Typography>
      )}
    </Stack>
  );
};

const styles = StyleSheet.create({
  padding: { paddingVertical: 8 },
  copyText: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    maxWidth: 320,
    paddingRight: 4,
  },
  textMobile: {
    maxWidth: 240,
    paddingRight: 8,
  },
});
