import { View, StyleSheet } from "react-native";

import { Typography, useTheme, VStack } from "@smartrent/ui";
import * as React from "react";

interface ContextMenuSectionHeaderProps {
  title: string;
}
export const ContextMenuSectionHeader: React.FC<
  ContextMenuSectionHeaderProps
> = ({ title }) => {
  const { colors, spacing } = useTheme();

  return (
    <VStack>
      <Typography
        type="bodySmallSemibold"
        style={{
          paddingHorizontal: spacing.sm,
          paddingBottom: spacing.xxs,
          paddingTop: spacing.xs,
        }}
        color="labelText"
      >
        {title.toUpperCase()}
      </Typography>
      <View
        style={[
          styles.divider,
          {
            borderBottomColor: colors.border,
            marginHorizontal: spacing.sm,
          },
        ]}
      />
    </VStack>
  );
};

const styles = StyleSheet.create({
  divider: {
    borderBottomWidth: 1,
  },
});
