import { useMemo, useState } from "react";
import * as React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";

import { ActivityIndicator, Button, Typography, useTheme } from "@smartrent/ui";

import { useIsPhone } from "@/hooks/breakpoints";

import { useLatestHubMessagesQuery } from "@/api/hub";

import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";

interface HubMessagesProps {
  serial: string;
}
export const HubMessages: React.FC<HubMessagesProps> = ({ serial }) => {
  const { colors } = useTheme();
  const isPhone = useIsPhone();
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const {
    data: latestMessages,
    isError,
    refetch,
    isFetching,
  } = useLatestHubMessagesQuery(
    { serial: serial },
    {
      refetchInterval: shouldRefetch ? 5000 : false,
      onError: () => setShouldRefetch(false),
    }
  );

  const refetchAndSetInterval = () => {
    refetch();
    setShouldRefetch(true);
  };

  const logs = useMemo(
    () =>
      latestMessages?.total_records > 0 ? latestMessages.records.reverse() : [],
    [latestMessages]
  );

  return (
    <View style={styles.container}>
      <>
        <View style={[styles.title, { borderBottomColor: colors.border }]}>
          <Typography type="title3">Hub Messages</Typography>
          <View style={!isPhone ? styles.flexRow : null}>
            {isFetching ? (
              <View style={styles.refreshIndicator}>
                <Typography color="textSecondary">Refreshing</Typography>
                <ActivityIndicator />
              </View>
            ) : null}
            {isError ? (
              <View style={styles.refreshIndicator}>
                <Typography color="error">Error fetching data</Typography>
              </View>
            ) : null}
            <Button
              size="small"
              disabled={!isError}
              onPress={refetchAndSetInterval}
            >
              {!isError ? "Auto Refresh Enabled" : "Refresh"}
            </Button>
          </View>
        </View>
        {logs.map(({ message, timestamp }, key) => (
          <ListItemLabelValueCompact
            key={key}
            value={message}
            timestamp={timestamp}
            style={styles.logItem}
          />
        ))}
      </>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: 500,
    overflow: "auto",
    padding: 16,
  } as Omit<ViewStyle, "overflow">,
  logItem: {
    marginBottom: 8,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    borderBottomWidth: 1,
  },
  refreshIndicator: {
    marginRight: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  flexRow: { flexDirection: "row" },
});
