import { useMemo } from "react";
import * as React from "react";
import { TextStyle } from "react-native";

import { Chip, ChipProps } from "@smartrent/ui";

import { ConnectionStatus } from "@/utils/zipato";

import { useVirtualBoxHook } from "@/pages/hub-refurbish/hooks/useVirtualBoxHook";

interface VirtualBoxRedisStatsProps extends Partial<ChipProps> {
  serial?: string;
  redisStatus?: any;
  style?: any;
  size?: ChipProps["size"];
  fontSize?: TextStyle["fontSize"];
  isLoading?: boolean;
}

export const VirtualBoxRedisStats: React.FC<VirtualBoxRedisStatsProps> = ({
  serial,
  redisStatus,
  isLoading,
  style,
  size,
  fontSize,
}) => {
  const {
    isDisabled,
    isToBeRemoved,
    isInHubPrep,
    handleOnPress,
    serialShort,
    style: chipDefaultStyle,
    textStyle: chipDefaultTextStyle,
  } = useVirtualBoxHook({ serial });

  const color = useMemo<ChipProps["color"]>(() => {
    if (isDisabled || isToBeRemoved) {
      return "grayscale";
    }

    if (isInHubPrep) {
      return "warning";
    }

    if (isLoading) {
      return "info";
    }

    switch (redisStatus) {
      case ConnectionStatus.Online:
        return "success";
      case ConnectionStatus.Offline:
        return "error";
      case ConnectionStatus.Error:
      case ConnectionStatus.Unknown:
        return "warning";
      default:
        return "info";
    }
  }, [isDisabled, isInHubPrep, isLoading, isToBeRemoved, redisStatus]);

  return (
    <Chip
      corners="badge"
      variation="muted"
      color={color}
      onPress={handleOnPress}
      size={size}
      style={[chipDefaultStyle, style]}
      textStyle={[chipDefaultTextStyle, { fontSize }]}
      disabled={isDisabled}
    >
      {serialShort}
    </Chip>
  );
};
