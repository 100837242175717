import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { AnimatedPlaceholder, DrawerHeader, useTheme } from "@smartrent/ui";

import { useSensorQuery } from "@/api";

export interface SignHeaderProps {
  sensorId: string;
}

const SensorHeader: React.FC<SignHeaderProps> = ({ sensorId }) => {
  const { colors } = useTheme();

  const { data: sensor, isLoading } = useSensorQuery(sensorId);

  const title = useMemo(
    () =>
      isLoading || !sensor ? (
        <View style={styles.placeholderContainer}>
          <AnimatedPlaceholder
            style={[
              styles.titlePlaceholder,
              { backgroundColor: colors.animatedPlaceholder },
            ]}
          />
        </View>
      ) : (
        sensor?.dev_eui
      ),
    [colors, isLoading, sensor]
  );

  return <DrawerHeader title={title} subtitle="Manage Sensor" />;
};

const styles = StyleSheet.create({
  placeholderContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  titlePlaceholder: {
    width: 100,
    height: 20,
    borderRadius: 2,
    marginRight: 4,
  },
});

export default SensorHeader;
