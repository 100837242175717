import { useCallback } from "react";
import * as React from "react";

import { ActionItem } from "@/common/ActionItem";
import { useDisableParkingMutation } from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

interface DisableParkingActionProps {
  groupId: number;
  onSuccess: () => void;
}

export const DisableParkingAction: React.FC<DisableParkingActionProps> = ({
  groupId,
  onSuccess,
}) => {
  const { confirm } = useDialog();
  const [disableParking] = useDisableParkingMutation();

  const handlePress = useCallback(async () => {
    const confirmed = await confirm({
      title: "Disable Parking Module?",
      confirmText: "Disable",
      confirmType: "destructive",
      description:
        "Disabling the parking module will archive the property in parking and they will not be able to access spaces, vehicles, etc...",
    });

    if (confirmed) {
      await disableParking({ groupId });
      onSuccess();
    }
  }, [confirm, disableParking, groupId, onSuccess]);

  return (
    <ActionItem
      onPress={handlePress}
      title="Disable Parking Module"
      color="error"
    />
  );
};
