import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useRef } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import { useWindowDimensionsQuery } from "@smartrent/hooks";
import {
  Form,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  PanelSeparator,
  Stack,
} from "@smartrent/ui";

import { useSerialsQueryStringFieldHook } from "@/pages/hub-refurbish/hooks/useSerialsQueryStringFieldHook";
import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

import { FormikRemovedHubField } from "@/pages/hub-refurbish/HubRefurbishRemovedHubsFormikField";
import { HubRefurbishStepsInfo } from "@/pages/hub-refurbish/HubRefurbishStepsInfo";
import { RedisStatsBatchErrorsList } from "@/pages/hub-refurbish/redis-stats/RedisStatsErrorsList";
import { RedisStatsFormikFieldError } from "@/pages/hub-refurbish/redis-stats/RedisStatsFormikFieldError";
import { RedisStatsFormikFieldOffline } from "@/pages/hub-refurbish/redis-stats/RedisStatsFormikFieldOffline";
import { RedisStatsFormikFieldOnline } from "@/pages/hub-refurbish/redis-stats/RedisStatsFormikFieldOnline";
import { RedisStatsVirtualGrid } from "@/pages/hub-refurbish/redis-stats/RedisStatsVirtualGrid";

import {
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
  HubRefurbishRedisStatsFormikValues,
  HubRefurbishStep,
} from "@/types";

import { HubRefurbishJobFormikFieldSuccesses } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobFormikFieldSuccesses";
import { FormikHubRefurbishJobErrorsField } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobFormikFieldErrors";
import { HubRefurbishJobVirtualGrid } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobVirtualGrid";
import { HubRefurbishJobErrorsList } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobErrorsList";
import { HubRefurbishRefetchButton } from "@/pages/hub-refurbish/grid/HubRefurbishRefetchButton";

import { HubRefurbishStepSerialsInput } from "./HubRefurbishStepSerialsInput";
import { HubRefurbishStepSubmit } from "./HubRefurbishStepSubmit";

export const hubSchema = Yup.object({
  serial: Yup.string(),
  isError: Yup.boolean().nullable(),
  box: Yup.object().nullable(),
  boxSessions: Yup.array().of(Yup.object()).nullable(),
  status: Yup.string(),
  error: Yup.object({
    name: Yup.string(),
    message: Yup.string(),
  }).nullable(),
});

const resetHubSchema = Yup.object({
  serial: Yup.string(),
  step: Yup.string(),
  stepName: Yup.string(),
  totalSteps: Yup.number(),
  progress: Yup.number(),
  isSuccess: Yup.boolean().nullable(),
  isError: Yup.boolean().nullable(),
  error: Yup.object({
    name: Yup.string(),
    message: Yup.string(),
  }).nullable(),
});

const hubOnlineSchema = Yup.object({
  [HubRefurbishFormikFieldNames.Serials]: Yup.string().required(
    "Please enter a batch of hub serial numbers"
  ),
  [HubRefurbishFormikFieldNames.Online]: Yup.array()
    .of(hubSchema)
    .nullable()
    .max(0, "Hubs cannot be online"),

  [HubRefurbishFormikFieldNames.Offline]: Yup.array()
    .of(hubSchema)
    .required()
    .min(1, "At least 1 hub must be offline to continue"),

  [HubRefurbishFormikFieldNames.Error]: Yup.array()
    .of(hubSchema)
    .max(0, "Remove error hubs to continue"),

  [HubRefurbishFormikFieldNames.Removed]: Yup.array().of(hubSchema).nullable(),
});

const hubResetStatusSchema = Yup.object({
  [HubRefurbishFormikFieldNames.Serials]: Yup.string().required(
    "Please enter a batch of hub serial numbers"
  ),
  [HubRefurbishFormikFieldNames.Success]: Yup.array()
    .of(resetHubSchema)
    .ensure()
    .required()
    .min(1, "Must be at least 1 successful hub to continue"),

  [HubRefurbishFormikFieldNames.JobErrors]: Yup.array()
    .of(resetHubSchema)
    .ensure()
    .nullable()
    .max(0, "Remove error hubs to continue"),

  [HubRefurbishFormikFieldNames.Removed]: Yup.number().nullable(),
});

export const HubRefurbishSteps: React.FC = () => {
  const { small } = useWindowDimensionsQuery();
  const { initialValues } = useSerialsQueryStringFieldHook();
  const { serials, step, isCriticalError } = useHubRefurbishContext();

  // focus the serials textarea input
  const innerRef = useRef();
  useEffect(() => {
    if (innerRef?.current) {
      const textarea = innerRef.current as HTMLTextAreaElement;
      const end = textarea.value.length;
      textarea.setSelectionRange(end, end);
      textarea.focus();
    }
  }, [innerRef]);

  // makes sure we don't have duplicate serial numbers
  const handleValidation = useCallback(({ serials: serialsVal }) => {
    if (!serialsVal || serialsVal === "" || typeof serialsVal !== "string") {
      return { serials: "Please enter a batch of hub serial numbers" };
    }

    const list = serialsVal
      .split("\n")
      .map((serial: string) => serial.trimStart().toUpperCase())
      .filter((serial) => serial !== "");

    // check for duplicates
    const listSet = new Set(list);
    if (list.length === listSet.size) {
      return {};
    }

    const duplicateSerialsStr = list
      .reduce(
        (duplicateSerials, value) =>
          duplicateSerials.includes(value)
            ? [...duplicateSerials, value]
            : duplicateSerials,
        []
      )
      .map((dup) => `\n\n- ${dup}`)
      .join("");

    return {
      serials: `Remove duplicate serial numbers: ${duplicateSerialsStr}`,
    };
  }, []);

  // formik submission
  const handleOnSubmit = useCallback(
    (values, formikHelpers: FormikHelpers<any>) => {
      formikHelpers.setSubmitting(true);
      formikHelpers.validateForm();
      // validateForm() updates the FormikContext .. isSubmitting=true
      // to finish the submission flow, we hook into the FormikContext
      // this is implemented inside the FormikStepOnePanelHeader component
    },
    []
  );

  return (
    <Formik<HubRefurbishRedisStatsFormikValues>
      validateOnChange
      validateOnBlur
      initialValues={initialValues}
      validate={handleValidation}
      onSubmit={handleOnSubmit}
      validationSchema={
        step === HubRefurbishStep.ResetHubs
          ? hubResetStatusSchema
          : hubOnlineSchema
      }
    >
      <View style={styles.grid}>
        <View style={styles.formContainer}>
          <Panel>
            <PanelHeader>
              <PanelHeaderTitle title="Refurbish Batch" />
            </PanelHeader>
            <PanelBody>
              <Form>
                <HubRefurbishStepSerialsInput
                  serials={serials}
                  innerRef={innerRef}
                  disabled={step > HubRefurbishStep.HubsOffline}
                />

                <HubRefurbishStepsInfo />
              </Form>
            </PanelBody>
          </Panel>
        </View>

        <View style={styles.gridPanelContainer}>
          <Panel>
            <HubRefurbishStepSubmit />

            {/* NETWORK CONNECTION STATUS LEGEND  */}
            {serials?.length && !isCriticalError ? (
              <PanelHeader
                style={styles.networkStatusLegend}
                EndAdornment={<HubRefurbishRefetchButton />}
              >
                <Stack
                  spacing={24}
                  direction={small ? "column" : "row"}
                  wrap
                  style={styles.stack}
                >
                  {step === HubRefurbishStep.ResetHubs ? (
                    <>
                      <HubRefurbishJobFormikFieldSuccesses />
                      <FormikHubRefurbishJobErrorsField />
                    </>
                  ) : (
                    <>
                      <RedisStatsFormikFieldOnline />
                      <RedisStatsFormikFieldOffline required={true} />
                      <RedisStatsFormikFieldError />
                    </>
                  )}

                  <FormikRemovedHubField
                    queryKey={HubRefurbishReactQueryKeys.RedisStats}
                  />
                </Stack>
              </PanelHeader>
            ) : null}

            {/* HUB GRID */}
            {serials?.length ? (
              <PanelBody>
                <View>
                  {step === HubRefurbishStep.ResetHubs ? (
                    <HubRefurbishJobVirtualGrid />
                  ) : (
                    <RedisStatsVirtualGrid />
                  )}
                  {/* ERROR DETAILS */}
                  {step !== HubRefurbishStep.ResetHubs ? (
                    <RedisStatsBatchErrorsList />
                  ) : !isCriticalError ? (
                    <View>
                      <PanelSeparator />
                      <HubRefurbishJobErrorsList />
                    </View>
                  ) : null}
                </View>
              </PanelBody>
            ) : null}
          </Panel>
        </View>
      </View>
    </Formik>
  );
};

const styles = StyleSheet.create({
  grid: {
    marginTop: 24,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },

  formContainer: { marginRight: 16 },

  gridPanelContainer: { flexGrow: 1, flexShrink: 2, maxWidth: "80%" },

  networkStatusLegend: {
    alignItems: "flex-start",
    padding: 0,
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 8,
  },
  stack: {
    justifyContent: "flex-start",
    minHeight: 92,
  },
});
