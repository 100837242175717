import { useCallback } from "react";
import * as React from "react";
import { StyleProp, ViewStyle, StyleSheet, View } from "react-native";

import { Button } from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { Panel } from "@/common/panel";
import NoRecords from "@/common/NoRecords";
import { useDialog } from "@/lib/contexts/dialog";
import { useEnableParkingMutation } from "@/api";

interface EnableParkingPanelProps {
  groupId: number;
  propertyId: string;
  style?: StyleProp<ViewStyle>;
}

export const EnableParkingPanel: React.FC<EnableParkingPanelProps> = ({
  groupId,
  propertyId,
  style,
}) => {
  const { permissions } = useUser();
  const { confirm } = useDialog();

  const [enableParking] = useEnableParkingMutation();

  const handlePress = useCallback(async () => {
    const confirmed = await confirm({
      title: `Enable Parking Module?`,
      confirmText: "Enable",
      description:
        "Enabling parking module will give the property access to manage spaces and vehicles.",
    });

    if (confirmed) {
      await enableParking({ groupId });
    }
  }, [groupId, enableParking, confirm]);

  return (
    <Panel title="Enable Parking" style={[styles.root, style]}>
      {permissions.manage_parking ? (
        <View style={styles.container}>
          <Button onPress={handlePress}> Enable</Button>
        </View>
      ) : (
        <NoRecords title="Parking is not enabled." />
      )}
    </Panel>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 300,
  },
  container: { margin: 24 },
});
