import { useState, useContext, useCallback } from "react";
import {
  map,
  startCase,
  filter,
  groupBy,
  without,
  includes,
  uniq,
  sum,
} from "lodash-es";
import classNames from "classnames";
import { View, StyleSheet } from "react-native";

import { Pill, Typography, Link, useTheme } from "@smartrent/ui";
import { ExclamationCircleOutline } from "@smartrent/icons";

import Helpers from "@/lib/helpers";
import Context from "@/layout/Context";
import { useUnit } from "@/pages/unit/UnitContext";
import { formatDate, formatDateToBeHumanReadable } from "@/utils";

import { WorkflowProps } from "../types";

import Circle from "./Circle";
import HoverText from "./HoverText";

function Stats({ stats }: { stats: Record<symbol, unknown> }) {
  return (
    <div className="u-mtop8 u-mbottom16 u-flex u-flex-wrap">
      {map(groupBy(stats, "type"), (groupedStats, type) => {
        const total = sum(map(groupedStats, ({ count }) => Number(count)));
        return (
          <div
            className="u-mtop12 u-text-gray-800 u-mright8 u-p8 u-flex-grow1"
            key={type}
            style={{ border: "1px solid #eee" }}
          >
            <div className="u-mright4" style={{ width: "225px" }}>
              <span className="u-sans-bold">{startCase(type)}</span>
              {map(groupedStats, ({ count, status }) => {
                let friendlyStatus = startCase(status);
                switch (status) {
                  case "started":
                    friendlyStatus = "Running";
                    break;

                  case "stopped":
                    friendlyStatus = "Exceptions";
                    break;
                  default:
                    break;
                }
                return (
                  <div
                    key={`${status}-${type}`}
                    className="u-flex u-flex-wrap u-flex-start u-mleft4"
                  >
                    <div
                      className={classNames("u-mright8", {
                        "u-text-symbolic-success": status === "completed",
                        "u-text-symbolic-knowledge": status === "started",
                        "u-text-gray-600": status === "canceled",
                        "u-text-symbolic-error": status === "stopped",
                      })}
                    >
                      {friendlyStatus}
                    </div>
                    <div className="u-mright8">
                      {Number(count).toLocaleString()}
                    </div>
                    <div className="u-text-gray-300">
                      {((Number(count) / total) * 100).toFixed(1)}%
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

type Props = {
  stats?: any; // @todo,
  showFilters: boolean;
  workflows: WorkflowProps[];
};

export default function Workflows({
  workflows,
  stats,
  showFilters = false,
}: Props) {
  const { colors } = useTheme();

  const { user, match } = useContext(Context);

  const { unit } = useUnit();

  const isOnUnitPage = match?.path?.includes("/units") ?? false;

  // Don't show Hub Offline stuck workflows by default
  const [filters, setFilters] = useState(
    showFilters ? [] : uniq(map(workflows, "cause"))
  );

  const triggeredByLabel = useCallback((triggered_by) => {
    if (!triggered_by) {
      return null;
    }

    switch (triggered_by) {
      case "venterra_import":
      case "automated_sync":
        return "by AMIMO";
      case "authorized_installer":
        return "by Authorized Installer";
      case "staff":
        return "by Staff";
      default:
        return "Unknown";
    }
  }, []);

  if (!(workflows && workflows.length)) {
    return null;
  }

  const causes = groupBy(workflows, "cause");

  return (
    <View>
      {stats && <Stats stats={stats} />}

      {showFilters && (
        <View style={[styles.filter, { borderTopColor: colors.gray100 }]}>
          {map(causes, (workflows, cause) => {
            return (
              <Pill
                style={styles.filterButtons}
                textStyle={
                  includes(filters, cause)
                    ? styles.filterButtonOn
                    : styles.filterButtonOff
                }
                variation={includes(filters, cause) ? "default" : "outlined"}
                key={cause}
                onPress={() =>
                  includes(filters, cause)
                    ? setFilters(without(filters, cause))
                    : setFilters([...filters, cause])
                }
                backgroundColor={colors.white}
                textColor={colors.gray600}
                borderColor={colors.white}
              >
                {`${cause} (${workflows.length})`}
              </Pill>
            );
          })}
        </View>
      )}

      {map(
        filter(workflows, (workflow) => {
          return includes(filters, workflow.cause);
        }),
        (
          {
            id,
            location,
            cause,
            // jobs,
            workflow_type,
            triggered_by,
            unit_id,
            resident_id,
            resident_name,
            inserted_at,
            access_code_activation_id,
            status,
            created_by_name,
            created_by_id,
          },
          idx
        ) => {
          let color;

          switch (status) {
            case "started":
              color = Helpers.colors.blue;
              break;
            case "stopped":
              color = Helpers.colors.red;
              break;
            case "completed":
              color = Helpers.colors.green;
              break;
            case "canceled":
              color = Helpers.colors.gray;
              break;
            default:
              color = Helpers.colors.red;
              break;
          }

          return (
            <View
              key={idx}
              style={[styles.logContainer, { borderTopColor: colors.gray100 }]}
            >
              <View style={styles.logRow}>
                <View>
                  {!isOnUnitPage && (
                    <Typography type="title5">
                      <Link
                        target="_blank"
                        href={`/units/${unit_id}`}
                        style={styles.logLink}
                      >
                        {location}
                      </Link>
                    </Typography>
                  )}
                  <View>
                    <Typography style={styles.logTextPrimary}>
                      <Circle color={color} />
                      {workflow_type}{" "}
                      {created_by_id ? (
                        <>
                          {"by "}
                          <Link
                            href={`/users/${created_by_id}`}
                            style={styles.logLink}
                            target="_blank"
                          >
                            {created_by_name}
                          </Link>
                        </>
                      ) : (
                        triggeredByLabel(triggered_by)
                      )}
                      {!isOnUnitPage ? ` - ${resident_name}` : null}
                    </Typography>
                  </View>

                  {status !== "completed" ? (
                    <View>
                      <Typography
                        type="caption"
                        style={[
                          styles.logTextWarning,
                          { color: colors.warning500 },
                        ]}
                      >
                        {cause}
                      </Typography>
                    </View>
                  ) : null}
                </View>
                <View>
                  <View style={styles.logTimestampRow}>
                    <Typography
                      style={[
                        styles.logTimestampRowText,
                        { color: colors.gray600 },
                      ]}
                    >
                      {formatDateToBeHumanReadable({ date: inserted_at })}{" "}
                    </Typography>
                    <HoverText
                      color={colors.gray700}
                      tooltipText={formatDate({
                        date: inserted_at,
                        pattern: "PPPP p z",
                        timeZone: unit?.timezone ?? "UTC",
                      })}
                      tooltipDirection="right"
                      width={250}
                      hoverElement={
                        <ExclamationCircleOutline
                          style={styles.logTimestampRowIcon}
                          size={10}
                          color={colors.gray700}
                        />
                      }
                    />
                  </View>
                </View>
              </View>

              {user?.permissions?.view_technical_details && (
                <View style={styles.technicalDetailsContainer}>
                  <Typography
                    type="caption"
                    style={[
                      styles.technicalDetailText,
                      { color: colors.warning800 },
                    ]}
                  >
                    workflow id: {id}
                  </Typography>
                  {!isOnUnitPage && (
                    <Typography
                      type="caption"
                      style={[
                        styles.technicalDetailText,
                        { color: colors.warning800 },
                      ]}
                    >
                      resident id: {resident_id}
                    </Typography>
                  )}
                  {!isOnUnitPage && (
                    <Typography
                      type="caption"
                      style={[
                        styles.technicalDetailText,
                        { color: colors.warning800 },
                      ]}
                    >
                      unit id: {unit_id}
                    </Typography>
                  )}

                  <Typography
                    type="caption"
                    style={[
                      styles.technicalDetailText,
                      { color: colors.warning800 },
                    ]}
                  >
                    aca id: {access_code_activation_id || "-"}
                  </Typography>
                </View>
              )}
            </View>
          );
        }
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  filter: {
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderTopWidth: 1,
  },
  filterButtons: {
    margin: 1,
    paddingRight: 8,
    paddingLeft: 6,
  },
  filterButtonOn: {
    fontWeight: "600",
  },
  filterButtonOff: {
    fontWeight: "normal",
  },

  logContainer: {
    paddingTop: 4,
    paddingBottom: 4,
    borderTopWidth: 1,
    justifyContent: "space-between",
  },
  logRow: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logTextPrimary: {
    lineHeight: 16,
    fontSize: 14,
  },
  logLink: {
    lineHeight: 16,
    textDecorationLine: "underline",
    fontSize: 12,
  },
  logTextWarning: {
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 4,
  },
  logTimestampRow: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  logTimestampRowText: {
    lineHeight: 16,
    fontWeight: "normal",
    fontSize: 10,
  },
  logTimestampRowIcon: {
    marginLeft: 4,
  },

  technicalDetailsContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
  },
  technicalDetailText: {
    lineHeight: 16,
    fontWeight: "normal",
    fontSize: 11,
    marginRight: 4,
  },
});
