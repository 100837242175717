import * as React from "react";
import { View, StyleSheet } from "react-native";

import { DrawerHeader, Typography } from "@smartrent/ui";

import { ZipatoAccountResponse } from "@/types";
import { useUser } from "@/layout/Context";

import { ListValue } from "@/common/ListValue";
import { Spoiler } from "@/common/Spoiler";
import NoRecords from "@/common/NoRecords";
import { CopyText } from "@/common/CopyText";

export interface ZipatoAccountProps {
  zipato?: ZipatoAccountResponse;
}

export const ZipatoAccount: React.FC<ZipatoAccountProps> = ({ zipato }) => {
  const { permissions } = useUser();

  return (
    <>
      <DrawerHeader title="Zipato Account" />
      {!zipato ? (
        <View style={styles.noRecordsContainer}>
          <NoRecords title="Zipato Account Not Found" />
        </View>
      ) : (
        <View style={styles.container}>
          <ListValue label="Environment" value={zipato.zipato_environment} />

          <ListValue label="Realm ID" value={zipato.realm_id} />

          <ListValue
            label="Redirect Server ID"
            value={zipato.redirect_server_id}
          />

          <ListValue label="Zipato Hub ID" value={zipato.zipato_hub_id} />

          <ListValue label="Zipato User ID" value={zipato.zipato_user_id} />

          {permissions.hub_extended_details ? (
            <>
              {zipato?.hubAccount ? (
                <View style={styles.bottomPadding}>
                  <Typography color="textSecondary">
                    Registered To Hub Account (attached):
                  </Typography>
                  <View style={styles.copyContainer}>
                    <Typography
                      color={
                        zipato?.hubAccount?.attached ? "textPrimary" : "error"
                      }
                      style={styles.value}
                    >
                      {zipato?.hubAccount?.attached ? "Yes" : "No"}
                    </Typography>
                  </View>
                </View>
              ) : null}
              {zipato?.hubAccount?.zipato_username ? (
                <View style={styles.bottomPadding}>
                  <Typography color="textSecondary">Zipato Username</Typography>
                  <View style={styles.copyContainer}>
                    <Typography style={styles.value}>
                      {zipato.hubAccount.zipato_username}
                    </Typography>
                    <CopyText text={zipato.hubAccount.zipato_username} />
                  </View>
                </View>
              ) : null}
              {zipato?.hubAccount?.zipato_password ? (
                <View style={styles.bottomPadding}>
                  <Typography color="textSecondary">
                    Zipato User Password
                  </Typography>
                  <Spoiler label="Click to reveal password">
                    <View style={styles.copyContainer}>
                      <Typography style={styles.value}>
                        {zipato.hubAccount.zipato_password}
                      </Typography>
                      <CopyText text={zipato.hubAccount.zipato_password} />
                    </View>
                  </Spoiler>
                </View>
              ) : null}
            </>
          ) : null}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  noRecordsContainer: {
    flexGrow: 1,
    justifyContent: "center",
  },
  value: { fontWeight: "bold", marginRight: 8 },
  bottomPadding: { marginBottom: 16 },
  copyContainer: { flexDirection: "row", alignItems: "center" },
});
