import { useCallback } from "react";
import * as React from "react";

import { StyleSheet, View } from "react-native";

import {
  Accordion,
  AccordionPanel,
  HStack,
  MessageCard,
  Panel,
  PanelHeader,
  Typography,
  useAccordionState,
  useTheme,
  VStack,
} from "@smartrent/ui";

import { Email } from "@smartrent/icons";

import { ResidentProps } from "@/types";

import { useEmailWebhooksAxiosQuery } from "@/api/email-webhooks";

import { formatDate } from "@/utils";

import { useIsDesktop } from "@/hooks/breakpoints";

import { WebhookLabel } from "./WebhookLabel";

interface EmailWebhooksProps {
  email: ResidentProps["email"];
}

export const EmailWebhooks: React.FC<EmailWebhooksProps> = ({ email }) => {
  const { spacing } = useTheme();
  const isDesktop = useIsDesktop();

  const emailWebhooksQuery = useEmailWebhooksAxiosQuery(
    {
      email: email,
      params: { limit: 25, sortBy: "inserted_at", orderBy: "DESC" },
    },
    {
      enabled: !!email,
    }
  );

  const accordionProps = useAccordionState({ allowMultiple: true });

  const isWebhookPanelOpen = useCallback(
    (index) => accordionProps.expandedPanels.includes(index),
    [accordionProps?.expandedPanels]
  );

  const getChipColor = useCallback((event) => {
    if (!event) {
      return "grayscale";
    }
    switch (event.toLowerCase()) {
      case "open":
      case "click":
      case "delivered":
        return "success";
      case "bounce":
      case "dropped":
        return "error";
      default:
        return "grayscale";
    }
  }, []);

  const emptyMessage = "(no subject)";

  return (
    <Panel
      style={
        isDesktop
          ? [
              styles.root,
              {
                marginRight: spacing.md,
                marginTop: spacing.md,
                marginBottom: spacing.md,
              },
            ]
          : { margin: spacing.sm }
      }
    >
      <PanelHeader>
        <VStack spacing={spacing.xs}>
          <HStack spacing={spacing.xs} align="center">
            <Email size={32} />
            <Typography type="title2">
              Email Webhooks{" "}
              {emailWebhooksQuery?.data?.total_records
                ? `(${emailWebhooksQuery.data.total_records})`
                : null}
            </Typography>
          </HStack>
          <Typography color="mutedTextPrimary" type="title4">
            {email}
          </Typography>
        </VStack>
      </PanelHeader>
      <View>
        {!emailWebhooksQuery?.data?.records?.length ? (
          <MessageCard heading="No Webhooks Found" message="" />
        ) : (
          <Accordion {...accordionProps} style={{ padding: spacing.xxs }}>
            {emailWebhooksQuery.data.records.map((webhook, index) => {
              return (
                <AccordionPanel
                  key={`email-webhook-${webhook.id}-${index}`}
                  title={
                    <WebhookLabel
                      isPanelExtended={isWebhookPanelOpen(index)}
                      chipColor={getChipColor(webhook.event)}
                      chipIcon={(props) => <Email {...props} size={20} />}
                      chipText={webhook.event}
                      messageEmpty={emptyMessage}
                      message={webhook.subject}
                      inserted_at={webhook.inserted_at}
                      recipient={webhook.email}
                    />
                  }
                >
                  <VStack spacing={spacing.md} style={styles.offset}>
                    <Typography type="bodySemibold">
                      {webhook?.subject ? webhook.subject : emptyMessage}
                    </Typography>

                    <Typography type="bodySmall">
                      <Typography type="bodySmallSemibold">
                        Details:{" "}
                      </Typography>
                      {webhook.response}
                    </Typography>

                    <Typography type="captionSmallSemibold">
                      webhook_created_at:{" "}
                      {formatDate({ date: webhook.inserted_at })}
                    </Typography>
                  </VStack>
                </AccordionPanel>
              );
            })}
          </Accordion>
        )}
      </View>
    </Panel>
  );
};

const styles = StyleSheet.create({
  root: { marginLeft: 0 },
  offset: { marginTop: 0 },
});
