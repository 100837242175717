import { GroupWithOrganizationProps } from "./GroupProps";
import { OrganizationProps } from "./OrganizationProps";
import { UserProps } from "./UserProps";

export enum ScopeOfWorkStatus {
  Draft = "draft",
  ReadyForReview = "ready_for_review",
  Submitted = "submitted",
  Revision = "revision",
  Syncing = "syncing",
  Error = "error",
  Completed = "completed",
}

export enum ScopeOfWorkDetailCellQuality {
  Bad = "bad",
  Okay = "okay",
  Good = "good",
  Excellent = "excellent",
}

export enum ScopeOfWorkDeviceCategory {
  Thermostats = "thermostat",
  "Light Switches" = "light-switches",
  Locks = "locks",
  Outlets = "outlet",
  Hubs = "hub",
  Sensors = "sensor",
  "Professional Services" = "professional-services",
  Other = "other",
}

export type ScopeOfWorkDeviceCategoryOption = {
  label: keyof typeof ScopeOfWorkDeviceCategory | string;
  value:
    | (typeof ScopeOfWorkDeviceCategory)[keyof typeof ScopeOfWorkDeviceCategory]
    | string;
};

export interface ScopeOfWorkProps {
  id: string;
  name: string;
  status: ScopeOfWorkStatus;
  ready_for_review_at: string;
  submitted_at: string;
  completed_at: string;
  updated_at: string;
  inserted_at: string;
  group: Partial<GroupWithOrganizationProps>;
  organization: Partial<OrganizationProps>;
  user?: Partial<UserProps>;
  sf_opportunity_id?: string;
  scope_of_work_detail?: ScopeOfWorkDetailProps;
  scope_of_work_devices?: ScopeOfWorkDeviceProps[];
  scope_of_work_devices_order?: ScopeOfWorkDeviceCategory[];
  opportunity_property_id?: string;
  uuid: string;
  passcode?: string;
  show_pricing: boolean;
}

export interface ScopeOfWorkDetailProps {
  id: string;
  scope_of_work_id: string;
  updated_at: string;
  inserted_at: string;

  units_count: string;
  floors_count: string;
  floor_plans_count: string;
  phases_count: string;
  ada_count: string;
  units_surveyed_count: string;
  has_secondary_entrances: string;
  has_multiple_tstats: string;
  has_multiple_heaters: string;
  cell_quality: string;
}

export interface ScopeOfWorkRevision {
  id: string;
  scope_of_work_device_id: string;
  updated_at: string;
  inserted_at: string;
  user_id: string;
  notes: string;
  status: string;
  origin: string;
}

export interface ScopeOfWorkImageProps {
  id: string;
  scope_of_work_device_id: string;
  inserted_at: string;
  url: string;
}

export interface ScopeOfWorkDeviceProps {
  id: string;
  scope_of_work_id: string;
  updated_at: string;
  inserted_at: string;

  sf_device_sku: string;
  sf_product_id: string;
  sf_quote_line_item_id: string;
  sf_device_category: string;
  notes: string;
  quantity: number;
  is_accepted: boolean;
  accepted_at: string;
  deleted_at: string;

  scope_of_work_revision: ScopeOfWorkRevision[];
  scope_of_work_images: ScopeOfWorkImageProps[];
}

export type ScopeOfWorkDeviceDictionary = Record<
  ScopeOfWorkDeviceProps["sf_device_category"],
  ScopeOfWorkDeviceProps[]
>;

export interface ScopeOfWorkTemplate {
  id: string;
  name: string;
}
