import { createContext, useContext, useState } from "react";
import * as React from "react";

import { HubPrepLog } from "../../types";

interface HubPrepContext {
  hubPrepLogs: HubPrepLog[];
  setHubPrepLogs: (hubPrepLogs: HubPrepLog[]) => void;
  loading: boolean;
  setLoading: (bool: boolean) => void;
}

const HubPrepContext = createContext<HubPrepContext>(undefined);

export const useHubPrep = () => useContext(HubPrepContext);

interface HubPrepProps {}

export const HubPrepProvider: React.FC<HubPrepProps> = ({ children }) => {
  const [hubPrepLogs, setHubPrepLogs] = useState<HubPrepLog[]>();
  const [loading, setLoading] = useState(false);

  return (
    <HubPrepContext.Provider
      value={{ hubPrepLogs, setHubPrepLogs, loading, setLoading }}
    >
      {children}
    </HubPrepContext.Provider>
  );
};
