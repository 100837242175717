import { ComponentType } from "react";
import { StyleProp, ViewStyle } from "react-native";

export const FlagIcon = ({
  Flag,
}: {
  Flag: ComponentType<{ size: number; style?: StyleProp<ViewStyle> }>;
}) => {
  return <Flag size={24} />;
};
