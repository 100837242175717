import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import * as React from "react";

import { StyleSheet } from "react-native";

import { useParams } from "react-router";

import { StatusBannerProvider, useQueryStringField } from "@smartrent/ui";

import { HubRefurbishStep, RefurbishHub, RefurbishHubDict } from "@/types";

export interface HubRefurbishContext {
  // the current step we are on
  step: HubRefurbishStep;

  // unique id for the HubRefurbishBatchJob bullMQ flow
  flowId: string | null;
  setFlowId: React.Dispatch<React.SetStateAction<string | null>>;
  // count of hubs in a flow's job
  flowHubsCount: number | null;
  setFlowHubsCount: React.Dispatch<React.SetStateAction<number | null>>;

  // data structure for keeping tack of hub/s within any of the steps
  hubsDict: Record<string, RefurbishHub>;
  setHubsDict: React.Dispatch<
    React.SetStateAction<Record<string, RefurbishHub>>
  >;
  updateHub: (
    serial: string,
    props: Partial<RefurbishHub>,
    callback?: any
  ) => void;
  updateHubs: (
    serials: string[],
    props: Partial<RefurbishHub>,
    callback?: any
  ) => void;
  updateAllHubs: (iterator: (serial: string) => Partial<RefurbishHub>) => void;

  // convenience props for filtering / mapping over hubsDict data
  serials: RefurbishHub["serial"][];
  activeSerials: RefurbishHub["serial"][];
  removedSerials: RefurbishHub["serial"][];
  disabledSerials: RefurbishHub["serial"][];

  // critical error means factory reset is unexpectedly failing
  // this will most likely require engineering team to investigate datadog logs
  isCriticalError: boolean;
  setIsCriticalError: React.Dispatch<React.SetStateAction<boolean>>;

  getHubs: (filter: (hub: RefurbishHub) => boolean) => RefurbishHub[];
}

const HubRefurbishContext = createContext<HubRefurbishContext>(
  {} as HubRefurbishContext
);

export const useHubRefurbishContext = () => useContext(HubRefurbishContext);

interface HubRefurbishProviderProps {
  search?: string;
}
export const HubRefurbishProvider: React.FC<HubRefurbishProviderProps> = ({
  children,
}) => {
  // keeping track of job ID for querying status on step 2
  const flowIdQueryStringField = useQueryStringField("flowId");
  const flowHubsCountQueryStringField = useQueryStringField("flowHubsCount");
  const [flowId, setFlowId] = useState<string | null>(
    flowIdQueryStringField || null
  );
  const [flowHubsCount, setFlowHubsCount] = useState<number>(
    parseInt(flowHubsCountQueryStringField) || 0
  );

  // Keeping track of serial numbers typed into textarea input
  const [hubsDict, setHubsDict] = useState<RefurbishHubDict>({});
  const serials = useMemo(() => Object.keys(hubsDict), [hubsDict]);
  const [isCriticalError, setIsCriticalError] = useState<boolean>(false);

  const { step: stepParam } = useParams<{ step: string }>();
  const step = useMemo(() => {
    if (stepParam === null) {
      return null;
    }
    switch (stepParam) {
      case "offline":
        return HubRefurbishStep.HubsOffline;
      case "reset-hubs":
        return HubRefurbishStep.ResetHubs;
      case "online-w-sd":
        return HubRefurbishStep.HubsOnlineWithSD;
      case "procure-hubs":
        return HubRefurbishStep.ProcureHubs;
      default:
        return HubRefurbishStep.Home; // home path
    }
  }, [stepParam]);

  // used when clicking the (x) button to remove hubs
  const updateHub = useCallback(
    (serial: string, props: Partial<RefurbishHub>) => {
      if (typeof serial === "string" && hubsDict[serial]) {
        const updateMap = Object.fromEntries([
          [serial, { ...hubsDict[serial], ...props }],
        ]);
        setHubsDict({ ...hubsDict, ...updateMap });
      }
    },
    [hubsDict]
  );

  // use when clicking (x) button on a formik field to remove list of serials
  const updateHubs = useCallback(
    (serials: string[], props: Partial<RefurbishHub>) => {
      const updateMap = Object.fromEntries(
        serials.map((serial) => [serial, { ...hubsDict[serial], ...props }])
      );
      setHubsDict({ ...hubsDict, ...updateMap });
    },
    [hubsDict]
  );

  // use when clicking (x) button on REMOVED field to 'restore' hubs to current step
  const updateAllHubs = useCallback(
    (iterator) => {
      const updateMap = Object.fromEntries(
        Object.entries(hubsDict).map(([key, val]) => [
          key,
          { ...val, ...iterator(val) },
        ])
      );
      setHubsDict({ ...hubsDict, ...updateMap });
    },
    [hubsDict]
  );

  /** HUB SERIAL NUMBERS */
  const {
    active: activeSerials,
    removed: removedSerials,
    disabled: disabledSerials,
  } = useMemo(
    () =>
      Object.entries(hubsDict).reduce(
        (serials, [serial, val]) => {
          if (val?.isDisabled === true || /disabled/gi.test(serial) === true) {
            serials.disabled.push(serial);
            return serials;
          }
          if (val?.isToBeRemoved === true) {
            serials.removed.push(serial);
            return serials;
          }

          serials.active.push(serial);
          return serials;
        },
        {
          active: [],
          removed: [],
          disabled: [],
        }
      ),
    [hubsDict]
  );

  // get list of hubs.. where disabled & removed are filtered out by default
  const getHubs = useCallback(
    (filter) => {
      return Object.entries(hubsDict || {}).reduce(
        (hubs, [serial, hub]) =>
          hub?.isDisabled !== true && hub?.isToBeRemoved !== true && filter(hub)
            ? [...hubs, hub]
            : hubs,
        []
      );
    },
    [hubsDict]
  );

  return (
    <HubRefurbishContext.Provider
      value={{
        step,

        flowId,
        setFlowId,
        flowHubsCount,
        setFlowHubsCount,

        hubsDict,
        setHubsDict,

        isCriticalError,
        setIsCriticalError,

        updateHub,
        updateHubs,
        updateAllHubs,

        serials,

        // serials can be 'active', temporarily 'removed' or permanently 'disabled' for a single batch
        activeSerials,
        removedSerials,
        disabledSerials,

        getHubs,
      }}
    >
      <StatusBannerProvider placement="top" style={styles.statusBanner}>
        {children}
      </StatusBannerProvider>
    </HubRefurbishContext.Provider>
  );
};

const styles = StyleSheet.create({
  statusBanner: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    lineHeight: 24,
  },
});
