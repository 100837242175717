import { useState, useEffect, useCallback, useMemo } from "react";
import * as React from "react";
import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from "react-native";
import { map, isEmpty, startCase } from "lodash-es";

import {
  ActivityIndicator,
  Typography,
  FlatListItemSeparator,
} from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { Card, CardHeader, CardBody } from "@/common/card/index";

import { useRedisStatsQuery } from "@/api/zipato";
import { valueToHumanReadableString } from "@/utils/human-readable-values";

import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";
import { NoContent } from "@/pages/hub-page/NoContent";

interface BoxContentProps {
  data: any;
}

const BoxContent: React.FC<BoxContentProps> = ({ data }) => {
  const boxSession = useMemo(() => {
    const boxSessions = data?.boxSessions ?? [];
    return boxSessions.length
      ? map(boxSessions[0], (value, key) => ({ key, value })).filter(
          (item) => !["serverId", "remoteAddr", "sessionId"].includes(item.key)
        )
      : [];
  }, [data]);

  const box = useMemo(() => {
    return data?.box
      ? map(data.box, (value, key) => ({ key, value })).filter(
          (item) => !["serverId", "remoteAddr", "sessionId"].includes(item.key)
        )
      : [];
  }, [data]);

  return isEmpty(data?.boxSessions) ? (
    <NoContent />
  ) : (
    <View>
      <Typography type="title3">Box</Typography>
      <FlatList
        data={box}
        keyExtractor={({ key }) => key}
        renderItem={({ item }) => (
          <ListItemLabelValueCompact
            label={startCase(item.key)}
            value={
              item.key !== "firmware"
                ? valueToHumanReadableString(item.value, true)
                : item.value
            }
          />
        )}
        ItemSeparatorComponent={FlatListItemSeparator}
      />

      <Typography type="title3" style={styles.label}>
        Box Session
      </Typography>
      <FlatList
        data={boxSession}
        keyExtractor={({ key }) => key}
        renderItem={({ item }) => (
          <ListItemLabelValueCompact
            label={startCase(item.key)}
            value={valueToHumanReadableString(item.value, true)}
          />
        )}
        ItemSeparatorComponent={FlatListItemSeparator}
      />
    </View>
  );
};

export interface BoxSessionProps {
  hubSerial: string;
  style?: StyleProp<ViewStyle>;
}

export const BoxSessionCard: React.FC<BoxSessionProps> = ({
  hubSerial,
  style,
}) => {
  const [initiallyClicked, setInitiallyClicked] = useState(false);
  const [ableToRefresh, setAbleToRefresh] = useState(true);

  const { data, isFetching, isLoading, refetch, isError, isRefetchError } =
    useRedisStatsQuery({ serial: hubSerial }, { enabled: false });

  useEffect(() => {
    if (!isFetching) {
      window.setTimeout(() => {
        setAbleToRefresh(true);
      }, 1500);
    }
  }, [isLoading, isFetching]);

  const fetch = useCallback(() => {
    setInitiallyClicked(true);
    setAbleToRefresh(false);
    refetch();
  }, [refetch]);

  return (
    <Card style={style}>
      <CardHeader>
        <Typography type="title2">Box Session</Typography>
        {ableToRefresh ? (
          <TouchableOpacity onPress={fetch}>
            <Refresh size={24} />
          </TouchableOpacity>
        ) : (
          <ActivityIndicator size={25} />
        )}
      </CardHeader>

      <CardBody>
        {!isLoading && !isFetching && initiallyClicked && ableToRefresh ? (
          <>
            {isError || isRefetchError || data?.boxSessions === null ? (
              <NoContent isError />
            ) : (
              <BoxContent data={data} />
            )}
          </>
        ) : (
          <NoContent />
        )}
      </CardBody>
    </Card>
  );
};

const styles = StyleSheet.create({
  label: {
    marginTop: 8,
  },
});
