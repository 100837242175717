import { useCallback } from "react";
import * as React from "react";
import { View } from "react-native";

import { DrawerLoader } from "@smartrent/ui";

import { useSensorQuery, useUnassignSensorMutation } from "@/api";
import { ActionItem } from "@/common/ActionItem";
import { useUser } from "@/layout/Context";

import { useDrawerNav } from "@/common/AppDrawer";
import { useDialog } from "@/lib/contexts/dialog";

import SensorHeader from "./SensorHeader";

interface SensorActionsProps {
  sensorId: string;
  propertyId: string;
}

const SensorActions: React.FC<SensorActionsProps> = ({
  sensorId,
  propertyId,
}) => {
  const { permissions } = useUser();
  const drawer = useDrawerNav();
  const { confirm } = useDialog();

  const { data: sensor, isLoading: loadingSensor } = useSensorQuery(sensorId);

  const [deactivateSensor, { isLoading: deactivatingSensor }] =
    useUnassignSensorMutation();

  const handleActivity = useCallback(
    () =>
      drawer.push("sensorActivity", {
        sensorId,
        propertyId,
        navBackTitle: "Back to Manage Sensor",
      }),
    [drawer, propertyId, sensorId]
  );

  const handleMigrate = useCallback(
    () =>
      drawer.push("migrateSensor", {
        sensorId,
        propertyId,
        navBackTitle: "Back to Manage Sensor",
      }),
    [drawer, propertyId, sensorId]
  );

  const handleDeactivate = useCallback(async () => {
    const confirmed = await confirm({
      title: "Deactivate Sensor?",
      confirmText: "Deactivate",
      confirmType: "destructive",
      description: `The sensor will be removed from the ${sensor.active_space.space_number} space. This space's sensor event history will still exist.`,
    });

    if (confirmed && sensor && sensor.active_space) {
      await deactivateSensor({
        sensorId: sensor.id,
        spaceId: sensor.active_space.id,
      });

      drawer.pop();
    }
  }, [confirm, deactivateSensor, drawer, sensor]);

  return (
    <>
      <SensorHeader sensorId={sensorId} />
      {loadingSensor ? (
        <DrawerLoader />
      ) : (
        <View>
          {permissions.manage_parking && !sensor.active_space ? (
            <ActionItem onPress={handleMigrate} title="Migrate" color="error" />
          ) : null}
          {sensor.active_space ? (
            <>
              {permissions.manage_parking ? (
                <ActionItem
                  onPress={handleDeactivate}
                  disabled={deactivatingSensor}
                  title="Deactivate"
                  color={deactivatingSensor ? "border" : "error"}
                />
              ) : null}
              <ActionItem
                onPress={handleActivity}
                title="Activity"
                color="primary"
              />
            </>
          ) : null}
        </View>
      )}
    </>
  );
};

export default SensorActions;
