import { useCallback } from "react";
import * as React from "react";

import { StyleSheet, View } from "react-native";

import {
  Accordion,
  AccordionPanel,
  Blockquote,
  HStack,
  MessageCard,
  Panel,
  PanelHeader,
  Typography,
  useAccordionState,
  useTheme,
  VStack,
} from "@smartrent/ui";

import { Messages } from "@smartrent/icons";

import { useSmsWebhooksQuery } from "@/api";

import { ResidentProps } from "@/types";

import { formatDate, redactLinks } from "@/utils";

import { useIsDesktop } from "@/hooks/breakpoints";

import { WebhookLabel } from "./WebhookLabel";

interface SmsWebhooksProps {
  phone_number: ResidentProps["phone_number"];
}
export const SmsWebhooks: React.FC<SmsWebhooksProps> = ({ phone_number }) => {
  const isDesktop = useIsDesktop();
  const { spacing, colors } = useTheme();

  const webhooksQuery = useSmsWebhooksQuery(
    {
      phoneNumber: phone_number,
      params: { limit: 25, sortBy: "inserted_at", orderBy: "DESC" },
    },
    {
      enabled: !!phone_number,
    }
  );

  const accordionProps = useAccordionState({ allowMultiple: true });

  const isWebhookPanelOpen = useCallback(
    (index) => accordionProps.expandedPanels.includes(index),
    [accordionProps?.expandedPanels]
  );

  const getChipColor = useCallback((status) => {
    if (!status) {
      return "grayscale";
    }
    switch (status.toLowerCase()) {
      case "delivered":
        return "success";
      case "undelivered":
        return "error";
      case "sent":
        return "info";
      default:
        return "grayscale";
    }
  }, []);

  const emptyMessage = "Message body unavailable";

  return (
    <Panel style={isDesktop ? { margin: spacing.md } : { margin: spacing.sm }}>
      <PanelHeader>
        <VStack spacing={spacing.xs}>
          <HStack spacing={spacing.xs} align="center">
            <Messages size={32} />
            <Typography type="title2">
              Sms Webhooks{" "}
              {webhooksQuery?.data?.total_records
                ? `(${webhooksQuery.data.total_records})`
                : null}
            </Typography>
          </HStack>
          <Typography color="mutedTextPrimary" type="title4">
            {phone_number}
          </Typography>
        </VStack>
      </PanelHeader>

      <View>
        {!webhooksQuery?.data?.records?.length ? (
          <MessageCard heading="No Webhooks Found" message="" />
        ) : (
          <Accordion {...accordionProps} style={{ padding: spacing.xxs }}>
            {webhooksQuery.data.records.map((webhook, index) => {
              return (
                <AccordionPanel
                  key={`sms-webhook-${webhook.remote_id}-${index}`}
                  title={
                    <WebhookLabel
                      isPanelExtended={isWebhookPanelOpen(index)}
                      chipColor={getChipColor(webhook.status)}
                      chipIcon={(props) => <Messages {...props} size={20} />}
                      chipText={webhook.status}
                      messageEmpty={emptyMessage}
                      message={redactLinks(webhook.body)}
                      inserted_at={webhook.inserted_at}
                      recipient={webhook.phone_number}
                    />
                  }
                >
                  <VStack spacing={spacing.xl} style={styles.offset}>
                    <Blockquote style={{ borderLeftColor: colors.gray200 }}>
                      <Typography type="body">
                        {webhook?.body
                          ? redactLinks(webhook.body)
                          : emptyMessage}
                      </Typography>
                    </Blockquote>
                    <Typography type="captionSmallSemibold">
                      webhook_created_at:{" "}
                      {formatDate({ date: webhook.inserted_at })}
                    </Typography>
                  </VStack>
                </AccordionPanel>
              );
            })}
          </Accordion>
        )}
      </View>
    </Panel>
  );
};
const styles = StyleSheet.create({
  offset: { marginTop: 0 },
});
