import { useCallback } from "react";
import * as React from "react";
import { TouchableOpacity } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";
import { TrashX } from "@smartrent/icons";

import { useDeleteSignMutation } from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

import { LoadingDots } from "@/common/LoadingDots";

interface ArchiveSignActionProps {
  signId: string;
  propertyId: string;
  onSuccess?: () => void;
  variation?: "plain" | "icon";
}

export const ArchiveSignAction: React.FC<ArchiveSignActionProps> = ({
  signId,
  propertyId,
  onSuccess,
  variation = "icon",
}) => {
  const { colors } = useTheme();
  const { confirm } = useDialog();

  const [deleteSign, { isLoading: isDeleting }] = useDeleteSignMutation();

  const handleDelete = useCallback(async () => {
    const confirmed = await confirm({
      title: "Archive Sign",
      confirmType: "destructive",
      confirmText: "Archive",
      description:
        "Warning: you are going to be archiving this sign and its space associations. The guest parking experience for this sign will be deactivated.",
    });

    if (confirmed) {
      await deleteSign({ signId, propertyId });
      onSuccess ? onSuccess() : null;
    }
  }, [signId, propertyId, deleteSign, confirm]);

  return variation === "icon" ? (
    <TouchableOpacity onPress={handleDelete}>
      {isDeleting ? <LoadingDots /> : <TrashX color={colors.error} />}
    </TouchableOpacity>
  ) : (
    <TouchableOpacity onPress={handleDelete}>
      {isDeleting ? (
        <LoadingDots />
      ) : (
        <Typography color="error" type="title5">
          ARCHIVE
        </Typography>
      )}
    </TouchableOpacity>
  );
};
