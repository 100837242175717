import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { useField } from "formik";

import {
  SelectField,
  SelectOption,
  TextInputFieldHelperText,
  useTheme,
} from "@smartrent/ui";

import { useOrganizationInfiniteQuery } from "@/api";
import { useDebounce } from "@/lib/hooks";
import { LoadingDots } from "@/common/LoadingDots";

export interface FormikAsyncOrganizationFieldProps {
  name: string;
  label: string;
  required?: boolean;
}

const FormikAsyncOrganizationField: React.FC<
  FormikAsyncOrganizationFieldProps
> = ({ name, label, required }) => {
  const [textInput, setTextInput] = useState("");
  const debouncedTextInput = useDebounce(textInput, 500);
  const [value, setValue] = useState<SelectOption | undefined>(undefined);
  const [, meta, helpers] = useField(name);
  const { colors } = useTheme();

  const { reducedData, isFetching, hasNextPage, fetchNextPage } =
    useOrganizationInfiniteQuery({
      name: debouncedTextInput,
      limit: 50,
    });

  const options = useMemo(
    () =>
      reducedData.map((organization) => ({
        value: organization.id,
        label: organization.name,
      })),
    [reducedData]
  );

  const handleEndReached = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const handleChange = useCallback(
    (option: SelectOption) => {
      setValue(option);
      helpers.setValue(option?.value ?? undefined);
    },
    [helpers]
  );

  return (
    <>
      <SelectField
        name={name}
        label={label}
        onChange={handleChange}
        options={options}
        value={value}
        required={required}
        StartAdornment={isFetching ? () => <LoadingDots color="gray" /> : null}
        onTextInputChange={setTextInput}
        flatListProps={{ onEndReached: handleEndReached }}
      />
      <TextInputFieldHelperText color={colors.error}>
        {meta.error}
      </TextInputFieldHelperText>
    </>
  );
};

export default FormikAsyncOrganizationField;
