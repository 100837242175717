import * as React from "react";
import { View, Text, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { IconProps } from "@smartrent/icons";

export interface StatusPillProps {
  type: "success" | "warning" | "error" | "info" | "gray";
  icon?: React.ComponentType<IconProps>;
  style?: StyleProp<ViewStyle>;
}

export const StatusPill: React.FC<StatusPillProps> = ({
  type,
  children,
  icon: Icon,
  style,
}) => {
  const { color, backgroundColor } = colorPallet[type];

  return (
    <View style={[styles.container, style, { backgroundColor }]}>
      {Icon ? <Icon color={color} size={11} style={styles.icon} /> : null}
      <Text style={[styles.text, { color }]}>{children}</Text>
    </View>
  );
};

// These colors are not in SmartRent-UI, and they are specifically more "muted" in tone.
const colorPallet = {
  success: {
    color: "#0A8930",
    backgroundColor: "rgba(10, 137, 48, 0.12)",
  },
  warning: {
    color: "#F3AB47",
    backgroundColor: "rgba(243, 171, 71, 0.12)",
  },
  error: {
    color: "#E3273F",
    borderColor: "#E3273F",
    backgroundColor: "rgba(227, 39, 63, 0.12)",
  },
  info: {
    color: "#039BE5",
    backgroundColor: "rgba(3, 155, 229, 0.12)",
  },
  gray: {
    color: "#6A6E79",
    backgroundColor: "#EBEEF4",
  },
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  icon: {
    marginRight: 8,
  },
  text: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "600", // this has to be quoted, according to StyleSheet.. obviously.
  },
});
