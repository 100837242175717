import { useCallback, useMemo } from "react";
import * as React from "react";

import { ActionItem } from "@/common/ActionItem";
import { useToggleParkingMapModule } from "@/api";
import { ParkingProperty } from "@/types";
import { useDialog } from "@/lib/contexts/dialog";
import { useDrawerNav } from "@/common/AppDrawer";

interface Props {
  property: ParkingProperty;
  onSuccess: () => void;
}

export const ToggleMapModuleAction: React.FC<Props> = ({
  property,
  onSuccess,
}) => {
  const { confirm } = useDialog();
  const drawer = useDrawerNav();

  const [toggleParkingMap] = useToggleParkingMapModule();

  const enabled = useMemo(
    () => property?.maps_module_enabled ?? false,
    [property]
  );

  const title = useMemo(
    () => `${enabled ? "Disable" : "Enable"} Parking Maps Module`,
    [enabled]
  );

  const color = useMemo(() => (enabled ? "error" : "primary"), [enabled]);

  const handlePress = useCallback(async () => {
    if (enabled) {
      const confirmed = await confirm({
        title: "Disable Parking Maps Module",
        confirmText: "Disable",
        confirmType: "destructive",
        description:
          "Are you sure? Disabling this will disable all parking maps at this community.",
      });

      if (confirmed) {
        await toggleParkingMap({
          property: {
            maps_module_enabled: false,
            id: property.id,
            map_id: null,
          },
        });
        onSuccess();
      }
    } else {
      drawer.push("enableParkingMaps", { propertyId: property.id });
    }
  }, [enabled, confirm, toggleParkingMap, property, onSuccess, drawer]);

  return <ActionItem onPress={handlePress} title={title} color={color} />;
};
