import * as React from "react";

import { PanelHeaderTitle } from "@smartrent/ui";

import CardHeading from "@/common/CardHeading";

interface WhiteLabelConfigHeaderProps {
  headingPrefix?: React.ReactNode;
  headingSuffix?: React.ReactNode;
}

export const WhiteLabelConfigHeader: React.FC<WhiteLabelConfigHeaderProps> = ({
  headingPrefix,
  headingSuffix,
}) => {
  return (
    <CardHeading>
      {headingPrefix}
      <PanelHeaderTitle title="ResApp Rebranding" />
      {headingSuffix}
    </CardHeading>
  );
};
