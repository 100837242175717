import { useEffect, useMemo } from "react";
import * as React from "react";
import { View, Text, StyleSheet } from "react-native";
import { HStack, VStack, Typography } from "@smartrent/ui";

import Helpers from "@/lib/helpers";
import { transformMessage, getColor } from "@/utils/hubLogs";

import { useHubLogs } from "@/api";

import Circle from "../Circle";

import { HubDebugTitleBar } from "./HubDebugTitleBar";

type Type = "info" | "success" | "error" | "warning";

interface HubLogProps {
  filters: Record<string, any>;
  onFetch?: (logs: Record<string, any>[]) => void;
}

function Log({
  children,
  type,
  tip,
}: {
  children: React.ReactNode;
  type: Type;
  tip?: string;
}) {
  return (
    <HStack align="center">
      <Circle size={8} color={getColor(type)} />
      <VStack>
        <Text>{children}</Text>
        {tip ? (
          <Typography type="caption" font="light">
            {tip}
          </Typography>
        ) : null}
      </VStack>
    </HStack>
  );
}

export function HubLog({ message, timestamp, hubType }) {
  const { label, type, tip } = useMemo(() => {
    return transformMessage(message, hubType);
  }, [message, hubType]);

  return (
    <VStack style={styles.log}>
      <HStack wrap={true} align="center" style={styles.logDetails}>
        <Log type={type} tip={tip}>
          {label}
        </Log>
        <Typography type="caption" style={styles.timestamp} font="light">
          {Helpers.formatDate(timestamp)}
        </Typography>
      </HStack>
    </VStack>
  );
}

export default function HubLogs({ filters, onFetch }: HubLogProps) {
  const { data, isLoading, isFetching, isError, isFetched, refetch } =
    useHubLogs(filters);

  useEffect(() => {
    if (onFetch && data && isFetched) {
      onFetch(data);
    }
  }, [onFetch, isFetched, data]);

  if (!(filters && (filters.remote_id || filters.serial))) {
    return null;
  }

  return (
    <View style={styles.logContainer}>
      <HubDebugTitleBar
        title="Logs"
        isLoading={isLoading || isFetching}
        onReloadClicked={refetch}
      />

      {isLoading ? <Typography>Loading...</Typography> : null}
      {isError ? <Typography>Error loading logs</Typography> : null}

      {(data?.length ? data : []).map(({ message, timestamp }, idx) => (
        <HubLog
          message={String(message)}
          timestamp={String(timestamp)}
          hubType={filters.type}
          key={String(idx)}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  logContainer: {
    marginTop: 8,
    paddingHorizontal: 8,
    maxHeight: 300,
    overflowY: "auto",
  },
  log: {
    marginBottom: 4,
  },
  logDetails: {
    justifyContent: "space-between",
  },
  timestamp: {
    flexGrow: 1,
    flexShrink: 1,
    alignSelf: "flex-end",
  },
});
