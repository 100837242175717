import * as React from "react";
import { Link } from "react-router-dom";
import { get, startCase } from "lodash-es";

import CardHeading from "@/common/CardHeading";

import { instance } from "@/lib/hooks";
import Helpers from "@/lib/helpers";

import { useGroup } from "../../GroupContext";

interface GroupDetailsHeaderProps {
  headingPrefix?: React.ReactNode;
  headingSuffix?: React.ReactNode;
  thirdLevel?: React.ReactNode;
}

export const GroupDetailsHeader: React.FC<GroupDetailsHeaderProps> = ({
  headingPrefix,
  headingSuffix,
  thirdLevel,
}) => {
  const { group, organization } = useGroup();

  return (
    <CardHeading>
      <div className="u-flex u-flex-align-items-baseline">
        {headingPrefix}
        <div className="u-mright4 u-line12">
          <Link to={`/organizations/${organization.id}`} className="a">
            <div>{organization.name}</div>
          </Link>
        </div>
        <div className="u-mright4"> / </div>
        {thirdLevel ? (
          <>
            <Link to={`/groups/${group.id}`} className="a">
              {startCase(group.marketing_name)}
            </Link>
            <div className="u-mleft4 u-mright4"> / </div>
            <div className="u-mright4">{thirdLevel}</div>
          </>
        ) : (
          <div className="u-mright4">{startCase(group.marketing_name)}</div>
        )}

        {headingSuffix}
      </div>
    </CardHeading>
  );
};

export const groupDetailsHeaderActions = ({
  group,
  context,
  permissions,
  groupCommunityWifi,
  handleUpdateGroupActionClick,
  handleLinkIntegrationClick,
}) => {
  const actions = [];

  const canUpdateGroup = get(permissions, "update_group");

  if (canUpdateGroup) {
    actions.push({
      label: "Update Group Settings",
      onSubmit: handleUpdateGroupActionClick,
    });
  }

  if (permissions.download_first_response_codes) {
    actions.push({
      label: "Download First Responder Codes",
      modal: {
        title: "Download Codes",
        confirmText: "Download",
        body: (
          <div>
            Download all confirmed first responder codes? If the records for the
            unit are missing, usually this means that the codes have not been
            applied and something could have gone wrong during installation.
          </div>
        ),
        formik: {
          onSubmit: () => {
            return instance
              .get(`/groups/${group.id}/units/emergency-codes`, {
                params: {
                  type: "first_responder",
                },
              })
              .then((data) => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message: "Your download should automatically happen.",
                });

                Helpers.downloadFile(
                  "confirmed_emergency_access_codes.csv",
                  data.data
                );
              })
              .catch(() => {
                context.setToast(Helpers.unknownErrorToast);
              });
          },
        },
      },
    });

    if (groupCommunityWifi?.enabled) {
      actions.push({
        label: "Apply WiFi Device Network",
        modal: {
          title: "Apply WiFi Device Network To All Hubs",
          confirmText: "Confirm",
          body: (
            <div>
              <div>
                This action will attempt to apply WiFi Device Network to all
                hubs where we haven't already confirmed that the hub is running
                on WiFi Device Network.
              </div>
              <div>
                This process will likely take 10+ minutes and it may fail on
                hubs before a certain firmware version, hubs that are offline,
                and potentially hubs that are already on a different WiFi
                network.
              </div>
            </div>
          ),
          formik: {
            onSubmit: () => {
              return instance
                .post(`/groups/${group.id}/hubs/apply-community-wifi`)
                .then(({ data }) => {
                  context.setToast({
                    type: "success",
                    title: "Success",
                    message: `WiFi Device Network is being applied to ${data.count} hubs. Please wait 10+ minutes`,
                  });
                })
                .catch(() => {
                  context.setToast(Helpers.unknownErrorToast);
                });
            },
          },
        },
      });
    }
  }

  if (permissions.id_integration_manager) {
    actions.push({
      label: "Link Integration",
      onSubmit: handleLinkIntegrationClick,
    });
  }

  return actions;
};
