import { useCallback } from "react";
import * as React from "react";
import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import { DrawerHeader } from "@smartrent/ui";

import { useCreateVendorApplicationMutation } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import {
  VendorApplicationForm,
  VendorApplicationFormValues,
} from "@/common/vendor-applications/drawer/VendorApplicationForm";

interface CreateVendorApplicationProps {
  organizationId: number;
}
export const CreateVendorApplication: React.FC<
  CreateVendorApplicationProps
> = ({ organizationId }) => {
  const drawer = useDrawerNav();
  const history = useHistory();

  const [createVendorApplication] = useCreateVendorApplicationMutation();

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);

  const handleSubmit = useCallback(
    async (
      values: VendorApplicationFormValues,
      actions: FormikHelpers<VendorApplicationFormValues>
    ) => {
      actions.setSubmitting(true);

      const { id } = await createVendorApplication({ organizationId, values });

      actions.setSubmitting(false);

      history.push(
        `/organizations/${organizationId}/vendor-applications/${id}`
      );
    },
    [organizationId, createVendorApplication, history]
  );

  return (
    <>
      <DrawerHeader title="Create Vendor Application" />
      <VendorApplicationForm
        organizationId={organizationId}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
