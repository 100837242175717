import * as React from "react";
import { PanelHeaderTitle } from "@smartrent/ui";

import Helpers from "@/lib/helpers";
import { instance } from "@/lib/hooks";

import CardHeading from "@/common/CardHeading";

interface ParkingHeaderProps {
  headingPrefix?: React.ReactNode;
  headingSuffix?: React.ReactNode;
}

export const ParkingHeader: React.FC<ParkingHeaderProps> = ({
  headingPrefix,
  headingSuffix,
}) => {
  return (
    <CardHeading>
      {headingPrefix}
      <PanelHeaderTitle title="Parking Module" />
      {headingSuffix}
    </CardHeading>
  );
};

export const parkingHeaderActions = ({
  group,
  context,
  permissions,
  parkingEnabled,
  parkingGroup,
  setParkingEnabled,
}: any) => {
  const actions = [];

  if (permissions.manage_parking && parkingEnabled) {
    actions.push({
      label: "Disable Parking Module",
      modal: {
        title: "Disable Parking Module",
        confirmText: "Disable",
        body: <div>Are you sure you want to disable the parking module?</div>,
        formik: {
          onSubmit: () => {
            return instance
              .post(`/groups/${group.id}/parking/disable`)
              .then(() => {
                setParkingEnabled(false);
                context.setToast({
                  type: "success",
                  title: "Success",
                  message:
                    "Parking module successfully disabled. Please refresh the page.",
                });
              })
              .catch((err) => {
                console.error(err);
                context.setToast(Helpers.unknownErrorToast);
              });
          },
        },
      },
    });

    actions.push({
      label: "Import Parking Users",
      modal: {
        title: "Import Parking Users",
        confirmText: "Import",
        body: (
          <div>
            Are you sure you want to import parking users? This will create
            missing parking user records for any residents that are moved in and
            have an account.
          </div>
        ),
        formik: {
          onSubmit: () => {
            return instance
              .post(`/groups/${group.id}/parking/users`)
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message: "Parking users successfully imported.",
                });
              })
              .catch((err) => {
                console.error(err);
                context.setToast(Helpers.unknownErrorToast);
              });
          },
        },
      },
    });

    const decalModuleEnabled = parkingGroup.decal_module_enabled;
    const decalModuleToggleTitle = `${
      decalModuleEnabled ? "Disable" : "Enable"
    } Decals Module`;
    const decalModuleAction = decalModuleEnabled ? "disable" : "enable";

    actions.push({
      label: decalModuleToggleTitle,
      modal: {
        title: decalModuleToggleTitle,
        confirmText: decalModuleEnabled ? "Disable" : "Enable",
        body: (
          <div>
            Are you sure you want to {decalModuleAction} the parking decals
            module?
          </div>
        ),
        formik: {
          onSubmit: () => {
            return instance
              .patch(`/parking/properties/${group.uuid}`, {
                decal_module_enabled: !decalModuleEnabled,
              })
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message: `Parking Decal Module ${
                    decalModuleEnabled ? "Disabled" : "Enabled"
                  }. Please refresh the page.`,
                });
              })
              .catch((err) => {
                console.error(err);
                context.setToast(Helpers.unknownErrorToast);
              });
          },
        },
      },
    });

    const guestModuleEnabled = parkingGroup.guest_parking_module_enabled;
    const guestModuleToggleTitle = `${
      guestModuleEnabled ? "Disable" : "Enable"
    } Guest Parking Module`;
    const guestModuleAction = guestModuleEnabled ? "disable" : "enable";

    actions.push({
      label: guestModuleToggleTitle,
      modal: {
        title: guestModuleToggleTitle,
        confirmText: guestModuleEnabled ? "Disable" : "Enable",
        body: (
          <div>
            Are you sure you want to {guestModuleAction} the guest parking
            module?
          </div>
        ),
        formik: {
          onSubmit: () => {
            (guestModuleEnabled
              ? instance.delete(
                  `/parking/properties/${group.uuid}/guest-parking`
                )
              : instance.post(`/parking/properties/${group.uuid}/guest-parking`)
            )
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message: `Parking Guest Module ${
                    guestModuleEnabled ? "Disabled" : "Enabled"
                  }. Please refresh the page.`,
                });
              })
              .catch((err) => {
                console.error(err);
                context.setToast(Helpers.unknownErrorToast);
              });
          },
        },
      },
    });
  }

  return actions;
};
