import { useCallback, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { FormikHelpers } from "formik";

import { AnimatedPlaceholder, DrawerHeader, useTheme } from "@smartrent/ui";

import { useCreateSignMutation, useGroupQuery } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import { SignFormValues, SignForm } from "@/common/parking/drawer/SignForm";

interface CreateSignProps {
  groupId: number;
}
export const CreateSign: React.FC<CreateSignProps> = ({ groupId }) => {
  const { colors } = useTheme();
  const drawer = useDrawerNav();

  const { data: group, isLoading } = useGroupQuery(groupId);

  const [createSign] = useCreateSignMutation();

  const title = useMemo(
    () =>
      isLoading ? (
        <View style={styles.placeholderContainer}>
          <AnimatedPlaceholder
            style={[
              styles.titlePlaceholder,
              { backgroundColor: colors.gray100 },
            ]}
          />
        </View>
      ) : (
        group?.marketing_name ?? "\u2014"
      ),
    [group]
  );

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);

  const handleSubmit = useCallback(
    async (values: SignFormValues, actions: FormikHelpers<SignFormValues>) => {
      actions.setSubmitting(true);
      const { id } = await createSign({ propertyId: group.uuid, values });

      actions.setSubmitting(false);

      drawer.push("signDetail", { signId: id, propertyId: group.uuid });
    },
    [createSign, handleCancel, group]
  );

  return (
    <>
      <DrawerHeader title={title} subtitle="Create Sign" />
      <SignForm
        propertyId={group?.uuid}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </>
  );
};

const styles = StyleSheet.create({
  placeholderContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  titlePlaceholder: {
    width: 100,
    height: 20,
    borderRadius: 2,
    marginRight: 4,
  },
});
