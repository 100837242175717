import { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { create } from "zustand";

import * as Yup from "yup";

import { Formik } from "formik";

import {
  Button,
  Form,
  FormikSubmit,
  FormikTextareaField,
  Typography,
  VStack,
  useStatusBanner,
  useTheme,
} from "@smartrent/ui";
import { ExclamationCircleOutline, Flag } from "@smartrent/icons";

import { useUser } from "@/layout/Context";

import {
  FlagTaskRequest,
  useCreateFlagTask,
  useUpdateFlagTask,
} from "@/api/tasks";
import { TaskFlag } from "@/types";
import { useGetJob } from "@/api";

import Modal from "../../modal/Modal";

type UseTaskFlagModal = {
  isOpen: boolean;
  taskId: string | null;
  taskName: string;
  taskFlagId: string | null;
  lastTaskFlag: TaskFlag | null;
  reason: string;
  close(): void;
  openCreateModal(
    taskId: string,
    taskName: string,
    lastTaskFlag: TaskFlag | null
  ): void;
  openEditModal(taskFlagId: string, reason: string): void;
};

type TaskFlagModalProps = {
  jobId: string;
};

const schema = Yup.object().shape({
  reason: Yup.string().required(),
});

export const useTaskFlagModal = create<UseTaskFlagModal>((set) => ({
  isOpen: false,
  taskId: null,
  taskName: "",
  taskFlagId: null,
  lastTaskFlag: null,
  reason: "",
  close: () => set(() => ({ isOpen: false })),
  openCreateModal: (taskId, taskName, lastTaskFlag) =>
    set(() => ({
      isOpen: true,
      taskFlagId: null,
      taskId,
      taskName,
      reason: "",
      lastTaskFlag,
    })),
  openEditModal: (taskFlagId, reason) =>
    set(() => ({ isOpen: true, taskFlagId, reason })),
}));

export function TaskFlagModal({ jobId }: TaskFlagModalProps) {
  const { colors } = useTheme();
  const { data: job }: any = useGetJob(jobId);
  const { mutateAsync } = useCreateFlagTask();
  const { addStatusBanner, clearStatusBanner } = useStatusBanner();
  const { email } = useUser();

  const { close, taskId, reason, isOpen, taskFlagId, lastTaskFlag, taskName } =
    useTaskFlagModal();
  const { mutateAsync: updateFlagTaskMutate } = useUpdateFlagTask(taskFlagId);

  const onSubmit = useCallback(
    async (values) => {
      if (taskFlagId !== null) {
        await updateFlagTaskMutate(
          { reason: values.reason },
          {
            onSuccess: () => close(),
            onError: (error: any) => {
              console.log(error?.response?.data);
            },
          }
        );

        return false;
      }

      if (taskId === null || email === null) {
        return false;
      }

      const payload: FlagTaskRequest = {
        reason: values.reason,
        task_id: taskId,
        created_by_email: email,
        job_id: job.id,
        project_id: null,
      };

      if (job?.project_id) {
        payload.project_id = job.project_id;
      }

      await mutateAsync(payload, {
        onSuccess: () => close(),
        onError: (error: any) => {
          console.log(error?.response?.data);
          addStatusBanner({
            title: "Could not create task flag. Try again.",
            numberOfLines: 1,
            icon: ExclamationCircleOutline,
            status: "error",
            actions: [
              {
                label: "DISMISS",
                onPress: () => clearStatusBanner(),
              },
            ],
          });
        },
      });

      return false;
    },
    [
      mutateAsync,
      close,
      taskId,
      taskFlagId,
      updateFlagTaskMutate,
      addStatusBanner,
      clearStatusBanner,
      email,
      job?.project_id,
      job?.id,
    ]
  );

  return (
    <Modal titleId="flag-task-modal" isOpen={isOpen} onClose={close}>
      <Modal.Header style={{ borderBottomColor: colors.border }}>
        <Typography style={styles.title}>
          <Flag color={colors.error} />
          Flag Task: {taskName}
        </Typography>
      </Modal.Header>
      <Modal.Content scrollable={true}>
        <VStack>
          <Typography style={styles.instructions} numberOfLines={3}>
            Flagging a job task will mark the task, "Flagged" and notify all
            workers assigned to the related job and project.
          </Typography>

          {lastTaskFlag ? (
            <>
              <Typography>
                Last Flagged By: {lastTaskFlag.created_by_email}
              </Typography>
              <Typography>"{lastTaskFlag.reason}"</Typography>
              <Typography>
                Last Completed By: {lastTaskFlag.completed_by_email}
              </Typography>
            </>
          ) : null}

          <Formik
            initialValues={{ reason }}
            onSubmit={onSubmit}
            validationSchema={schema}
            enableReinitialize
          >
            <Form>
              <View style={styles.textareaContainer}>
                <FormikTextareaField
                  name="reason"
                  label="Reason"
                  assistiveText="Please describe the issue that needs to be resolved"
                  textInputProps={{
                    multiline: true,
                    style: styles.textarea,
                  }}
                />
              </View>

              <FormikSubmit
                textStyle={styles.button}
                label={taskFlagId ? "Update" : "Submit"}
              />
              <Button
                textStyle={styles.button}
                variation="plain"
                onPress={close}
              >
                Cancel
              </Button>
            </Form>
          </Formik>
        </VStack>
      </Modal.Content>
    </Modal>
  );
}

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    fontSize: 20,
  },
  instructions: {
    marginVertical: 16,
    width: 500,
  },
  textareaContainer: {
    marginVertical: 16,
  },
  textarea: {
    flex: 1,
    minHeight: 250,
  },
  button: {
    fontSize: 16,
  },
});
