import { useMemo } from "react";
import * as React from "react";
import { ListItemContainer, ListItemLabelValue } from "@smartrent/ui";

import { JobCommentProps } from "@/types";
import { formatDate } from "@/utils";

interface JobCommentListItemProps {
  item: JobCommentProps;
}
export const JobCommentListItem: React.FC<JobCommentListItemProps> = ({
  item,
}) => {
  const name = useMemo(
    () => `${item.created_by.first_name} ${item.created_by.last_name}`.trim(),
    [item.created_by.first_name, item.created_by.last_name]
  );
  const modifierText = useMemo(() => {
    const d = formatDate({
      date: item.updated_at,
      pattern: "P p",
    });
    const edited = item.updated_at !== item.inserted_at ? " (edited)" : "";
    return `${d}${edited}`;
  }, [item.inserted_at, item.updated_at]);

  return (
    <ListItemContainer>
      <ListItemLabelValue
        label={name}
        modifier={modifierText}
        value={item.comment}
        numberOfLines={100}
      />
    </ListItemContainer>
  );
};
