import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { DrawerBackButton, DrawerLoader, LegacyFlatList } from "@smartrent/ui";

import { useSensorEventsInfiniteQuery } from "@/api";
import SensorHeader from "@/common/parking/drawer/SensorHeader";
import { useDrawerNav } from "@/common/AppDrawer";
import { ParkingSensorEvent } from "@/types";
import NoRecords from "@/common/NoRecords";

import SensorActivityItem from "./SensorActivityItem";

interface Props {
  sensorId: string;
  propertyId: string;
  navBackTitle?: string;
}

const SensorActivityList: React.FC<Props> = ({
  sensorId,
  propertyId,
  navBackTitle,
}) => {
  const drawer = useDrawerNav();

  const { reducedData, isFetching, hasNextPage, fetchNextPage } =
    useSensorEventsInfiniteQuery(sensorId, {
      property_id: propertyId,
      dir: "desc",
    });

  const handleEndReached = useCallback(
    () => (hasNextPage ? fetchNextPage() : null),
    [fetchNextPage, hasNextPage]
  );

  return (
    <>
      <SensorHeader sensorId={sensorId} />
      {navBackTitle ? (
        <DrawerBackButton onPress={drawer.pop}>{navBackTitle}</DrawerBackButton>
      ) : null}
      {isFetching ? (
        <DrawerLoader />
      ) : (
        <LegacyFlatList<ParkingSensorEvent>
          title="Sensor Activity"
          data={reducedData}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => <SensorActivityItem event={item} />}
          onEndReached={handleEndReached}
          ListEmptyComponent={
            <View style={styles.noRecords}>
              <NoRecords title="No Sensor Activity" />
            </View>
          }
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  noRecords: { marginTop: 16 },
});

export default SensorActivityList;
