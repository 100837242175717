import * as React from "react";
import { View } from "react-native";

import { Typography } from "@smartrent/ui";

import CardHeading from "@/common/CardHeading";

import { useOrganization } from "../OrganizationContext";

interface OrganizationInfoHeaderProps {
  headingPrefix?: React.ReactNode;
  headingSuffix?: React.ReactNode;
}

const OrganizationInfoHeader: React.FC<OrganizationInfoHeaderProps> = ({
  headingPrefix,
  headingSuffix,
}) => {
  const { organization } = useOrganization();

  return (
    <CardHeading>
      {headingPrefix}
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Typography style={{ marginRight: 16 }} type="title3">
          {organization.name}
        </Typography>
      </View>
      {headingSuffix}
    </CardHeading>
  );
};

export default OrganizationInfoHeader;
