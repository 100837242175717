import * as React from "react";
import { StyleSheet } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  DrawerContent,
  DrawerHeader,
  Typography,
  VStack,
  useTheme,
  FormikTextareaField,
  FormikSubmit,
  HStack,
} from "@smartrent/ui";

import { useSubmitRevisionNotesMutation } from "@/api";

import { ScopeOfWorkRevision } from "@/types";

import { formatDateTime } from "@/utils";
import { useDrawerNav } from "@/common/AppDrawer";

interface ScopeOfWorkRevisionProps {
  revisions: ScopeOfWorkRevision[];
  scope_of_work_id: string;
  device_sku: string;
  scope_of_work_device_id: string;
}

export const ScopeOfWorkRevisions: React.FC<ScopeOfWorkRevisionProps> = ({
  scope_of_work_id,
  scope_of_work_device_id,
  device_sku,
  revisions,
}) => {
  const sortedRevision = revisions.sort(
    (a, b) =>
      new Date(a.inserted_at).getTime() - new Date(b.inserted_at).getTime()
  );
  const { colors } = useTheme();
  const { pop } = useDrawerNav();

  const [submitRevisionNotes] = useSubmitRevisionNotesMutation();

  const submitNote = async (values) => {
    await submitRevisionNotes({
      scope_of_work_id,
      scope_of_work_device_id,
      notes: values.notes,
    });
    pop();
  };

  return (
    <>
      <DrawerHeader title="Revision Notes" subtitle={device_sku} />

      <DrawerContent>
        <VStack
          spacing={16}
          justify="space-between"
          style={styles.contentHeight}
        >
          <VStack spacing={16}>
            {sortedRevision.map((revision, index) => {
              const isSmrt = revision.origin === "smrt";
              return (
                <VStack
                  key={index}
                  spacing={4}
                  style={[
                    styles.wrapper,

                    {
                      alignSelf: isSmrt ? "flex-end" : "auto",
                    },
                  ]}
                >
                  <Typography
                    type="caption"
                    style={{
                      alignSelf: isSmrt ? "flex-end" : "auto",
                    }}
                  >
                    {isSmrt ? "You " : "Client "}
                    {formatDateTime(revision.inserted_at)}
                  </Typography>
                  <Box
                    style={{
                      backgroundColor: isSmrt ? colors.primary : "",
                    }}
                  >
                    <Typography
                      color={isSmrt ? "textPrimaryInverse" : "textPrimary"}
                    >
                      {revision.notes}
                    </Typography>
                  </Box>
                </VStack>
              );
            })}
          </VStack>
        </VStack>
      </DrawerContent>
      <Formik
        initialValues={{ notes: "" }}
        onSubmit={(values) => submitNote(values)}
        validationSchema={Yup.object({
          notes: Yup.string().required("Please enter a note"),
        })}
      >
        <VStack spacing={16} style={styles.padding}>
          <FormikTextareaField label="Add a Note" name="notes" />

          <HStack justify="space-between">
            <Button onPress={pop} variation="plain">
              Close
            </Button>
            <FormikSubmit label="Send" />
          </HStack>
        </VStack>
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  contentHeight: { height: "100%", marginBottom: 206 },
  wrapper: { width: "80%" },
  padding: {
    padding: 16,
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
  },
});
