import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { useTheme } from "@smartrent/ui";

export interface CardProps {
  header?: React.ReactChild;
  style?: StyleProp<ViewStyle>;
}

export const Card: React.FC<CardProps> = ({ children, style }) => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.container,
        css.shadow,
        style,
        { backgroundColor: colors.background },
      ]}
    >
      {children}
    </View>
  );
};

const css = {
  shadow: {
    borderColor: "#ebeef4",
    boxShadow: "0px 4px 4px rgba(45, 50, 57, 0.08)",
  },
} as any;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    borderRadius: 4,
    borderWidth: 1,
  },
});
