import { useCallback, useState } from "react";
import * as React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { SelectField } from "@smartrent/ui";

import { SalesForceAccount } from "@/types";
import { selectQueryPropsSalesforceAccounts } from "@/api";

interface SalesforceAccountSelectProps {
  initialValue?: SalesForceAccount;
  value?: SalesForceAccount;
  setValue: (value: SalesForceAccount) => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const SalesforceAccountSelect: React.FC<
  SalesforceAccountSelectProps
> = ({
  initialValue,
  value: account,
  setValue: setAccount,
  disabled = false,
  style,
}) => {
  const [accountFieldDirty, setAccountFieldDirty] = useState(false);

  const onChangeAccount = useCallback(
    (value) => {
      setAccountFieldDirty(true);
      setAccount(value || { Id: "", Name: "" });
    },
    [setAccount]
  );

  return (
    <SelectField<SalesForceAccount>
      label="Salesforce Account"
      name="salesforce_account_id"
      value={accountFieldDirty ? account : (initialValue as SalesForceAccount)}
      onChange={onChangeAccount}
      getOptionValue={(opp) => (opp?.Id ? opp.Id : "")}
      getOptionLabel={(opp) => (opp?.Name ? opp.Name : "")}
      {...selectQueryPropsSalesforceAccounts()}
      disabled={disabled}
      style={style}
    />
  );
};
