import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import * as React from "react";

import { useApi } from "@/lib/hooks";

import Context from "@/layout/Context";
import Layout from "@/layout/Layout";
import Alert from "@/common/Alert";

import { OrganizationProps } from "../../types";

interface OrganizationContextState {
  organization: OrganizationProps;
}

interface OrganizationContext extends OrganizationContextState {
  refetchOrganization: () => void;
}

const OrganizationCtx = createContext<OrganizationContext>(undefined);

export const useOrganization = () => useContext(OrganizationCtx);

interface OrganizationProviderProps {
  id: number;
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  id,
  children,
}) => {
  const { user, setPageLoading } = useContext(Context);

  const [data, setData] = useState<any>();

  const { response, reFetch } = useApi({
    url: `/organizations/${id}`,
    trigger: [id],
  });

  useEffect(() => {
    if (response && response.data) {
      setPageLoading(false);
      setData(response.data);
    } else {
      setPageLoading(true);
    }
  }, [response, setPageLoading]);

  const refetchOrganization = useCallback(() => {
    reFetch();
    // TODO(someday): fix useAxios hook
  }, [id]);

  if (!(data && user)) {
    // loading indicator, if we return null we get no loading indicator and if we continue from here without data
    // we get a runtime error
    return <Layout>{null}</Layout>;
  }

  const { organization, error } = data;

  if (error) {
    return <Alert type="error">{error}</Alert>;
  }

  if (!organization) {
    return <div>Organization not found</div>;
  }

  return (
    <OrganizationCtx.Provider
      value={{
        organization,
        refetchOrganization,
      }}
    >
      {children}
    </OrganizationCtx.Provider>
  );
};
