import { useMemo } from "react";
import * as React from "react";
import { StyleSheet, TextStyle, View } from "react-native";

import { Chip, ChipProps, HStack, LinearProgress, VStack } from "@smartrent/ui";
import { ExclamationCircleSolid } from "@smartrent/icons";

import { useVirtualBoxHook } from "@/pages/hub-refurbish/hooks/useVirtualBoxHook";

import { HubRefurbishBatchJob } from "@/types/HubRefurbishProps";

interface VirtualBoxHubRefurbishStep2Props extends Partial<ChipProps> {
  serial?: string;
  style?: any;
  size?: ChipProps["size"];
  fontSize?: TextStyle["fontSize"];
  isLoading?: boolean;
  width?: number;

  isSuccess?: HubRefurbishBatchJob["isSuccess"];
  isError?: HubRefurbishBatchJob["isError"];
  progress?: HubRefurbishBatchJob["progress"];
}

export const HubRefurbishJobVirtualBox: React.FC<
  VirtualBoxHubRefurbishStep2Props
> = ({
  serial,
  style,
  size,
  fontSize,
  width = 54,
  isSuccess,
  isError,
  isLoading,
  progress,
}) => {
  const {
    isDisabled,
    isToBeRemoved,
    handleOnPress,
    serialShort,
    style: chipDefaultStyle,
    textStyle: chipDefaultTextStyle,
    chipColor,
    progressColor,
  } = useVirtualBoxHook({
    serial,
    isSuccess,
    isError,
    isLoading,
  });

  const linearProgressStyle = useMemo(
    () => (isSuccess ? styles.linearProgressSuccess : styles.linearProgress),
    [isSuccess]
  );

  return (
    <VStack spacing={2} align="center">
      <Chip
        corners="badge"
        variation="muted"
        color={chipColor}
        onPress={handleOnPress}
        size={size}
        style={[chipDefaultStyle, style]}
        textStyle={[chipDefaultTextStyle, { fontSize }]}
        disabled={isDisabled}
      >
        {serialShort}
      </Chip>
      {!isToBeRemoved && !isDisabled ? (
        <View style={linearProgressStyle}>
          <HStack align="center" spacing={4}>
            <LinearProgress
              width={isError ? width - 24 : width}
              progress={progress ? progress : 0}
              color={progressColor}
            />
            {isError ? (
              <ExclamationCircleSolid color={progressColor} size={14} />
            ) : null}
          </HStack>
        </View>
      ) : (
        <View style={styles.placeholder} />
      )}
    </VStack>
  );
};

const styles = StyleSheet.create({
  linearProgress: {
    marginLeft: 24,
  },
  linearProgressSuccess: { marginLeft: 36 },
  placeholder: { padding: 8 },
});
