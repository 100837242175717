import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ActionSheetProvider,
  PlatformProvider,
  ThemeProvider,
} from "@smartrent/ui";

import { QueryClientProvider } from "@/api/query-client";
import { DialogProvider } from "@/lib/contexts/dialog";

import { Router } from "./Router";
import "./main.css";
import "@/lib/sentry";

import "@/utils/favicon";

render(
  <ThemeProvider initialMode="light">
    <QueryClientProvider>
      {process.env.REACT_APP_REACT_QUERY_DEV_TOOLS ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
      <PlatformProvider>
        <BrowserRouter>
          <DialogProvider>
            <ActionSheetProvider>
              <Router />
            </ActionSheetProvider>
          </DialogProvider>
        </BrowserRouter>
      </PlatformProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
