import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import {
  ActivityIndicator,
  Button,
  HStack,
  LegacyPageNav,
  Panel,
  PanelBody,
  PanelHeader,
  TabContent,
  TabProvider,
  Typography,
  VStack,
  useTheme,
} from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { HubRequest } from "@/types";
import { useHubCommandMutation, useHubCommandsQuery } from "@/api";
import { Filter } from "@/lib/ansi-to-html";
const ansiConverter = new Filter({ newline: true });

export const TABS = {
  hubLog: "hub-log",
  healNetwork: "heal-network",
  hubMessages: "hub-messages",
  hubRefurbishHistory: "hub-refurbish-history",
};

interface HubInfoProps {
  hub: HubRequest;
}

interface CommandResponse {
  [key: string]: string;
}

export const SmartRentHubInfo: React.FC<HubInfoProps> = ({ hub }) => {
  const { colors } = useTheme();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [tabData, setTabData] = useState<CommandResponse[]>([]);

  const { data: response } = useHubCommandsQuery(
    { serial: hub?.serial },
    { enabled: !!hub?.serial }
  );
  const data = response?.scripts;
  const [hubCommand, { isLoading }] = useHubCommandMutation();

  const handleTabChange = useCallback(
    async (tabId) => {
      setActiveTab(tabId);

      if (tabId !== activeTab && !tabData[tabId]) {
        const response = await hubCommand({
          serial: hub?.serial,
          command: tabId,
        });
        setTabData({ ...tabData, [tabId]: response });
      }
    },
    [activeTab, hub?.serial, hubCommand, tabData]
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setActiveTab(parseInt(data[0].id));
    }
  }, [data]);

  const tabs = data?.map(({ id, name }) => ({
    id,
    label: name,
    disabled: isLoading,
    onPress: () => handleTabChange(id),
  }));

  if (!tabs) {
    return null;
  }

  return (
    <VStack spacing={48}>
      <TabProvider>
        <Panel>
          <PanelHeader
            style={[
              styles.panelHeader,
              {
                backgroundColor: colors.background,
              },
            ]}
          >
            <HStack justify="space-between" style={styles.headerWidth}>
              <LegacyPageNav
                title="SmartRent Hub Info"
                tabItems={tabs}
                initialActiveTabId="1"
              />
              <Button
                variation="plain"
                style={styles.buttonPadding}
                onPress={async () => {
                  const response = await hubCommand({
                    serial: hub?.serial,
                    command: activeTab,
                  });
                  setTabData({ ...tabData, [activeTab]: response });
                }}
                disabled={isLoading}
              >
                {isLoading ? <ActivityIndicator size={24} /> : <Refresh />}
              </Button>
            </HStack>
          </PanelHeader>
          <PanelBody>
            <>
              {tabs.map(({ id }) => (
                <TabContent key={id} id={id}>
                  {tabData[activeTab] ? (
                    <div
                      style={{
                        padding: 16,
                        overflowY: "auto",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        fontFamily: "monospace",
                        backgroundColor: colors.black,
                        color: colors.white,
                        borderRadius: 4,
                        lineHeight: 1.25,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: ansiConverter.toHtml(tabData[activeTab]),
                      }}
                    />
                  ) : (
                    <HStack justify="center">
                      <Typography style={styles.noContent}>
                        {isLoading ? "Loading..." : "Refresh to Show Data"}
                      </Typography>
                    </HStack>
                  )}
                </TabContent>
              ))}
            </>
          </PanelBody>
        </Panel>
      </TabProvider>
    </VStack>
  );
};

const styles = StyleSheet.create({
  panelHeader: {
    paddingVertical: 0,
  },
  headerWidth: { width: "100%" },
  buttonPadding: { paddingTop: 16 },
  noContent: {
    fontStyle: "italic",
  },
});
