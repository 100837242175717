import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleProp, ViewStyle, StyleSheet, View } from "react-native";
import { compact } from "lodash-es";

import { Decal, DollarSign, Map, Person } from "@smartrent/icons";
import { Typography, Link, Button, useTheme } from "@smartrent/ui";

import {
  useGroupQuery,
  useParkingPropertyQuery,
  useServerConfigQuery,
} from "@/api";
import { useUser } from "@/layout/Context";

import { Panel, PanelKeyValueList } from "@/common/panel";
import { QuickActionsFooter } from "@/common/QuickActionsFooter";
import { useDrawerNav } from "@/common/AppDrawer";
import { ModuleCard } from "@/common/parking/PropertyDetailPanel/ModuleCard";

interface PropertyDetailPanelProps {
  groupId: number;
  style?: StyleProp<ViewStyle>;
}

export const PropertyDetailPanel: React.FC<PropertyDetailPanelProps> = ({
  groupId,
  style,
}) => {
  const drawer = useDrawerNav();
  const { colors } = useTheme();

  const { permissions } = useUser();

  const { data: group, isLoading: loadingGroup } = useGroupQuery(groupId);
  const { data: serverConfig, isLoading: loadingServerConfig } =
    useServerConfigQuery();

  const { data: parkingProperty, isLoading: loadingParkingProperty } =
    useParkingPropertyQuery(group?.parking_enabled ? group?.uuid : null);

  const loading = useMemo(
    () => loadingServerConfig || loadingGroup || loadingParkingProperty,
    [loadingGroup, loadingParkingProperty, loadingServerConfig]
  );

  const items = useMemo(
    () =>
      compact([
        {
          label: "Total Spaces",
          value: parkingProperty?.total_spaces ?? "\u2014",
        },
        {
          label: "Assigned Spaces",
          value: parkingProperty?.assigned_spaces ?? "\u2014",
        },
        {
          label: "Available Spaces",
          value: parkingProperty?.available_spaces ?? "\u2014",
        },
        {
          label: "Pooled Spaces",
          value: parkingProperty?.pooled_spaces ?? "\u2014",
        },
        {
          label: "Total Sections",
          value: parkingProperty?.total_sections ?? "\u2014",
        },
        {
          label: "Total Areas",
          value: parkingProperty?.total_areas ?? "\u2014",
        },
        parkingProperty?.guest_parking_module_enabled
          ? {
              label: "Slug",
              value: (
                <Link
                  href={`${serverConfig?.alloyParkingBaseUrl}/${parkingProperty?.slug}`}
                  target="_blank"
                >
                  {parkingProperty?.slug}
                </Link>
              ),
            }
          : null,
      ]),
    [serverConfig, parkingProperty]
  );

  const handleQuickActionsPress = useCallback(
    () =>
      drawer.push("parkingQuickActions", { propertyId: group.uuid, groupId }),
    [drawer, group.uuid, groupId]
  );

  const handlePropertyOptions = useCallback(
    () => drawer.push("parkingPropertySettings", { propertyId: group.uuid }),
    [drawer, group.uuid]
  );

  return (
    <Panel
      title="Details"
      action={
        permissions.manage_parking &&
        parkingProperty?.guest_parking_module_enabled ? (
          <Button onPress={handlePropertyOptions}>EDIT</Button>
        ) : null
      }
      footer={
        permissions.manage_parking && group ? (
          <QuickActionsFooter onPress={handleQuickActionsPress} />
        ) : null
      }
      style={[styles.root, style]}
    >
      <PanelKeyValueList loading={loading} items={items} />
      <View style={[styles.moduleRoot, { borderTopColor: colors.gray200 }]}>
        <View style={styles.moduleHeader}>
          <Typography type="title5" style={{ color: colors.gray500 }}>
            Modules
          </Typography>
        </View>
        <View style={styles.moduleContainer}>
          <ModuleCard
            name="Decal"
            enabled={parkingProperty?.decal_module_enabled}
            icon={<Decal />}
          />
          <ModuleCard
            name="Guest Parking"
            enabled={parkingProperty?.guest_parking_module_enabled}
            icon={<Person />}
          />
          <ModuleCard
            name="Guest Payments"
            enabled={parkingProperty?.guest_parking_payments_module_enabled}
            icon={<DollarSign />}
          />
          <ModuleCard
            name="Maps"
            enabled={parkingProperty?.maps_module_enabled}
            icon={<Map />}
          />
        </View>
      </View>
    </Panel>
  );
};

const styles = StyleSheet.create({
  root: {
    minWidth: 300,
  },
  moduleRoot: {
    borderTopWidth: 1,
  },
  moduleHeader: { marginTop: 16, alignItems: "center" },
  moduleContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
});
