import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useReducer,
  Reducer,
} from "react";
import axios, { AxiosInstance } from "axios";
import { parse } from "querystring";
import { createBreakpoint } from "react-use";
import { useHistory, useLocation } from "react-router-dom";

import useAxios from "@smartrent/use-axios";

import helpers from "@/lib/helpers";

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
  withCredentials: true,
});

export const publicInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/public-api`,
});

export const reactQueryInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
  withCredentials: true,
});

reactQueryInstance.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error) => Promise.reject(error)
);

type UseApiOptions = Omit<Parameters<typeof useAxios>[0], "axios"> & {
  axios?: AxiosInstance;
};

export const useApi = (params: UseApiOptions) => {
  return useAxios({
    axios: instance,
    ...params,
  });
};

export const useDebounce = (value: any, delay?: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    }, // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay]
  );

  return debouncedValue;
};

export function useInterval(callback: Function, delay?: number | null) {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    if (callback) {
      savedCallback.current = callback;
    }
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        try {
          savedCallback.current();
        } catch (err) {
          // error while calling reFetch
          console.error(err);
        }
      }
    }
    if (delay !== null || delay !== undefined) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}

export type Breakpoint = "sm" | "md" | "lg";

// mirrors assets/scss/settings/_breakpoints.scss
export const useBreakpoint = createBreakpoint({
  sm: 320, // 20em
  md: 768, // 48em
  lg: 992, // 62em
}) as () => Breakpoint;

export const useIsDesktop = () => useBreakpoint() === "lg";
export const useIsTablet = () => useBreakpoint() === "md";
export const useIsPhone = () => useBreakpoint() === "sm";
export const useIsMobile = () => ["md", "sm"].includes(useBreakpoint());

export const useQueryString = () => {
  const location = useLocation();

  const qs = useMemo(
    () => parse(location.search.replace("?", "")),
    [location.search]
  );

  return qs;
};

type TypeConverter<V> = (v: string) => V;

export function useQueryStringField<T = any>(
  field: string,
  convert: TypeConverter<T>,
  multiple: true
): T[];
export function useQueryStringField<T = any>(
  field: string,
  convert: TypeConverter<T>,
  multiple?: false
): T | undefined;

export function useQueryStringField<T = any>(
  field: string,
  convert: TypeConverter<T>,
  multiple?: boolean
): T[] | T | undefined {
  const qs = useQueryString();

  if (!(field in qs)) {
    return multiple ? [] : undefined;
  }

  const value = qs[field];

  if (multiple) {
    if (Array.isArray(value)) {
      return value.map((v) => convert(v));
    }

    return convert(value);
  }

  if (Array.isArray(value)) {
    return value.length > 0 ? convert(value[0]) : undefined;
  }

  return convert(value);
}

export type SortDirection = "asc" | "desc";
export type SortChangeHandler = (
  column: string | undefined,
  direction: SortDirection | undefined
) => void;

interface UseUrlSortOptions {
  defaultColumn?: string;
  defaultDirection?: SortDirection;
  resetPaginationOnChange?: boolean;
}

const isSortDirection = (value: any): value is SortDirection =>
  ["asc", "desc"].includes(value);

const stringFromQs = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value[0] ?? "";
  }

  return typeof value === "string" ? value : undefined;
};

export const useUrlSort = ({
  defaultColumn,
  defaultDirection,
  resetPaginationOnChange = true,
}: UseUrlSortOptions = {}) => {
  const history = useHistory();
  const location = useLocation();
  const qs = useQueryString();

  const column = stringFromQs(qs.sort) ?? defaultColumn;

  const dir = stringFromQs(qs.dir);
  const direction = isSortDirection(dir) ? dir : defaultDirection;

  const handleSortChange = useCallback<SortChangeHandler>(
    (sort, dir) => {
      const update: Record<string, string | null | undefined> = { sort, dir };
      if (resetPaginationOnChange) {
        update.page = null;
      }

      helpers.updateQS({
        history,
        location,
        update,
        method: "replace",
      });
    },
    [history, location, resetPaginationOnChange]
  );

  return { column, direction, onSortChange: handleSortChange };
};

interface PushReplaceAction<T> {
  type: "push" | "replace";
  payload: T;
}

interface PopAction {
  type: "pop";
  payload: number;
}

interface ResetAction {
  type: "reset";
}

type StackAction<T> = PushReplaceAction<T> | PopAction | ResetAction;

function stackReducer<T>(state: T[], action: StackAction<T>) {
  switch (action.type) {
    case "push":
      return [...state, action.payload];
    case "pop":
      return state.slice(0, -1);
    case "replace":
      return [...state.slice(0, -1), action.payload];
    case "reset":
      return [];
    default:
      return state;
  }
}

export function useStack<T = any>(initialState: T[] = []) {
  const [stack, dispatch] = useReducer<Reducer<T[], StackAction<T>>>(
    stackReducer,
    initialState
  );

  const push = useCallback((payload: T) => {
    dispatch({ type: "push", payload });
  }, []);

  const pop = useCallback((count: number = 1) => {
    dispatch({ type: "pop", payload: count });
  }, []);

  const replace = useCallback((payload: T) => {
    dispatch({ type: "replace", payload });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: "reset" });
  }, []);

  return { stack, push, replace, pop, reset };
}
