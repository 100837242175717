import { useCallback, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { isEmpty, startCase } from "lodash-es";

import { Typography } from "@smartrent/ui";
import {
  Online,
  Signal,
  Connection,
  WireDisconnected,
  InformationSolid,
  X,
  Hub,
  Person,
  XCircle,
} from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { EM_DASH } from "@/utils/chars";
import { useIsDesktop } from "@/lib/hooks";
import helpers from "@/lib/helpers";

import { useAccountQuery } from "@/api/zipato";
import { useCellStrengthQuery, useHub } from "@/api/hub";
import { useUnit } from "@/api/units";

import { useDrawerNav } from "@/common/AppDrawer";
import { StatusPill } from "@/common/StatusPill";
import { CopyText } from "@/common/CopyText";

import DefaultOrgImage from "@/assets/images/no-image.png";

import { BackupAccessCodes } from "./BackupAccessCodes";
export interface OrgHeaderProps {
  serial: string;
  noHubAccount: boolean;
}

export const OrgHeader: React.FC<OrgHeaderProps> = ({
  serial,
  noHubAccount,
}) => {
  const isDesktop = useIsDesktop();
  const drawer = useDrawerNav();
  const {
    visible: drawerVisible,
    onClose: onDrawerClose,
    onToggle: onDrawerToggle,
  } = useModalState();

  const { data: hub } = useHub({ serial });

  const { data: unit } = useUnit(hub?.unit_id);

  const { data: zipato } = useAccountQuery(
    { serial },
    { enabled: hub?.type === "zipato" }
  );
  const { data: cellStrengthQuery, isLoading: cellStrengthLoading } =
    useCellStrengthQuery({ serial });

  const organization = useMemo(() => unit?.organization ?? null, [unit]);

  const isInstalledOnUnit = useMemo(
    () => !isEmpty(hub) && hub?.deleted_at === null,
    [hub]
  );

  const cellStrength = useMemo(
    () => cellStrengthQuery?.cellStrength,
    [cellStrengthQuery]
  );

  const cellStrengthColor = useMemo(() => {
    if (cellStrengthLoading) {
      return "gray";
    }

    switch (cellStrength) {
      case null:
        return "gray";
      case "MARGINAL":
        return "error";
      case "OK":
        return "warning";
      case "GOOD":
        return "info";
      case "EXCELLENT":
        return "success";
      default:
        return "gray";
    }
  }, [cellStrength, cellStrengthLoading]);

  const openDrawer = useCallback(() => {
    drawer.push("hubSettings", { zipato });
  }, [drawer, zipato]);

  const hubType = hub ? (
    <StatusPill
      style={[styles.hubType, !isDesktop ? styles.topMargin : null]}
      icon={Hub}
      type="gray"
    >
      {helpers.getHubType(hub) === "unknown"
        ? "Unknown Hub Type"
        : startCase(helpers.getHubType(hub))}
    </StatusPill>
  ) : null;

  return (
    <View style={styles.container}>
      {isDesktop ? (
        <img
          src={organization?.logo_url ?? DefaultOrgImage}
          alt="org-header"
          style={orgImageStyles}
        />
      ) : null}

      <View style={styles.orgInfo}>
        <Typography type="title">{organization?.name}</Typography>

        <View style={styles.infoContainer}>
          <View style={styles.infoRow}>
            <Typography type="title4">Serial:</Typography>

            <Typography style={styles.dataLink} color="textSecondary">
              {serial ?? EM_DASH}
            </Typography>

            <CopyText text={serial ?? EM_DASH} />

            {isDesktop ? hubType : null}
          </View>

          {hub?.type === "zipato" ? (
            <TouchableOpacity style={styles.infoRow} onPress={openDrawer}>
              <Typography type="title5" color="primary">
                Show Zipato Account Info
              </Typography>
            </TouchableOpacity>
          ) : null}
          {isInstalledOnUnit ? (
            <TouchableOpacity style={styles.infoRow} onPress={onDrawerToggle}>
              <Typography type="title5" color="primary">
                Show Backup Codes
              </Typography>
            </TouchableOpacity>
          ) : null}
        </View>

        {isInstalledOnUnit ? (
          <View style={styles.pillsRow}>
            {hub?.online ? (
              <StatusPill icon={Online} type="success" style={styles.pillRow}>
                Online
              </StatusPill>
            ) : (
              <StatusPill
                icon={hub ? WireDisconnected : X}
                type={hub ? "error" : "gray"}
                style={styles.pillRow}
              >
                {hub ? "Offline" : "No Unit Found"}
              </StatusPill>
            )}

            {hub?.connection !== "ethernet" &&
            !cellStrengthLoading &&
            cellStrength ? (
              <StatusPill
                icon={Signal}
                type={cellStrengthColor}
                style={styles.pillRow}
              >
                {startCase(cellStrength) ?? "Unknown Cell Strength"}
              </StatusPill>
            ) : null}

            <StatusPill icon={Connection} type="gray" style={styles.pillRow}>
              {[undefined, null, "unknown"].includes(hub?.connection)
                ? "Unknown Connection Type"
                : startCase(hub?.connection)}
            </StatusPill>
          </View>
        ) : (
          <View style={styles.pillsCol}>
            <StatusPill icon={X} type="gray" style={styles.pillCol}>
              No Unit Found
            </StatusPill>
            <StatusPill
              icon={InformationSolid}
              type="info"
              style={styles.pillCol}
            >
              Please run Box Get & Box Session
              {"\n"}
              to view the network connection status.
            </StatusPill>
            <StatusPill
              style={styles.pillCol}
              icon={noHubAccount ? XCircle : Person}
              type="gray"
            >
              {noHubAccount ? "No Hub Account" : "Hub Account Found"}
            </StatusPill>
          </View>
        )}
      </View>
      {isInstalledOnUnit ? (
        <BackupAccessCodes
          open={drawerVisible}
          onClose={onDrawerClose}
          hub={hub}
        />
      ) : null}
    </View>
  );
};

const orgImageStyles = { maxWidth: 184, borderRadius: 4 };

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  orgInfo: {
    marginLeft: 24,
    height: 184,
    justifyContent: "space-between",
    maxWidth: "100%",
  },
  infoContainer: {
    marginTop: 24,
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  dataLink: {
    marginHorizontal: 8,
    alignItems: "center",
  },
  pillsRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pillRow: {
    marginRight: 8,
    marginTop: 8,
  },
  pillsCol: {
    alignItems: "flex-start",
    marginTop: 24,
  },
  pillCol: {
    marginBottom: 8,
  },
  hubType: { marginLeft: 8 },
  topMargin: { marginTop: 8 },
});
